import React, { useEffect, useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root"); // Set the app root element

const ImportantTipModal = ({ isOpen, onClose }) => {
  console.log("🚀 ~ ImportantTipModal ~:");

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Important Tip!"
      className="important-modal"
    >
      {/* Modal content */}
      <>
        <div id="modal-header">Important Tip</div>
        <div id="modal-body">
          <strong>Notice:</strong> To ensure the ad displays correctly without
          distortion, the container <code>&lt;div&gt;</code> element must have a
          minimum width, exceeding the width of the Image Ads{" "}
          <code>(Typically 700px)</code>. Please update the width on your end to
          meet this requirement. You can either adjust your CSS or use the
          following style attribute as an example:
          <pre>
            <code>
              &lt;div id='XXXXXXXXXXXXXXXXXXXXXXXXXX'
              style="width:700px;"&gt;&lt;/div&gt;
            </code>
          </pre>
          Failure to provide the required width may result in improperly
          displayed ads.
          <br />
          <small>
            <i>
              Note: 700px is just an example. You need to adjust the width to a
              safe value that works for you.
            </i>
          </small>
        </div>
        <div id="modal-footer">
          <button onClick={onClose}>Close</button>
        </div>
        <style>{`
        .important-modal {
          display: block;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 90%;
          max-width: 800px;
          background-color: #fff;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          border-radius: 8px;
          z-index: 1000;
        }

         #modal-header {
            background-color: #007bff;
            color: white;
            padding: 15px;
            font-size: 1.5rem;
            font-weight: bold;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
          }

          #modal-body {
            padding: 25px;
            font-family: Arial, sans-serif;
            font-size: 1rem;
            line-height: 1.6;
            color: #333;
            overflow-y: auto;
          }

           #modal-body code {
            background-color: #f8f9fa;
            padding: 5px;
            border-radius: 4px;
            font-size: 0.95rem;
            word-wrap: break-word;
            display: block;
            white-space: pre-wrap;
            width: 100%;
          }

           #modal-footer {
            padding: 10px 15px;
            text-align: right;
            border-top: 1px solid #ddd;
          }

          #modal-footer button {
            background-color: #007bff;
            color: white;
            border: none;
            padding: 10px 15px;
            font-size: 1rem;
            border-radius: 4px;
            cursor: pointer;
          }

           #modal-footer button:hover {
            background-color: #0056b3;
          }

        `}</style>
      </>
    </Modal>
  );
};

export default ImportantTipModal;
