import React, { useState, useEffect } from "react";

const DynamicScriptLoader = ({onScriptLoad }) => {
  useEffect(() => {
    const hostname = window.location.origin;
  
    let scriptSrc = null;
  
    // Assign the appropriate script source based on the hostname
    if (hostname === "https://ads.xoptin.com") {
      scriptSrc = "https://adscdn.xoptin.com/xoptin-ads.js";
    } else {
      scriptSrc = "https://stgcdn.xoptin.com/xoptin-ads.js";
    }
  
    if (scriptSrc) {
      // Create a new script element
      const script = document.createElement("script");
      script.src = scriptSrc; // Set the source
      script.async = true;    // Load asynchronously for better performance
  
      // Append the script to the head
      document.head.appendChild(script);
  
      console.log(`Script added to head: ${scriptSrc}`); // Debugging log
      onScriptLoad(true);
      return () => {
        // Clean up: Remove the script when the component unmounts
        console.log(`Removing script from head: ${scriptSrc}`);
        document.head.removeChild(script);
      };
    }
  }, []);
  

  return null;
};

export default DynamicScriptLoader;
