import React, { useEffect, useState } from "react";
import AppStore from "../../config/AppStore";
import {
  addDays,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { sendRequestGetPublisherOfferDailyStatReports } from "../../view_logics/Reports/Reports";
import { useSelector } from "react-redux";
import DatePickerField from "../../components/common/DatePickerField";
import Sub1Img from "../../../assets/images/sub1.png";
import Sub2Img from "../../../assets/images/sub2a.png";
import Sub3Img from "../../../assets/images/sub3a.png";
import Spinner from "../../components/common/Spinner";

const PublisherOfferDailyStatReportsList = () => {
  let totalUsers = 0;
  let totalClicks = 0;
  let totalGrossIncome = 0;
  let totalNetIncome = 0;
  let totalNetECP = 0;
  let totalNetECPM = 0;
  const { userId, publisherId, username, userType } = useSelector(
    (state) => state?.login
  );
  console.log("🚀 ~ PublisherOfferDailyStatReportsList ~ userId:", userId);
  let store = AppStore.getAppStoreInstance();
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportsList, setReportsList] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [sortOrder, setSortOrder] = useState("asc"); // or 'desc'
  const [dateFieldEmpty, setDateFieldEmpty] = useState(false);
  const [displayStats, setDisplayStats] = useState([]);
  const [displayStatIndex, setDisplayStatIndex] = useState([]);
  const [reportsFilterLoading, setReportsFilterLoading] = useState(false);

  const [isSub1IconClick, setIsSub1IconClick] = useState(false);
  const [isSub2IconClick, setIsSub2IconClick] = useState(false);
  const [isSub3IconClick, setIsSub3IconClick] = useState(false);
  const todayDate = new Date();

  const [collapseStatus, setCollapseStatus] = useState({});
  const [sub1RowCollapseStatus, setSub1RowCollapseStatus] = useState({});
  const [sub2RowCollapseStatus, setSub2RowCollapseStatus] = useState({});

  const [displaySub2Stats, setDisplaySub2Stats] = useState([]);

  const [sub1Loading, setSub1Loading] = useState(false);
  const [sub2Loading, setSub2Loading] = useState(false);
  const [sub3Loading, setSub3Loading] = useState(false);
  const [loadingRowIndex, setLoadingRowIndex] = useState({
    mainIndex: null,
    sub1Index: null,
    sub2Index: null,
  });
  const [isRowHidden, setIsRowHidden] = useState(false);

  const newState = {};

  const handleRowClick = (index) => {
    setCollapseStatus((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleSub2RowClick = (mainIndex, sub1Index) => {
    const key = `main-${mainIndex}-sub1-${sub1Index}`;
    setSub1RowCollapseStatus((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleSub3RowClick = (mainIndex, sub1Index, sub2Index) => {
    const key = `main-${mainIndex}-sub1-${sub1Index}-sub2-${sub2Index}`;
    setSub2RowCollapseStatus((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const inputObj = {
    accessToken: store.getState().login.accessToken,
    url: "reports",
    data: {
      from: todayDate?.toISOString().split("T")[0],
      to: todayDate?.toISOString().split("T")[0],
      publisher_id:
        publisherId != null ? publisherId : userId != null ? userId : "1",
    },
    dataHander: setApiData,
    loadingHandler: setReportsFilterLoading,
  };

  const fun1 = () => {
    const groupedData = [];

    apiData.forEach((item) => {
      const { sub1 } = item;
      const existingGroup = groupedData.find((group) => group.sub1 === sub1);

      if (existingGroup) {
        existingGroup.items.push(item);
      } else {
        groupedData.push({ sub1, items: [item] });
      }
    });
    setReportsList((prevList) =>
      prevList.map((item, idx) =>
        idx === displayStatIndex ? { ...item, sub1Data: groupedData } : item
      )
    );
    setLoadingRowIndex({
      mainIndex: null,
      sub1Index: null,
      sub2Index: null,
    });
    setSub1Loading(false);
    setIsSub1IconClick(false);
  };

  const fun2 = () => {
    // sub 2 data
    const groupedData = [];

    apiData.forEach((item) => {
      const { sub2 } = item;
      const existingGroup = groupedData.find((group) => group.sub2 === sub2);

      if (existingGroup) {
        existingGroup.items.push(item);
      } else {
        groupedData.push({ sub2, items: [item] });
      }
    });
    setReportsList((prevList) =>
      prevList.map((item, idx) =>
        idx === displayStatIndex
          ? {
              ...item,
              sub1Data: item.sub1Data.map((subItem, sIdx) =>
                sIdx === loadingRowIndex.sub1Index
                  ? { ...subItem, sub2Data: groupedData }
                  : subItem
              ),
            }
          : item
      )
    );
    setLoadingRowIndex({
      mainIndex: null,
      sub1Index: null,
      sub2Index: null,
    });
    setSub2Loading(false);
    // setDisplaySub2Stats(groupedData);
    setIsSub2IconClick(false);
  };

  const fun3 = () => {
    // sub 3 data
    const groupedData = [];

    apiData.forEach((item) => {
      const { sub3 } = item;
      const existingGroup = groupedData.find((group) => group.sub3 === sub3);

      if (existingGroup) {
        existingGroup.items.push(item);
      } else {
        groupedData.push({ sub3, items: [item] });
      }
    });
    setReportsList((prevList) =>
      prevList.map((item, idx) =>
        idx === displayStatIndex
          ? {
              ...item,
              sub1Data: item.sub1Data.map((subItem, sIdx) =>
                sIdx === loadingRowIndex.sub1Index
                  ? {
                      ...subItem,
                      sub2Data: subItem.sub2Data.map((sub2Item, sub2Idx) =>
                        sub2Idx === loadingRowIndex.sub2Index
                          ? {
                              ...sub2Item,
                              sub3Data: groupedData, // Add sub3 data
                            }
                          : sub2Item
                      ),
                    }
                  : subItem
              ),
            }
          : item
      )
    );

    setLoadingRowIndex({
      mainIndex: null,
      sub1Index: null,
      sub2Index: null,
    });
    setSub3Loading(false);
    setIsSub3IconClick(false);
  };

  useEffect(() => {
    if (Array.isArray(apiData)) {
      // sub 1 data
      if (isSub1IconClick === true) {
        fun1();
      } else if (isSub2IconClick === true) {
        fun2();
      } else if (isSub3IconClick === true) {
        fun3();
      } else {
        const initializedData = apiData.map((item) => ({
          ...item,
          sub1Data: [],
        }));
        setReportsList(initializedData);
      }
    }
  }, [apiData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setReportsFilterLoading(true);
        await sendRequestGetPublisherOfferDailyStatReports(inputObj);
        // await sendRequestGetPublishers(getPublishersInputObj);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDateRangeChange = (e) => {
    const selected = e.target.value;
    setSelectedDateRange(selected);

    const today = new Date();
    setDateFieldEmpty(true);

    switch (selected) {
      case "today":
        setFromDate(today);
        setToDate(today);
        break;
      case "yesterday":
        setFromDate(addDays(today, -1));
        setToDate(addDays(today, -1));
        break;
      case "thisWeek":
        setFromDate(startOfWeek(today));
        setToDate(endOfWeek(today));
        break;
      case "lastWeek":
        setFromDate(startOfWeek(addDays(today, -7)));
        setToDate(endOfWeek(addDays(today, -7)));
        break;
      case "thisMonth":
        setFromDate(startOfMonth(today));
        setToDate(endOfMonth(today));
        break;
      case "lastMonth":
        setFromDate(startOfMonth(addDays(today, -30)));
        setToDate(endOfMonth(addDays(today, -30)));
        break;
      case "thisYear":
        setFromDate(startOfYear(today));
        setToDate(endOfYear(today));
        break;
      default:
        break;
    }
  };

  const formatDate = (date) => format(date, "yyyy-MM-dd");

  const applyFilterHandler = () => {
    let postObject = {
      from: formatDate(fromDate),
      to: formatDate(toDate),
      publisher_id:
        publisherId != null ? publisherId : userId != null ? userId : "",
      loadingHandler: setReportsFilterLoading,
    };
    inputObj.data = postObject;

    const fetchData = async () => {
      try {
        setReportsFilterLoading(true);
        await sendRequestGetPublisherOfferDailyStatReports(inputObj);
      } catch (error) {
        setReportsFilterLoading(false);
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
    setCollapseStatus({});
  };

  const sub1DataFetch = (date) => {
    let postObject = {
      from: date,
      to: date,
      publisher_id:
        publisherId != null ? publisherId : userId != null ? userId : "",
      sub1: "",
      loadingHandler: setSub1Loading,
    };
    inputObj.data = postObject;

    const fetchData = async () => {
      try {
        setSub1Loading(true);
        await sendRequestGetPublisherOfferDailyStatReports(inputObj);
      } catch (error) {
        setSub1Loading(false);
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  };

  const sub2DataFetch = (date, sub1) => {
    if (sub1 !== "") {
      let postObject = {
        from: date,
        to: date,
        publisher_id:
          publisherId != null ? publisherId : userId != null ? userId : "",
        sub1: sub1,
        sub2: "",
        loadingHandler: setSub2Loading,
      };
      inputObj.data = postObject;

      const fetchData = async () => {
        try {
          setSub2Loading(true);
          await sendRequestGetPublisherOfferDailyStatReports(inputObj);
        } catch (error) {
          setSub2Loading(false);
          console.log("Error fetching data:", error);
        }
      };

      fetchData();
    } else {
      console.log("sub1 have no value");
    }
  };
  const sub3DataFetch = (date, sub1, sub2) => {
    if (sub2 !== "") {
      let postObject = {
        from: date,
        to: date,
        publisher_id:
          publisherId != null ? publisherId : userId != null ? userId : "",
        sub1: sub1,
        sub2: sub2,
        sub3: "",
        loadingHandler: setSub3Loading,
      };
      inputObj.data = postObject;

      const fetchData = async () => {
        try {
          setSub3Loading(true);
          await sendRequestGetPublisherOfferDailyStatReports(inputObj);
        } catch (error) {
          setSub3Loading(false);
          console.log("Error fetching data:", error);
        }
      };

      fetchData();
    } else {
      console.log("sub3 have no value");
    }
  };

  useEffect(() => {
    const newDate = new Date();
    setDateFieldEmpty(true);
    if (newDate) {
      setFromDate(newDate);
      setToDate(newDate);
    }
  }, []);

  const sumOfFieldForRow = (row, fieldName) => {
    const sum = (row.items || []).reduce(
      (total, item) => total + Number(item[fieldName] || 0),
      0
    );
    return sum.toFixed(2);
  };

  const calculateNetEPC = (item) => {
    if (!item || item.click_count <= 0 || item.net_income == null) {
      return "0.00";
    }
    return parseFloat(item.net_income / item.click_count).toFixed(2);
  };

  const calculateNetECPM = (item) => {
    //( total net income / total users count ) * 1000
    if (!item || item.sessions_count <= 0 || item.net_income == null) {
      return "0.00";
    }
    return parseFloat((item.net_income / item.sessions_count) * 1000).toFixed(
      2
    );
  };

  const toggleRowVisibility = (event) => {
    console.log("the check value is ", event.target.checked);
    setIsRowHidden(event.target.checked);
  };

  return (
    <div className="col-12 col-xl-12 position-relative">
      <div className="card">
        <div className="w-100 px-3 pt-3 d-flex">
          <div className="w-100 d-flex gap-1">
            <div className="col-md-12 col-lg-12 col-xl-10 col-xxl-8 d-flex gap-1 align-items-center">
              <div className="d-flex w-50">
                <select
                  className="form-select"
                  aria-label="select example"
                  value={selectedDateRange ? selectedDateRange : "today"}
                  onChange={handleDateRangeChange}
                >
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="thisWeek">This Week</option>
                  <option value="lastWeek">Last Week</option>
                  <option value="thisMonth">This Month</option>
                  <option value="lastMonth">Last Month</option>
                  <option value="thisYear">This Year</option>
                </select>
              </div>
              <div className="w-100 d-flex gap-1 align-items-center">
                <div className="w-60 d-flex gap-1 align-items-center justify-content-between">
                  <label className="form-label mb-0" htmlFor="advertiserId">
                    From:
                  </label>
                  <DatePickerField value={fromDate} setValue={setFromDate} />
                </div>

                <div className="w-40 d-flex gap-1 align-items-center justify-content-between">
                  <label className="form-label mb-0" htmlFor="advertiserId">
                    To:
                  </label>
                  <DatePickerField value={toDate} setValue={setToDate} />
                </div>
              </div>

              <div>
                {reportsFilterLoading ? (
                  <button
                    className="btn btn-primary d-flex gap-2 align-items-center"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Apply
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={applyFilterHandler}
                  >
                    Apply
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="w-100 px-3 pt-3 d-flex">
          <label className="">
            <input
              type="checkbox"
              id="show_rows"
              name="show_rows"
              className="form-check-input "
              onChange={toggleRowVisibility}
            />
            <span className="form-check-label" style={{ marginLeft: "10px" }}>
              Hide rows with no income
            </span>
          </label>
        </div>
        <table id="reports" className="table table-striped">
          <thead>
            <tr>
              <th scope="col" className="position-relative">
                Date{" "}
              </th>
              {/* <th scope="col">
                Name{" "}
              </th>
              <th scope="col">
                Dup Count{" "}
              </th> */}
              <th scope="col">Total Users </th>
              <th scope="col">Total Clicks </th>
              {/* <th scope="col">
                Click Cost{" "} 
              </th> */}

              <th scope="col">Gross Income </th>
              <th scope="col">Net Income </th>
              <th scope="col">
                Net EPC &nbsp;&nbsp;
                <i
                  className="fa-solid fa-circle-info"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={`EPC =  Net Income / Clicks`}
                ></i>
              </th>
              <th scope="col">
                Net ECPM &nbsp;&nbsp;
                <i
                  className="fa-solid fa-circle-info"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={`EPCM = ( Net Income / Users ) * 1000`}
                ></i>
              </th>

              {/* <th scope="col">Impressions Count </th> */}
            </tr>
          </thead>

          <tbody>
            {reportsList.length > 0 ? (
              reportsList.map((report, index) => {
                const isExpanded = collapseStatus[index];
                //1
                totalUsers += (report.sessions_count*1);
                totalClicks += (report.click_count*1);
                totalGrossIncome += parseFloat(report.gross_income)*1; // Keep it as a number
                totalNetIncome += parseFloat(report.net_income)*1;     // Keep it as a number
                totalNetECP += calculateNetEPC(report) ? (calculateNetEPC(report)*1) : 0
                totalNetECPM += calculateNetECPM(report) ? (calculateNetECPM(report)*1) : 0


                return (
                  <React.Fragment key={`report-${index}`}>
                    {!isRowHidden ||
                    report.gross_income !== 0 ||
                    (report?.net_income !== undefined &&
                      report?.net_income !== 0) ? (
                      <tr className="table-primary">
                        <td
                          title="Show Sub1 Stats"
                          className="cursor-pointer"
                          onClick={() => {
                            handleRowClick(index);

                            if (!collapseStatus[index]) {
                              setDisplayStatIndex(index);

                              setIsSub1IconClick(true);
                              setLoadingRowIndex({
                                mainIndex: index,
                                sub1Index: null,
                                sub2Index: null,
                              });

                              sub1DataFetch(report.date);
                            } else {
                              setSub1RowCollapseStatus((sub1Prev) => {
                                const updatedSub1Status = {};
                                for (const key in sub1Prev) {
                                  if (key.startsWith(`main-${index}-`)) {
                                    updatedSub1Status[key] = false;
                                  }
                                }
                                return { ...sub1Prev, ...updatedSub1Status };
                              });

                              setSub2RowCollapseStatus((sub2Prev) => {
                                const updatedSub2Status = {};
                                for (const key in sub2Prev) {
                                  if (key.startsWith(`main-${index}-`)) {
                                    updatedSub2Status[key] = false;
                                  }
                                }
                                return { ...sub2Prev, ...updatedSub2Status };
                              });
                            }
                          }}
                        >
                          {`${report.date}`}
                          <i
                            className={`fa-solid ${
                              isExpanded ? "fa-circle-minus" : "fa-circle-plus"
                            } ps-3`}
                          ></i>
                          {sub1Loading &&
                          loadingRowIndex.mainIndex === index &&
                          loadingRowIndex.sub1Index === null ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : null}
                        </td>
                        {/* <td>{report.ad_unit_name}</td>
                     <td>{report.dup_count}</td> */}  
                        <td>
                        
                          {report.sessions_count}</td>
                        <td>{report.click_count}</td>
                        {/* <td>{"$ " + parseFloat(report.click_cost).toFixed(2)}</td> */}
                        <td>
                          {"$ " + parseFloat(report.gross_income).toFixed(2)}
                        </td>
                        <td>
                          {"$ " + parseFloat(report.net_income).toFixed(2)}
                        </td>

                        <td>{calculateNetEPC(report)}</td>
                        <td>{calculateNetECPM(report)}</td>

                        {/* <td>{report.impression_count}</td> */}
                      </tr>
                    ) : (
                      <></>
                    )}

                    {/* Sub1 Rows */}
                    {isExpanded &&
                      report.sub1Data.map((item1, sub1Index) => {
                        const isSub1Expanded =
                          sub1RowCollapseStatus[
                            `main-${index}-sub1-${sub1Index}`
                          ];

                        return (
                          <React.Fragment key={`sub1-${index}-${sub1Index}`}>
                            {!isRowHidden ||
                            item1.items[0].gross_income !== 0 ||
                            (item1.items[0]?.net_income !== undefined &&
                              item1.items[0].net_income !== 0) ? (
                              <tr className="table-secondary">
                                <td
                                  // style={{ paddingLeft: "30px" }}
                                  title="Show Sub2 Stats"
                                  className="cursor-pointer"
                                  onClick={() => {
                                    handleSub2RowClick(index, sub1Index);
                                    if (!isSub1Expanded) {
                                      setDisplayStatIndex(index);
                                      setIsSub2IconClick(true);
                                      setLoadingRowIndex({
                                        mainIndex: index,
                                        sub1Index: sub1Index,
                                        sub2Index: null,
                                      });
                                      sub2DataFetch(report.date, item1.sub1);
                                    } else {
                                      setSub2RowCollapseStatus((sub2Prev) => {
                                        const updatedSub2Status = {};
                                        for (const key in sub2Prev) {
                                          if (
                                            key.startsWith(
                                              `main-${index}-sub1-${sub1Index}`
                                            )
                                          ) {
                                            updatedSub2Status[key] = false;
                                          }
                                        }
                                        return {
                                          ...sub2Prev,
                                          ...updatedSub2Status,
                                        };
                                      });
                                    }
                                  }}
                                >
                                  {item1.sub1 === "" ||
                                  item1.sub1 === undefined ? (
                                    <span>
                                      <img
                                        width={"20px"}
                                        height={"20px"}
                                        src={Sub1Img}
                                        alt=""
                                        title=""
                                      />
                                      {" : no sub record found"}
                                    </span>
                                  ) : (
                                    <>
                                      {
                                        <img
                                          width={"20px"}
                                          height={"20px"}
                                          src={Sub1Img}
                                          alt=""
                                          title=""
                                        />
                                      }
                                      {item1.sub1}
                                      {item1.sub1 && (
                                        <>
                                          <i
                                            className={`${
                                              isSub1Expanded
                                                ? "fa-solid fa-circle-minus"
                                                : "fa-solid fa-circle-plus"
                                            } ps-3`}
                                          ></i>
                                          {sub2Loading &&
                                          loadingRowIndex.mainIndex === index &&
                                          loadingRowIndex.sub1Index ===
                                            sub1Index ? (
                                            <span
                                              className="spinner-border spinner-border-sm"
                                              role="status"
                                              aria-hidden="true"
                                            ></span>
                                          ) : null}
                                        </>
                                      )}
                                    </>
                                  )}
                                </td>
                                {/* <td>{item1.items[0].ad_unit_name}</td>
                      <td>{item1.items[0].dup_count}</td> */}
                                <td>{item1.items[0].sessions_count}</td>
                                <td>{item1.items[0].click_count}</td>
                                {/* <td>{"$ " + sumOfFieldForRow(item1, "click_cost")}</td> */}

                                <td>
                                  {"$ " +
                                    sumOfFieldForRow(item1, "gross_income")}
                                </td>
                                <td>
                                  {"$ " + sumOfFieldForRow(item1, "net_income")}
                                </td>
                                <td>{calculateNetEPC(item1.items[0])}</td>
                                <td>{calculateNetECPM(item1.items[0])}</td>

                                {/* <td>{item1.items[0].impression_count}</td> */}
                              </tr>
                            ) : (
                              <></>
                            )}

                            {/* Sub2 Rows */}
                            {isSub1Expanded &&
                              report.sub1Data[sub1Index].sub2Data?.map(
                                (item2, sub2index) => {
                                  const isSub2Expanded =
                                    sub2RowCollapseStatus[
                                      `main-${index}-sub1-${sub1Index}-sub2-${sub2index}`
                                    ];
                                  return (
                                    <React.Fragment
                                      key={`sub2-${index}-${sub1Index}-${sub2index}`}
                                    >
                                      {!isRowHidden ||
                                      item2.items[0].gross_income !== 0 ||
                                      (item2.items[0]?.net_income !==
                                        undefined &&
                                        item2.items[0].net_income !== 0) ? (
                                        <tr className="table-success">
                                          <td
                                            // style={{ paddingLeft: "50px" }}
                                            title="Show Sub3 Stats"
                                            className="cursor-pointer"
                                            onClick={() => {
                                              handleSub3RowClick(
                                                index,
                                                sub1Index,
                                                sub2index
                                              );
                                              if (!isSub2Expanded) {
                                                setDisplayStatIndex(index);
                                                setIsSub3IconClick(true);
                                                setLoadingRowIndex({
                                                  mainIndex: index,
                                                  sub1Index,
                                                  sub2Index: sub2index,
                                                });
                                                sub3DataFetch(
                                                  report.date,
                                                  item1.sub1,
                                                  item2.sub2
                                                );
                                              }
                                            }}
                                          >
                                            {item2.sub2 === "" ||
                                            item2.sub2 === undefined ? (
                                              <span>
                                                <img
                                                  width={"40px"}
                                                  height={"20px"}
                                                  src={Sub2Img}
                                                  alt=""
                                                  title=""
                                                />
                                                {" : no sub record found"}
                                              </span>
                                            ) : (
                                              <>
                                                {
                                                  <img
                                                    width={"40px"}
                                                    height={"20px"}
                                                    src={Sub2Img}
                                                    alt=""
                                                    title=""
                                                  />
                                                }
                                                {item2.sub2}
                                                {item2.sub2 && (
                                                  <>
                                                    <i
                                                      className={`fa-solid ${
                                                        isSub2Expanded
                                                          ? "fa-circle-minus"
                                                          : "fa-circle-plus"
                                                      } ps-3`}
                                                    ></i>
                                                    {sub3Loading &&
                                                    loadingRowIndex.mainIndex ===
                                                      index &&
                                                    loadingRowIndex.sub1Index ===
                                                      sub1Index &&
                                                    loadingRowIndex.sub2Index ===
                                                      sub2index ? (
                                                      <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                      ></span>
                                                    ) : null}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </td>
                                          {/* <td>{item2.items[0].ad_unit_name}</td>
                                      <td>{item2.items[0].dup_count}</td> */}
                                          <td>
                                            {item2.items[0].sessions_count}
                                          </td>
                                          <td>{item2.items[0].click_count}</td>
                                          {/* <td>{"$ " + sumOfFieldForRow(item2, "click_cost")}</td> */}
                                          <td>
                                            {"$ " +
                                              sumOfFieldForRow(
                                                item2,
                                                "gross_income"
                                              )}
                                          </td>
                                          <td>
                                            {"$ " +
                                              sumOfFieldForRow(
                                                item2,
                                                "net_income"
                                              )}
                                          </td>
                                          <td>
                                            {calculateNetEPC(item2.items[0])}
                                          </td>
                                          <td>
                                            {calculateNetECPM(item2.items[0])}
                                          </td>
                                          {/* <td>
                                          {item2.items[0].impression_count}
                                        </td> */}
                                        </tr>
                                      ) : (
                                        <></>
                                      )}

                                      {isSub2Expanded &&
                                        item2.sub3Data?.map(
                                          (item3, sub3index) => {
                                            {
                                              !isRowHidden ||
                                              item3.items.gross_income !== 0 ||
                                              (item3.items?.net_income !==
                                                undefined &&
                                                item3.items.net_income !==
                                                  0) ? (
                                                <tr
                                                  className="table-warning"
                                                  key={`sub3-${index}-${sub1Index}-${sub2index}-${sub3index}`}
                                                >
                                                  {/* <td style={{ paddingLeft: "75px" }}> */}
                                                  <td>
                                                    {item3.sub3 === "" ||
                                                    item3.sub3 === undefined ? (
                                                      <span>
                                                        <img
                                                          width={"60px"}
                                                          height={"20px"}
                                                          src={Sub3Img}
                                                          alt=""
                                                          title=""
                                                        />
                                                        blank
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          width={"60px"}
                                                          height={"20px"}
                                                          src={Sub3Img}
                                                          alt=""
                                                          title=""
                                                        />
                                                        {item3.sub3}
                                                      </span>
                                                    )}
                                                    {/* { " : no sub record found"} */}
                                                  </td>
                                                  {/* <td>{item3.items[0].ad_unit_name}</td>
                                          <td>{item3.items[0].dup_count}</td> */}
                                                  <td>
                                                    {
                                                      item3.items[0]
                                                        .sessions_count
                                                    }
                                                  </td>
                                                  <td>
                                                    {item3.items[0].click_count}
                                                  </td>
                                                  {/* <td>{"$ " + sumOfFieldForRow(item3, "click_cost")}</td> */}

                                                  <td>
                                                    {"$ " +
                                                      sumOfFieldForRow(
                                                        item3,
                                                        "gross_income"
                                                      )}
                                                  </td>
                                                  <td>
                                                    {"$ " +
                                                      sumOfFieldForRow(
                                                        item3,
                                                        "net_income"
                                                      )}
                                                  </td>
                                                  <td>
                                                    {calculateNetEPC(
                                                      item3.items[0]
                                                    )}
                                                  </td>
                                                  <td>
                                                    {calculateNetECPM(
                                                      item3.items[0]
                                                    )}
                                                  </td>
                                                  {/* <td>
                                                  {
                                                    item3.items[0]
                                                      .impression_count
                                                  }
                                                </td> */}
                                                </tr>
                                              ) : (
                                                <></>
                                              );
                                            }
                                          }
                                        )}
                                    </React.Fragment>
                                  );
                                }
                              )}
                          </React.Fragment>
                        );
                      })}
                  </React.Fragment>
                );
              })
            ) : (
              <tr>
                <td colSpan="12">No records found…</td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
            <td scope="col" className="position-relative">
              Totals
            </td>
            <td scope="col">
              {totalUsers}
            </td>
            <td scope="col">
              {totalClicks}
            </td>
            <td scope="col">
              $ {totalGrossIncome.toFixed(2)}
            </td>
            <td scope="col">
              $ {totalNetIncome.toFixed(2)}
            </td>
            <td scope="col">
              
            </td>
            <td scope="col">
              
            </td>
            </tr>
          </tfoot>
        </table>
        {/* <hr />
        <PaginationComponent /> */}
      </div>
      {reportsFilterLoading && <Spinner />}
    </div>
  );
};

export default PublisherOfferDailyStatReportsList;
