// import React from 'react';

class Constants {
  constructor() {
    if (this.constructor === Constants) {
      throw new TypeError(
        'Abstract class "Constants" cannot be instantiated directly.'
      );
    }
  }
  static AppStoreState = {
    userId: null,
    currentExecutedAction: null,
    accessToken: null,
    refreshToken: null,
    isStaff: null,
    userType: null,
    publisherId: null,
    publisherCompany: null,
    publisherName: null,
  };
  static get APP_SESSION_KEEP_ALIVE_INTERVAL() {
    return 9000;
  }
  static get APP_SERVER_HOST_NAME() {
    return process.env.REACT_APP_API_BASE_URL || "env1.xoptin.com";
  }
  static get APP_SERVER_PORT() {
    return process.env.REACT_APP_API_PORT || 8000;
  }
  static get APP_SERVER_SCHEME() {
    return "https://";
  }

  static get AWS_ACCESS_HOST() {
    return "https://xoptin-ads-assets.s3.amazonaws.com/";
  }

  static get APP_SERVER_BASE_URL() {
    return (
      Constants.APP_SERVER_SCHEME +
      Constants.APP_SERVER_HOST_NAME +
      // ":" +
      // Constants.APP_SERVER_PORT +
      "/"
    );
  }
  static get APP_SERVER_AUTHENTICATE_USER_URL() {
    return Constants.APP_SERVER_BASE_URL + "jwt/";
  }
  static get APP_SERVER_VALIDATE_USER_TOKEN_URL() {
    return Constants.APP_SERVER_BASE_URL + "jwt/";
  }
  static get APP_SERVER_OPTION_ADVERTISERS_LIST_URL() {
    return Constants.APP_SERVER_BASE_URL + "advertisers/";
  }

  static get APP_SERVER_OPTION_WEBSITES_LIST_URL() {
    return Constants.APP_SERVER_BASE_URL + "websites//";
  }

  static get APP_SERVER_OPTION_WEBSITES_CREATE_URL() {
    return Constants.APP_SERVER_BASE_URL + "websites/";
  }

  static get APP_SERVER_OPTION_AD_UNIS_LIST_URL() {
    return Constants.APP_SERVER_BASE_URL + "ad_unit/";
  }

  static get APP_SERVER_OPTION_PUBLISHERS_LIST_URL() {
    return Constants.APP_SERVER_BASE_URL + "publishers/";
  }

  static get APP_SERVER_OPTION_ADVERTISER_OFFERS_LIST_URL() {
    return Constants.APP_SERVER_BASE_URL + "advertiser_offer";
  }

  static get APP_SERVER_OPTION_TAGS_LIST_URL() {
    return Constants.APP_SERVER_BASE_URL + "tags/";
  }

  static get APP_SERVER_CUSTOMER_DAILY_PROFIT_REPORT() {
    return Constants.APP_SERVER_BASE_URL + "customer/daily_profit_report/";
  }
  static get APP_SERVER_STAFF_DAILY_PROFIT_REPORT() {
    return Constants.APP_SERVER_BASE_URL + "staff/reports/daily/performance/";
  }

  static get APP_SERVER_GET_PROFIT_LOSS_DISTRIPUTION() {
    return Constants.APP_SERVER_BASE_URL + "staff/profit_loss_distribution/";
  }

  static get APP_SERVER_GET_PROFIT_LOSS_DETAIL() {
    return Constants.APP_SERVER_BASE_URL + "staff/profit_loss_detail/";
  }

  static get APP_SERVER_GET_NEWSLETTERS() {
    return Constants.APP_SERVER_BASE_URL + "newsletter";
  }
  static get APP_SERVER_CREATE_NEWSLETTERS() {
    return Constants.APP_SERVER_BASE_URL + "create_newsletter/";
  }
  static get APP_SERVER_IMPORT_TICKER_DATA() {
    return Constants.APP_SERVER_BASE_URL + "import_ticker_data/";
  }
  static get APP_SERVER_OPTION_CHART_DATA() {
    return Constants.APP_SERVER_BASE_URL + "option_chart_data/";
  }
  static get APP_SERVER_TICKER_PRICE_ACTION() {
    return Constants.APP_SERVER_BASE_URL + "ticker_price_action_chart/";
  }
  static get APP_SERVER_TICKER() {
    return Constants.APP_SERVER_BASE_URL + "ticker/";
  }
  static get APP_SERVER_STRATEGY() {
    return Constants.APP_SERVER_BASE_URL + "strategy/";
  }
  static get APP_SERVER_STRATEGY_TICKER() {
    return Constants.APP_SERVER_BASE_URL + "strategy_ticker/";
  }
  static get APP_SERVER_STRATEGY_PERFORMANCE() {
    return Constants.APP_SERVER_BASE_URL + "strategy_performance/";
  }
  static get APP_SERVER_TICKER_SUPPORT_RESISTANCE() {
    return Constants.APP_SERVER_BASE_URL + "support_resistance/";
  }
  static get APP_SERVER_TICKER_BREAKOUT_CONFIRMATION() {
    return Constants.APP_SERVER_BASE_URL + "ticker_breakout_confirmation/";
  }

  static get APP_SERVER_REPORTING_OFFER_STATS_URL() {
    return Constants.APP_SERVER_BASE_URL + "reporting/publisher_offer_stats/";
  }

  static get APP_SERVER_PUBLISHER_OFFER_STATS_URL() {
    return Constants.APP_SERVER_BASE_URL + "reporting/publisher/offer_stats/";
  }

  static get APP_SERVER_PUBLISHER_OFFER_DAILY_STATS_URL() {
    return Constants.APP_SERVER_BASE_URL + "reporting/publisher/day_stats/";
  }

  static get APP_SERVER_MONTHLY_REVENUE_REPORTS_URL() {
    return Constants.APP_SERVER_BASE_URL + "payouts/publisher/monthly_payouts/";
  }

  static get APP_SERVER_MONTHLY_REVENUE_REPORTS_ADD_URL() {
    return Constants.APP_SERVER_BASE_URL + "payouts/publisher/monthly_payouts/";
  }

  static get APP_SERVER_MONTHLY_REVENUE_REPORTS_ADMIN_URL() {
    return (
      Constants.APP_SERVER_BASE_URL + "reporting/admin/monthly_revenue_report/"
    );
  }

  static get APP_SERVER_UPDATE_MONTHLY_REVENUE_REPORTS_ADMIN_URL() {
    return (
      Constants.APP_SERVER_BASE_URL +
      "reporting/admin/update_monthly_revenue_report/"
    );
  }
  static get APP_SERVER_UPDATE_MONTHLY_FINAL_REVENUE_REPORTS_ADMIN_URL() {
    return (
      Constants.APP_SERVER_BASE_URL +
      "payouts/admin/create_publisher_monthly_payout/"
    );
  }

  static get APP_SERVER_UPDATE_STATUS_REPORTS_ADMIN_URL() {
    return (
      Constants.APP_SERVER_BASE_URL +
      "reporting/admin/update_monthly_revenue_report/"
    );
  }

  static get APP_URI_LOGIN() {
    return "/login";
  }
  static get APP_URI_DASHBOARD() {
    return "/dashboard";
  }

  static get APP_SERVER_OPTION_CREATE_ADVERTISER_URL() {
    return Constants.APP_SERVER_BASE_URL + "advertisers";
  }

  static get APP_SERVER_OPTION_CREATE_AD_UNIT_URL() {
    return Constants.APP_SERVER_BASE_URL + "ad_unit";
  }

  static get APP_SERVER_OPTION_UPDATE_ADVERTISER_OFFER_URL() {
    return Constants.APP_SERVER_BASE_URL + "advertiser_offer";
  }

  static get APP_SERVER_OPTION_UPDATE_ADVERTISER_OFFER_LEAD_SETTING_URL() {
    return Constants.APP_SERVER_BASE_URL + "offer_lead_setting/";
  }

  static get APP_SERVER_OPTION_UPDATE_ADVERTISER_OFFER_TARGETTING_URL() {
    return Constants.APP_SERVER_BASE_URL + "offer_targeting";
  }

  static get APP_SERVER_OPTION_GENERATE_PRESIGNED_POST_URL_ADVERTISER_OFFER() {
    return Constants.APP_SERVER_BASE_URL + "generate_presigned_post_url/";
  }

  static get APP_SERVER_OPTION_UPDATE_ADVERTISER_OFFER_IMAGES_URL() {
    return Constants.APP_SERVER_BASE_URL + "offer_creative/";
  }

  static get APP_SERVER_OPTION_CREATE_ADVERTISER_OFFER_URL() {
    return Constants.APP_SERVER_BASE_URL + "advertiser_offer";
  }

  static get APP_SERVER_OPTION_CREATE_TAG_URL() {
    return Constants.APP_SERVER_BASE_URL + "tags";
  }

  static get APP_SERVER_OPTION_EDIT_ADVERTISER_URL() {
    return Constants.APP_SERVER_BASE_URL + "advertisers";
  }

  static get APP_SERVER_OPTION_EDIT_AD_UNIT_URL() {
    return Constants.APP_SERVER_BASE_URL + "ad_unit";
  }

  static get APP_SERVER_OPTION_EDIT_ADVERTISER_OFFER_URL() {
    return Constants.APP_SERVER_BASE_URL + "advertiser_offer";
  }

  static get APP_SERVER_OPTION_GET_ADUNIT_EMBED_SCRIPT_URL() {
    return Constants.APP_SERVER_BASE_URL + "get_ad_unit_script/";
  }

  static get MAX_FILE_SIZE() {
    return 102400; //100KB
  }

  static get SUPPORTED_FORMATS() {
    return ["image/jpg", "image/jpeg", "image/png"];
  }

  // static get LEADERBOARD_IMAGE_RESOLUTION() { return 60000 }
  static get LEADERBOARD_IMAGE_RESOLUTION_WIDTH() {
    return 300;
  }
  static get LEADERBOARD_IMAGE_RESOLUTION_HEIGHT() {
    return 200;
  }
  static get LEADERBOARD_IMAGE_RESOLUTION_HEIGHT_RATION() {
    return 5;
  }

  static get SIDEBAR_IMAGE_RESOLUTION_WIDTH() {
    return 300;
  }
  static get SIDEBAR_IMAGE_RESOLUTION_HEIGHT() {
    return 200;
  }

  static get INCONTENT_IMAGE_RESOLUTION_WIDTH() {
    return 300;
  }
  static get INCONTENT_IMAGE_RESOLUTION_HEIGHT() {
    return 200;
  }

  static get EXIT_POPUP_IMAGE_RESOLUTION_WIDTH() {
    return 300;
  }
  static get EXIT_POPUP_IMAGE_RESOLUTION_HEIGHT() {
    return 200;
  }

  static get TINYMCE_EDITOR_APIKEY() {
    return "8u9khwf86c8ciq3mak8lfavd3ek7uc9o5vrseze03lha5hpp";
  }

  static get PREFILL_EXAMPLE_HTML_TEMPLATE() {
    return `<div class="col-md-12 listque_bx01_bx02 cpcListViewContainer">
    <div class="row questionnaireContainer">
    <div class="col-md-8 col-sm-7 listque_bx01_bx02_lt questionContainer" question_id="6141">
    <ul>
    <li>Participate in the WALMART Survey and Earn a $25 Gift Card!</li>
    </ul>
    </div>
    <div class="col-md-4 col-sm-5 listque_bx01_bx02_rt">
    <div class="row answerContainer">
    <div class="col-md-6 col-sm-6"><a onclick="handleAddClick(this)" class="listque_bx01_bx02_rt_btn btn-primary btn-block btn-lg" offer_id="5451" clicktrk="https://env1.xoptin.com/clicktrk/?oid=5451&amp;auid=6&amp;first_name=john&amp;last_name=doe&amp;gender=male&amp;email=test%40gmail.com&amp;dob=01-01-1999&amp;address=test&amp;zip=12321&amp;city=new+york&amp;state=NY&amp;country=US&amp;phone=001321654&amp;fingerprint=-400808096&amp;visitor_id=d520c7a8-421b-4563-b955-f5abc56b97ec" style="width: 100%; display: flow;">YES</a></div>
    <div class="col-md-6 col-sm-6"><input type="button" name="answer" value="No" class="listque_bx01_bx02_rt_btn btn-primary btn-block btn-lg" answer_id="12798" style="width: 100%; display: flow;"></div>
    </div>
    </div>
    </div>
    </div>`;
  }

  static get PREFILL_EXAMPLE_CSS_TEMPLATE() {
    return `
    .listque_bx01_bx02_rt_btn {
      background: #f7f7f7;
      font-size: 16px;
      color: #45a417;
      padding: 5px;
      box-sizing: border-box;
      border-radius: 5px;
      border: 1px solid #b8b8b8;
      outline: 0;
      -webkit-box-shadow: 1px 1px 10px 0 rgba(0,0,0,.5);
      -moz-box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, .5);
      box-shadow: 1px 1px 8px 0 rgba(0,0,0,.2);
      text-align: center;
      width: max-content;
    }
    `;
  }

  static get AFFILIATE_STRING_SEARCH_URL() {
    return Constants.APP_SERVER_BASE_URL + "block_subids";
  }

  static get APP_SERVER_OPTION_RESET_API_KEYS_URL() {
    return Constants.APP_SERVER_BASE_URL + "publishers/1/reset_api_key";
  }
}

export default Constants;
