import React, { useEffect } from "react";
import "./style.scss";
import DynamicScriptLoader from "../DynamicScriptLoader";

const CustomHeaderPage = () => {
  const adScriptCDN = process.env.REACT_APP_AD_SCRIPT_CDN;
  useEffect(() => {
    console.log("this is the script cde values>>>>>>>>>>>>", adScriptCDN);
    // Define the script to be executed
    const script = document.createElement("script");
    script.innerHTML = `
    var xoptinSettings = {
      'aid': '01HD6NAXQWBQPNP14KDYQRNY23',
      'sub1': '',
      'sub2': '',
      'sub3': '',
      '': '[]',
      'auto_adjust_height': 'true',
    };
    initialize_ads(xoptinSettings);
    `;
    // Append the script to the document body
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <head>
        <script src={`https://${adScriptCDN}/xoptin-ads.js`}></script>
      </head>

      <body class="blog et-tb-has-template et-tb-has-footer sfsi_actvite_theme_default et_extra et_extra_layout et_pb_pagebuilder_layout et_fixed_nav et_pb_gutters3 et_primary_nav_dropdown_animation_Default et_secondary_nav_dropdown_animation_Default et_includes_sidebar et-db et_minified_js et_minified_css sfsi_2.73 chrome cookies-not-set et_single_col">
        <div id="page-container" class="page-container">
          <div id="et-boc" class="et-boc">
            {/* <!-- Header --> */}
            <header class="header centered">
              {/* <!-- Main Header --> */}
              <div id="main-header-wrapper" style={{ height: "151px" }}>
                <div id="main-header" data-fixed-height="80">
                  <div class="container">
                    {/* <!-- ET Ad --> */}

                    {/* <!-- Logo --> */}
                    <a
                      class="logo"
                      href="http://stgblog.nationalassistancenetwork.org/"
                      data-fixed-height="57"
                      style={{ width: "1280px" }}
                      data-fixed-width="1279.9999999999998"
                      data-initial-width="1280"
                      data-initial-height="45.6"
                    >
                      <img
                        src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/nan-logo.png"
                        alt="National Assistance Network"
                        id="logo"
                        data-pagespeed-url-hash="3271002912"
                        onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                      />
                    </a>

                    {/* <!-- ET Navigation --> */}
                    <div id="et-navigation" class="">
                      <ul id="et-menu" class="nav">
                        <li class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-37">
                          <a href="http://stgblog.nationalassistancenetwork.org/category/most-popular/">
                            MOST POPULAR
                          </a>
                        </li>
                        <li class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-36">
                          <a href="http://stgblog.nationalassistancenetwork.org/category/grants/">
                            GRANTS
                          </a>
                        </li>
                        <li class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-34">
                          <a href="http://stgblog.nationalassistancenetwork.org/category/college/">
                            COLLEGE
                          </a>
                        </li>
                        <li class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-38">
                          <a href="http://stgblog.nationalassistancenetwork.org/category/work-from-home/">
                            WORK FROM HOME
                          </a>
                        </li>
                        <li class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-33">
                          <a href="http://stgblog.nationalassistancenetwork.org/category/budgeting/">
                            BUDGETING
                          </a>
                        </li>
                        <li class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-35">
                          <a href="http://stgblog.nationalassistancenetwork.org/category/family/">
                            FAMILY
                          </a>
                        </li>
                      </ul>
                      <div id="et-mobile-navigation">
                        <span class="show-menu">
                          <div class="show-menu-button">
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                          <p>Select Page</p>
                        </span>
                        <nav>
                          <ul
                            id="et-extra-mobile-menu"
                            class="et_extra_mobile_menu"
                          >
                            <li class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-37 et_first_mobile_item">
                              <a href="http://stgblog.nationalassistancenetwork.org/category/most-popular/">
                                MOST POPULAR
                              </a>
                            </li>
                            <li class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-36">
                              <a href="http://stgblog.nationalassistancenetwork.org/category/grants/">
                                GRANTS
                              </a>
                            </li>
                            <li class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-34">
                              <a href="http://stgblog.nationalassistancenetwork.org/category/college/">
                                COLLEGE
                              </a>
                            </li>
                            <li class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-38">
                              <a href="http://stgblog.nationalassistancenetwork.org/category/work-from-home/">
                                WORK FROM HOME
                              </a>
                            </li>
                            <li class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-33">
                              <a href="http://stgblog.nationalassistancenetwork.org/category/budgeting/">
                                BUDGETING
                              </a>
                            </li>
                            <li class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-35">
                              <a href="http://stgblog.nationalassistancenetwork.org/category/family/">
                                FAMILY
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                      {/* <!-- /#et-mobile-navigation --> */}
                    </div>
                    {/* <!-- /#et-navigation --> */}
                  </div>
                  {/* <!-- /.container --> */}
                </div>
                {/* <!-- /#main-header --> */}
              </div>
              {/* <!-- /#main-header-wrapper --> */}
            </header>

            <div id="main-content">
              <div class="container">
                <div id="content-area" class="clearfix">
                  <div class="et_pb_extra_column_main">
                    <div class="et_pb_section et_pb_section_0 et_section_regular">
                      <div class="et_pb_row et_pb_row_0">
                        <div class="et_pb_column et_pb_column_4_4 et_pb_column_0 et_pb_css_mix_blend_mode_passthrough et-last-child">
                          <div class="et_pb_module et_pb_code et_pb_code_0 et_pb_text_align_left">
                            <div class="et_pb_code_inner">
                              <div id="01HD6NAXQWBQPNP14KDYQRNY23">
                                <iframe
                                  width="100%"
                                  height="100%"
                                  style={{ border: "0px" }}
                                  title="Embedded Content"
                                ></iframe>
                              </div>
                            </div>
                          </div>
                          {/* <!-- .et_pb_code --> */}
                          <div
                            class="module posts-carousel-module et_pb_extra_module et_pb_posts_carousel_0"
                            style={{ borderTopColor: "#186b62" }}
                          >
                            <div class="module-head">
                              <h1 style={{ color: "#186b62" }}>All</h1>
                              <div class="module-filter">Latest</div>
                            </div>
                            <div class="posts-slider-module-items carousel-items et_pb_bg_layout_dark">
                              <div
                                class="carousel-group et_pb_slide et-pb-active-slide"
                                style={{ minHeight: "311.4px" }}
                              >
                                <article
                                  id="post-55"
                                  class="carousel-item post-55 post type-post status-publish format-standard has-post-thumbnail hentry category-work-from-home et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/everything-you-could-find-at-your-local-library/"
                                    title="EVERYTHING YOU COULD FIND AT YOUR LOCAL LIBRARY"
                                    class="post-thumbnail"
                                    style={{ maxHeight: "173.4px" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post6-440x264.png"
                                      alt="EVERYTHING YOU COULD FIND AT YOUR LOCAL LIBRARY"
                                      data-pagespeed-url-hash="3823293888"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                    <span class="et_pb_extra_overlay"></span>
                                  </a>
                                  <div class="post-content-box">
                                    <div class="post-content">
                                      <h3 class="entry-title">
                                        <a href="http://stgblog.nationalassistancenetwork.org/2021/07/08/everything-you-could-find-at-your-local-library/">
                                          EVERYTHING YOU COULD FIND AT YOUR
                                          LOCAL ...
                                        </a>
                                      </h3>
                                      <div class="post-meta vcard">
                                        <span class="updated">Jul 8, 2021</span>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                                <article
                                  id="post-52"
                                  class="carousel-item post-52 post type-post status-publish format-standard has-post-thumbnail hentry category-most-popular et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/what-parents-can-do-about-bullying/"
                                    title="WHAT PARENTS CAN DO ABOUT BULLYING"
                                    class="post-thumbnail"
                                    style={{ maxHeight: "173.4px" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post5-440x264.png"
                                      alt="WHAT PARENTS CAN DO ABOUT BULLYING"
                                      data-pagespeed-url-hash="2738771919"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                    <span class="et_pb_extra_overlay"></span>
                                  </a>
                                  <div class="post-content-box">
                                    <div class="post-content">
                                      <h3 class="entry-title">
                                        <a href="http://stgblog.nationalassistancenetwork.org/2021/07/08/what-parents-can-do-about-bullying/">
                                          WHAT PARENTS CAN DO ABOUT BULLYING
                                        </a>
                                      </h3>
                                      <div class="post-meta vcard">
                                        <span class="updated">Jul 8, 2021</span>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                                <article
                                  id="post-49"
                                  class="carousel-item post-49 post type-post status-publish format-standard has-post-thumbnail hentry category-grants et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/"
                                    title="HOW TO GET YOUR TIME (AND YOUR LIFE) BACK"
                                    class="post-thumbnail"
                                    style={{ maxHeight: "173.4px" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post4-440x264.png"
                                      alt="HOW TO GET YOUR TIME (AND YOUR LIFE) BACK"
                                      data-pagespeed-url-hash="1654249950"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                    <span class="et_pb_extra_overlay"></span>
                                  </a>
                                  <div class="post-content-box">
                                    <div class="post-content">
                                      <h3 class="entry-title">
                                        <a href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/">
                                          HOW TO GET YOUR TIME (AND YOUR LIFE)
                                          BAC...
                                        </a>
                                      </h3>
                                      <div class="post-meta vcard">
                                        <span class="updated">Jul 8, 2021</span>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                                <article
                                  id="post-46"
                                  class="carousel-item post-46 post type-post status-publish format-standard has-post-thumbnail hentry category-family et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/"
                                    title="COVID-19 CONTACT TRACING: WHAT IT IS AND HOW YOU COULD GET HIRED"
                                    class="post-thumbnail"
                                    style={{ maxHeight: "173.4px" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post3-440x264.png"
                                      alt="COVID-19 CONTACT TRACING: WHAT IT IS AND HOW YOU COULD GET HIRED"
                                      data-pagespeed-url-hash="569727981"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                    <span class="et_pb_extra_overlay"></span>
                                  </a>
                                  <div class="post-content-box">
                                    <div class="post-content">
                                      <h3 class="entry-title">
                                        <a href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/">
                                          COVID-19 CONTACT TRACING: WHAT IT IS
                                          AND...
                                        </a>
                                      </h3>
                                      <div class="post-meta vcard">
                                        <span class="updated">Jul 8, 2021</span>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              </div>
                              <div
                                class="carousel-group et_pb_slide"
                                style={{ minHeight: "311.4px" }}
                              >
                                <article
                                  id="post-43"
                                  class="carousel-item post-43 post type-post status-publish format-standard has-post-thumbnail hentry category-college et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/an-informational-guide-to-floridas-bright-futures-scholarship-program/"
                                    title="AN INFORMATIONAL GUIDE TO FLORIDA’S BRIGHT FUTURES SCHOLARSHIP PROGRAM"
                                    class="post-thumbnail"
                                    style={{ maxHeight: "173.4px" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post2-440x264.png"
                                      alt="AN INFORMATIONAL GUIDE TO FLORIDA’S BRIGHT FUTURES SCHOLARSHIP PROGRAM"
                                      data-pagespeed-url-hash="3780173308"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                    <span class="et_pb_extra_overlay"></span>
                                  </a>
                                  <div class="post-content-box">
                                    <div class="post-content">
                                      <h3 class="entry-title">
                                        <a href="http://stgblog.nationalassistancenetwork.org/2021/07/08/an-informational-guide-to-floridas-bright-futures-scholarship-program/">
                                          AN INFORMATIONAL GUIDE TO FLORIDA’S
                                          BRIG...
                                        </a>
                                      </h3>
                                      <div class="post-meta vcard">
                                        <span class="updated">Jul 8, 2021</span>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                                <article
                                  id="post-40"
                                  class="carousel-item post-40 post type-post status-publish format-standard has-post-thumbnail hentry category-budgeting et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/tennessee-education-lottery-scholarship-program-lets-students-jam-out-to-college/"
                                    title="TENNESSEE EDUCATION LOTTERY SCHOLARSHIP PROGRAM LETS STUDENTS JAM OUT TO COLLEGE"
                                    class="post-thumbnail"
                                    style={{ maxHeight: "173.4px" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post1-440x264.png"
                                      alt="TENNESSEE EDUCATION LOTTERY SCHOLARSHIP PROGRAM LETS STUDENTS JAM OUT TO COLLEGE"
                                      data-pagespeed-url-hash="2695651339"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                    <span class="et_pb_extra_overlay"></span>
                                  </a>
                                  <div class="post-content-box">
                                    <div class="post-content">
                                      <h3 class="entry-title">
                                        <a href="http://stgblog.nationalassistancenetwork.org/2021/07/08/tennessee-education-lottery-scholarship-program-lets-students-jam-out-to-college/">
                                          TENNESSEE EDUCATION LOTTERY
                                          SCHOLARSHIP ...
                                        </a>
                                      </h3>
                                      <div class="post-meta vcard">
                                        <span class="updated">Jul 8, 2021</span>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              </div>
                              <div class="et-pb-slider-arrows">
                                <a
                                  class="et-pb-arrow-prev"
                                  href="#"
                                  style={{ top: "111.7px" }}
                                >
                                  <span>Previous</span>
                                </a>
                                <a
                                  class="et-pb-arrow-next"
                                  href="#"
                                  style={{ top: "111.7px" }}
                                >
                                  <span>Next</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- .et_pb_column --> */}
                      </div>
                      {/* <!-- .et_pb_row --> */}
                      <div class="et_pb_row et_pb_row_1">
                        <div class="et_pb_column et_pb_column_1_3 et_pb_column_1 et_pb_css_mix_blend_mode_passthrough">
                          <div
                            class="module post-module et_pb_extra_module et_pb_posts_0"
                            style={{ borderTopColor: "#186b62" }}
                          >
                            <div class="module-head">
                              <h1 style={{ color: "#186b62" }}>All</h1>
                              <span class="module-filter">Latest</span>
                            </div>
                            <div class="main-post">
                              <article
                                id="post-55"
                                class="post-55 post type-post status-publish format-standard has-post-thumbnail hentry category-work-from-home et-has-post-format-content et_post_format-et-post-format-standard"
                              >
                                <div class="header">
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/everything-you-could-find-at-your-local-library/"
                                    title="EVERYTHING YOU COULD FIND AT YOUR LOCAL LIBRARY"
                                    class="featured-image"
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post6-440x264.png"
                                      alt="EVERYTHING YOU COULD FIND AT YOUR LOCAL LIBRARY"
                                      data-pagespeed-url-hash="3823293888"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                    <span class="et_pb_extra_overlay"></span>
                                  </a>
                                </div>
                                <div class="post-content">
                                  <h2 class="entry-title">
                                    <a
                                      class="et-accent-color"
                                      style={{ color: "#186b62" }}
                                      href="http://stgblog.nationalassistancenetwork.org/2021/07/08/everything-you-could-find-at-your-local-library/"
                                    >
                                      EVERYTHING YOU COULD FIND AT YOUR LOCAL
                                      LIBRARY
                                    </a>
                                  </h2>
                                  <div class="post-meta vcard">
                                    <p>
                                      by
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                        class="url fn"
                                        title="Posts by user"
                                        rel="author"
                                      >
                                        user
                                      </a>
                                      | <span class="updated">Jul 8, 2021</span>{" "}
                                      |
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/category/work-from-home/"
                                        rel="tag"
                                      >
                                        WORK FROM HOME
                                      </a>
                                      |
                                      <a
                                        class="comments-link"
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/everything-you-could-find-at-your-local-library/#comments"
                                      >
                                        0
                                        <span
                                          title="comment count"
                                          class="comment-bubble post-meta-icon"
                                        ></span>
                                      </a>
                                      |
                                      <span
                                        class="rating-stars"
                                        title="Rating: 0.00"
                                      >
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                      </span>
                                    </p>
                                  </div>
                                  <div class="excerpt entry-summary">
                                    <p>
                                      Lorem Ipsum&nbsp;is simply dummy text of
                                      the printing and typesetting industry.
                                      Lorem Ipsum has been...
                                    </p>
                                  </div>
                                </div>
                              </article>
                            </div>
                            <ul class="posts-list">
                              <li>
                                <article
                                  id="post-52"
                                  class="title-thumb-hover post-52 post type-post status-publish format-standard has-post-thumbnail hentry category-most-popular et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/what-parents-can-do-about-bullying/"
                                    title="WHAT PARENTS CAN DO ABOUT BULLYING"
                                    class="post-thumbnail"
                                    style={{ backgroundColor: "#186b62" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post5-150x150.png"
                                      alt="WHAT PARENTS CAN DO ABOUT BULLYING"
                                      style={{ backgroundColor: "#186b62" }}
                                      data-pagespeed-url-hash="1277786023"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                  </a>
                                  <div class="post-content">
                                    <h3 class="entry-title">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/what-parents-can-do-about-bullying/"
                                        data-hover-color="#186b62"
                                      >
                                        WHAT PARENTS CAN DO ABOUT BULLYING
                                      </a>
                                    </h3>
                                    <div class="post-meta vcard">
                                      <p>
                                        by
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                          class="url fn"
                                          title="Posts by user"
                                          rel="author"
                                        >
                                          user
                                        </a>
                                        |{" "}
                                        <span class="updated">Jul 8, 2021</span>{" "}
                                        |
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/category/most-popular/"
                                          rel="tag"
                                        >
                                          MOST POPULAR
                                        </a>
                                        |
                                        <a
                                          class="comments-link"
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/what-parents-can-do-about-bullying/#comments"
                                        >
                                          0
                                          <span
                                            title="comment count"
                                            class="comment-bubble post-meta-icon"
                                          ></span>
                                        </a>
                                        |
                                        <span
                                          class="rating-stars"
                                          title="Rating: 0.00"
                                        >
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </article>
                              </li>
                              <li>
                                <article
                                  id="post-49"
                                  class="title-thumb-hover post-49 post type-post status-publish format-standard has-post-thumbnail hentry category-grants et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/"
                                    title="HOW TO GET YOUR TIME (AND YOUR LIFE) BACK"
                                    class="post-thumbnail"
                                    style={{ backgroundColor: "#186b62" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post4-150x150.png"
                                      alt="HOW TO GET YOUR TIME (AND YOUR LIFE) BACK"
                                      style={{ backgroundColor: "#186b62" }}
                                      data-pagespeed-url-hash="193264054"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                  </a>
                                  <div class="post-content">
                                    <h3 class="entry-title">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/"
                                        data-hover-color="#186b62"
                                      >
                                        HOW TO GET YOUR TIME (AND YOUR LIFE)
                                        BACK
                                      </a>
                                    </h3>
                                    <div class="post-meta vcard">
                                      <p>
                                        by
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                          class="url fn"
                                          title="Posts by user"
                                          rel="author"
                                        >
                                          user
                                        </a>
                                        |{" "}
                                        <span class="updated">Jul 8, 2021</span>{" "}
                                        |
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/category/grants/"
                                          rel="tag"
                                        >
                                          GRANTS
                                        </a>
                                        |
                                        <a
                                          class="comments-link"
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/#comments"
                                        >
                                          0
                                          <span
                                            title="comment count"
                                            class="comment-bubble post-meta-icon"
                                          ></span>
                                        </a>
                                        |
                                        <span
                                          class="rating-stars"
                                          title="Rating: 0.00"
                                        >
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </article>
                              </li>
                              <li>
                                <article
                                  id="post-46"
                                  class="title-thumb-hover post-46 post type-post status-publish format-standard has-post-thumbnail hentry category-family et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/"
                                    title="COVID-19 CONTACT TRACING: WHAT IT IS AND HOW YOU COULD GET HIRED"
                                    class="post-thumbnail"
                                    style={{ backgroundColor: "#186b62" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post3-150x150.png"
                                      alt="COVID-19 CONTACT TRACING: WHAT IT IS AND HOW YOU COULD GET HIRED"
                                      style={{ backgroundColor: "#186b62" }}
                                      data-pagespeed-url-hash="3403709381"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                  </a>
                                  <div class="post-content">
                                    <h3 class="entry-title">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/"
                                        data-hover-color="#186b62"
                                      >
                                        COVID-19 CONTACT TRACING: WHAT IT IS AND
                                        HOW YOU COULD GET HIRED
                                      </a>
                                    </h3>
                                    <div class="post-meta vcard">
                                      <p>
                                        by
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                          class="url fn"
                                          title="Posts by user"
                                          rel="author"
                                        >
                                          user
                                        </a>
                                        |{" "}
                                        <span class="updated">Jul 8, 2021</span>{" "}
                                        |
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/category/family/"
                                          rel="tag"
                                        >
                                          FAMILY
                                        </a>
                                        |
                                        <a
                                          class="comments-link"
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/#comments"
                                        >
                                          0
                                          <span
                                            title="comment count"
                                            class="comment-bubble post-meta-icon"
                                          ></span>
                                        </a>
                                        |
                                        <span
                                          class="rating-stars"
                                          title="Rating: 0.00"
                                        >
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </article>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* <!-- .et_pb_column --> */}
                        <div class="et_pb_column et_pb_column_1_3 et_pb_column_3 et_pb_css_mix_blend_mode_passthrough">
                          <div
                            class="module post-module et_pb_extra_module et_pb_posts_1"
                            style={{ borderTopColor: "#681f00" }}
                          >
                            <div class="module-head">
                              <h1 style={{ color: "#681f00" }}>All</h1>
                              <span class="module-filter">Popular</span>
                            </div>
                            <div class="main-post">
                              <article
                                id="post-40"
                                class="post-40 post type-post status-publish format-standard has-post-thumbnail hentry category-budgeting et-has-post-format-content et_post_format-et-post-format-standard"
                              >
                                <div class="header">
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/tennessee-education-lottery-scholarship-program-lets-students-jam-out-to-college/"
                                    title="TENNESSEE EDUCATION LOTTERY SCHOLARSHIP PROGRAM LETS STUDENTS JAM OUT TO COLLEGE"
                                    class="featured-image"
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post1-440x264.png"
                                      alt="TENNESSEE EDUCATION LOTTERY SCHOLARSHIP PROGRAM LETS STUDENTS JAM OUT TO COLLEGE"
                                      data-pagespeed-url-hash="2695651339"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                    <span class="et_pb_extra_overlay"></span>
                                  </a>
                                </div>
                                <div class="post-content">
                                  <h2 class="entry-title">
                                    <a
                                      class="et-accent-color"
                                      style={{ color: "#681f00" }}
                                      href="http://stgblog.nationalassistancenetwork.org/2021/07/08/tennessee-education-lottery-scholarship-program-lets-students-jam-out-to-college/"
                                    >
                                      TENNESSEE EDUCATION LOTTERY SCHOLARSHIP
                                      PROGRAM LETS STUDENTS JAM OUT TO COLLEGE
                                    </a>
                                  </h2>
                                  <div class="post-meta vcard">
                                    <p>
                                      by
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                        class="url fn"
                                        title="Posts by user"
                                        rel="author"
                                      >
                                        user
                                      </a>
                                      | <span class="updated">Jul 8, 2021</span>{" "}
                                      |
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/category/budgeting/"
                                        rel="tag"
                                      >
                                        BUDGETING
                                      </a>
                                      |
                                      <a
                                        class="comments-link"
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/tennessee-education-lottery-scholarship-program-lets-students-jam-out-to-college/#comments"
                                      >
                                        0
                                        <span
                                          title="comment count"
                                          class="comment-bubble post-meta-icon"
                                        ></span>
                                      </a>
                                      |
                                      <span
                                        class="rating-stars"
                                        title="Rating: 0.00"
                                      >
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                      </span>
                                    </p>
                                  </div>
                                  <div class="excerpt entry-summary">
                                    <p>
                                      Lorem Ipsum&nbsp;is simply dummy text of
                                      the printing and typesetting industry.
                                      Lorem Ipsum has been...
                                    </p>
                                  </div>
                                </div>
                              </article>
                            </div>
                            <ul class="posts-list">
                              <li>
                                <article
                                  id="post-43"
                                  class="title-thumb-hover post-43 post type-post status-publish format-standard has-post-thumbnail hentry category-college et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/an-informational-guide-to-floridas-bright-futures-scholarship-program/"
                                    title="AN INFORMATIONAL GUIDE TO FLORIDA’S BRIGHT FUTURES SCHOLARSHIP PROGRAM"
                                    class="post-thumbnail"
                                    style={{ backgroundColor: "#681f00" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post2-150x150.png"
                                      alt="AN INFORMATIONAL GUIDE TO FLORIDA’S BRIGHT FUTURES SCHOLARSHIP PROGRAM"
                                      style={{ backgroundColor: "#681f00" }}
                                      data-pagespeed-url-hash="2319187412"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                  </a>
                                  <div class="post-content">
                                    <h3 class="entry-title">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/an-informational-guide-to-floridas-bright-futures-scholarship-program/"
                                        data-hover-color="#681f00"
                                      >
                                        AN INFORMATIONAL GUIDE TO FLORIDA’S
                                        BRIGHT FUTURES SCHOLARSHIP PROGRAM
                                      </a>
                                    </h3>
                                    <div class="post-meta vcard">
                                      <p>
                                        by
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                          class="url fn"
                                          title="Posts by user"
                                          rel="author"
                                        >
                                          user
                                        </a>
                                        |{" "}
                                        <span class="updated">Jul 8, 2021</span>{" "}
                                        |
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/category/college/"
                                          rel="tag"
                                        >
                                          COLLEGE
                                        </a>
                                        |
                                        <a
                                          class="comments-link"
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/an-informational-guide-to-floridas-bright-futures-scholarship-program/#comments"
                                        >
                                          0
                                          <span
                                            title="comment count"
                                            class="comment-bubble post-meta-icon"
                                          ></span>
                                        </a>
                                        |
                                        <span
                                          class="rating-stars"
                                          title="Rating: 0.00"
                                        >
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </article>
                              </li>
                              <li>
                                <article
                                  id="post-46"
                                  class="title-thumb-hover post-46 post type-post status-publish format-standard has-post-thumbnail hentry category-family et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/"
                                    title="COVID-19 CONTACT TRACING: WHAT IT IS AND HOW YOU COULD GET HIRED"
                                    class="post-thumbnail"
                                    style={{ backgroundColor: "#681f00" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post3-150x150.png"
                                      alt="COVID-19 CONTACT TRACING: WHAT IT IS AND HOW YOU COULD GET HIRED"
                                      style={{ backgroundColor: "#681f00" }}
                                      data-pagespeed-url-hash="3403709381"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                  </a>
                                  <div class="post-content">
                                    <h3 class="entry-title">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/"
                                        data-hover-color="#681f00"
                                      >
                                        COVID-19 CONTACT TRACING: WHAT IT IS AND
                                        HOW YOU COULD GET HIRED
                                      </a>
                                    </h3>
                                    <div class="post-meta vcard">
                                      <p>
                                        by
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                          class="url fn"
                                          title="Posts by user"
                                          rel="author"
                                        >
                                          user
                                        </a>
                                        |{" "}
                                        <span class="updated">Jul 8, 2021</span>{" "}
                                        |
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/category/family/"
                                          rel="tag"
                                        >
                                          FAMILY
                                        </a>
                                        |
                                        <a
                                          class="comments-link"
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/#comments"
                                        >
                                          0
                                          <span
                                            title="comment count"
                                            class="comment-bubble post-meta-icon"
                                          ></span>
                                        </a>
                                        |
                                        <span
                                          class="rating-stars"
                                          title="Rating: 0.00"
                                        >
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </article>
                              </li>
                              <li>
                                <article
                                  id="post-49"
                                  class="title-thumb-hover post-49 post type-post status-publish format-standard has-post-thumbnail hentry category-grants et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/"
                                    title="HOW TO GET YOUR TIME (AND YOUR LIFE) BACK"
                                    class="post-thumbnail"
                                    style={{ backgroundColor: "#681f00" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post4-150x150.png"
                                      alt="HOW TO GET YOUR TIME (AND YOUR LIFE) BACK"
                                      style={{ backgroundColor: "#681f00" }}
                                      data-pagespeed-url-hash="193264054"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                  </a>
                                  <div class="post-content">
                                    <h3 class="entry-title">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/"
                                        data-hover-color="#681f00"
                                      >
                                        HOW TO GET YOUR TIME (AND YOUR LIFE)
                                        BACK
                                      </a>
                                    </h3>
                                    <div class="post-meta vcard">
                                      <p>
                                        by
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                          class="url fn"
                                          title="Posts by user"
                                          rel="author"
                                        >
                                          user
                                        </a>
                                        |{" "}
                                        <span class="updated">Jul 8, 2021</span>{" "}
                                        |
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/category/grants/"
                                          rel="tag"
                                        >
                                          GRANTS
                                        </a>
                                        |
                                        <a
                                          class="comments-link"
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/#comments"
                                        >
                                          0
                                          <span
                                            title="comment count"
                                            class="comment-bubble post-meta-icon"
                                          ></span>
                                        </a>
                                        |
                                        <span
                                          class="rating-stars"
                                          title="Rating: 0.00"
                                        >
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </article>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* <!-- .et_pb_column --> */}
                        <div class="et_pb_column et_pb_column_1_3 et_pb_column_4 et_pb_css_mix_blend_mode_passthrough">
                          <div
                            class="module post-module et_pb_extra_module et_pb_posts_2"
                            style={{ borderTopColor: "#681f00" }}
                          >
                            <div class="module-head">
                              <h1 style={{ color: "#681f00" }}>All</h1>
                              <span class="module-filter">Top Rated</span>
                            </div>
                            <div class="main-post">
                              <article
                                id="post-40"
                                class="post-40 post type-post status-publish format-standard has-post-thumbnail hentry category-budgeting et-has-post-format-content et_post_format-et-post-format-standard"
                              >
                                <div class="header">
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/tennessee-education-lottery-scholarship-program-lets-students-jam-out-to-college/"
                                    title="TENNESSEE EDUCATION LOTTERY SCHOLARSHIP PROGRAM LETS STUDENTS JAM OUT TO COLLEGE"
                                    class="featured-image"
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post1-440x264.png"
                                      alt="TENNESSEE EDUCATION LOTTERY SCHOLARSHIP PROGRAM LETS STUDENTS JAM OUT TO COLLEGE"
                                      data-pagespeed-url-hash="2695651339"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                    <span class="et_pb_extra_overlay"></span>
                                  </a>
                                </div>
                                <div class="post-content">
                                  <h2 class="entry-title">
                                    <a
                                      class="et-accent-color"
                                      style={{ color: "#681f00" }}
                                      href="http://stgblog.nationalassistancenetwork.org/2021/07/08/tennessee-education-lottery-scholarship-program-lets-students-jam-out-to-college/"
                                    >
                                      TENNESSEE EDUCATION LOTTERY SCHOLARSHIP
                                      PROGRAM LETS STUDENTS JAM OUT TO COLLEGE
                                    </a>
                                  </h2>
                                  <div class="post-meta vcard">
                                    <p>
                                      by
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                        class="url fn"
                                        title="Posts by user"
                                        rel="author"
                                      >
                                        user
                                      </a>
                                      | <span class="updated">Jul 8, 2021</span>{" "}
                                      |
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/category/budgeting/"
                                        rel="tag"
                                      >
                                        BUDGETING
                                      </a>
                                      |
                                      <a
                                        class="comments-link"
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/tennessee-education-lottery-scholarship-program-lets-students-jam-out-to-college/#comments"
                                      >
                                        0
                                        <span
                                          title="comment count"
                                          class="comment-bubble post-meta-icon"
                                        ></span>
                                      </a>
                                      |
                                      <span
                                        class="rating-stars"
                                        title="Rating: 0.00"
                                      >
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                      </span>
                                    </p>
                                  </div>
                                  <div class="excerpt entry-summary">
                                    <p>
                                      Lorem Ipsum&nbsp;is simply dummy text of
                                      the printing and typesetting industry.
                                      Lorem Ipsum has been...
                                    </p>
                                  </div>
                                </div>
                              </article>
                            </div>
                            <ul class="posts-list">
                              <li>
                                <article
                                  id="post-43"
                                  class="title-thumb-hover post-43 post type-post status-publish format-standard has-post-thumbnail hentry category-college et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/an-informational-guide-to-floridas-bright-futures-scholarship-program/"
                                    title="AN INFORMATIONAL GUIDE TO FLORIDA’S BRIGHT FUTURES SCHOLARSHIP PROGRAM"
                                    class="post-thumbnail"
                                    style={{ backgroundColor: "#681f00" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post2-150x150.png"
                                      alt="AN INFORMATIONAL GUIDE TO FLORIDA’S BRIGHT FUTURES SCHOLARSHIP PROGRAM"
                                      style={{ backgroundColor: "#681f00" }}
                                      data-pagespeed-url-hash="2319187412"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                  </a>
                                  <div class="post-content">
                                    <h3 class="entry-title">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/an-informational-guide-to-floridas-bright-futures-scholarship-program/"
                                        data-hover-color="#681f00"
                                      >
                                        AN INFORMATIONAL GUIDE TO FLORIDA’S
                                        BRIGHT FUTURES SCHOLARSHIP PROGRAM
                                      </a>
                                    </h3>
                                    <div class="post-meta vcard">
                                      <p>
                                        by
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                          class="url fn"
                                          title="Posts by user"
                                          rel="author"
                                        >
                                          user
                                        </a>
                                        |{" "}
                                        <span class="updated">Jul 8, 2021</span>{" "}
                                        |
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/category/college/"
                                          rel="tag"
                                        >
                                          COLLEGE
                                        </a>
                                        |
                                        <a
                                          class="comments-link"
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/an-informational-guide-to-floridas-bright-futures-scholarship-program/#comments"
                                        >
                                          0
                                          <span
                                            title="comment count"
                                            class="comment-bubble post-meta-icon"
                                          ></span>
                                        </a>
                                        |
                                        <span
                                          class="rating-stars"
                                          title="Rating: 0.00"
                                        >
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </article>
                              </li>
                              <li>
                                <article
                                  id="post-46"
                                  class="title-thumb-hover post-46 post type-post status-publish format-standard has-post-thumbnail hentry category-family et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/"
                                    title="COVID-19 CONTACT TRACING: WHAT IT IS AND HOW YOU COULD GET HIRED"
                                    class="post-thumbnail"
                                    style={{ backgroundColor: "#681f00" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post3-150x150.png"
                                      alt="COVID-19 CONTACT TRACING: WHAT IT IS AND HOW YOU COULD GET HIRED"
                                      style={{ backgroundColor: "#681f00" }}
                                      data-pagespeed-url-hash="3403709381"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                  </a>
                                  <div class="post-content">
                                    <h3 class="entry-title">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/"
                                        data-hover-color="#681f00"
                                      >
                                        COVID-19 CONTACT TRACING: WHAT IT IS AND
                                        HOW YOU COULD GET HIRED
                                      </a>
                                    </h3>
                                    <div class="post-meta vcard">
                                      <p>
                                        by
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                          class="url fn"
                                          title="Posts by user"
                                          rel="author"
                                        >
                                          user
                                        </a>
                                        |{" "}
                                        <span class="updated">Jul 8, 2021</span>{" "}
                                        |
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/category/family/"
                                          rel="tag"
                                        >
                                          FAMILY
                                        </a>
                                        |
                                        <a
                                          class="comments-link"
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/#comments"
                                        >
                                          0
                                          <span
                                            title="comment count"
                                            class="comment-bubble post-meta-icon"
                                          ></span>
                                        </a>
                                        |
                                        <span
                                          class="rating-stars"
                                          title="Rating: 0.00"
                                        >
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </article>
                              </li>
                              <li>
                                <article
                                  id="post-49"
                                  class="title-thumb-hover post-49 post type-post status-publish format-standard has-post-thumbnail hentry category-grants et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/"
                                    title="HOW TO GET YOUR TIME (AND YOUR LIFE) BACK"
                                    class="post-thumbnail"
                                    style={{ backgroundColor: "#681f00" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post4-150x150.png"
                                      alt="HOW TO GET YOUR TIME (AND YOUR LIFE) BACK"
                                      style={{ backgroundColor: "#681f00" }}
                                      data-pagespeed-url-hash="193264054"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                  </a>
                                  <div class="post-content">
                                    <h3 class="entry-title">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/"
                                        data-hover-color="#681f00"
                                      >
                                        HOW TO GET YOUR TIME (AND YOUR LIFE)
                                        BACK
                                      </a>
                                    </h3>
                                    <div class="post-meta vcard">
                                      <p>
                                        by
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                          class="url fn"
                                          title="Posts by user"
                                          rel="author"
                                        >
                                          user
                                        </a>
                                        |{" "}
                                        <span class="updated">Jul 8, 2021</span>{" "}
                                        |
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/category/grants/"
                                          rel="tag"
                                        >
                                          GRANTS
                                        </a>
                                        |
                                        <a
                                          class="comments-link"
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/#comments"
                                        >
                                          0
                                          <span
                                            title="comment count"
                                            class="comment-bubble post-meta-icon"
                                          ></span>
                                        </a>
                                        |
                                        <span
                                          class="rating-stars"
                                          title="Rating: 0.00"
                                        >
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </article>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* <!-- .et_pb_column --> */}
                      </div>
                      {/* <!-- .et_pb_row --> */}
                      <div class="et_pb_row et_pb_row_3">
                        <div class="et_pb_column et_pb_column_4_4 et_pb_column_5 et_pb_css_mix_blend_mode_passthrough et-last-child">
                          <div
                            class="module tabbed-post-module et_pb_extra_module et_pb_tabbed_posts_0"
                            style={{ borderTopColor: "rgb(0, 50, 132)" }}
                          >
                            <div class="tabs clearfix">
                              <ul>
                                <li
                                  id="category-tab-0"
                                  class="et-accent-color-parent-term active"
                                  data-tab-id="0"
                                  data-term-color="#003284"
                                  ripple=""
                                  ripple-inverse=""
                                  style={{ color: "rgb(0, 50, 132)" }}
                                >
                                  <span> MOST POPULAR </span>
                                </li>
                                <li
                                  id="category-tab-1"
                                  class="et-accent-color-parent-term"
                                  data-tab-id="1"
                                  data-term-color="#005608"
                                  ripple=""
                                  ripple-inverse=""
                                >
                                  <span> GRANTS </span>
                                </li>
                                <li
                                  id="category-tab-2"
                                  class="et-accent-color-parent-term"
                                  data-tab-id="2"
                                  data-term-color="#003d47"
                                  ripple=""
                                  ripple-inverse=""
                                >
                                  <span> COLLEGE </span>
                                </li>
                                <li
                                  id="category-tab-3"
                                  class="et-accent-color-parent-term"
                                  data-tab-id="3"
                                  data-term-color="#186b62"
                                  ripple=""
                                  ripple-inverse=""
                                >
                                  <span> WORK FROM HOME </span>
                                </li>
                                <li
                                  id="category-tab-4"
                                  class="et-accent-color-parent-term"
                                  data-tab-id="4"
                                  data-term-color="#681f00"
                                  ripple=""
                                  ripple-inverse=""
                                >
                                  <span> BUDGETING </span>
                                </li>
                                <li
                                  id="category-tab-5"
                                  class="et-accent-color-parent-term"
                                  data-tab-id="5"
                                  data-term-color="#5e4900"
                                  ripple=""
                                  ripple-inverse=""
                                >
                                  <span> FAMILY </span>
                                </li>
                              </ul>
                              <div class="tab-nav">
                                <span
                                  class="prev arrow"
                                  title="Previous Tab"
                                ></span>
                                <span
                                  class="next arrow"
                                  title="Next Tab"
                                ></span>
                              </div>
                            </div>

                            <div class="tab-contents">
                              <div class="tab-content tab-content-0 et_pb_tabbed_posts_tab_0 et_pb_tabbed_posts_tab_0">
                                <div class="main-post">
                                  <article
                                    id="post-52"
                                    class="post-52 post type-post status-publish format-standard has-post-thumbnail hentry category-most-popular et-has-post-format-content et_post_format-et-post-format-standard"
                                  >
                                    <div class="header">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/what-parents-can-do-about-bullying/"
                                        title="WHAT PARENTS CAN DO ABOUT BULLYING"
                                        class="featured-image"
                                      >
                                        <img
                                          src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post5-440x264.png"
                                          alt="WHAT PARENTS CAN DO ABOUT BULLYING"
                                          data-pagespeed-url-hash="2738771919"
                                          onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                        />
                                        <span class="et_pb_extra_overlay"></span>
                                      </a>
                                    </div>
                                    <div class="post-content">
                                      <h2 class="entry-title">
                                        <a
                                          class="et-accent-color"
                                          style={{ color: "#003284" }}
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/what-parents-can-do-about-bullying/"
                                        >
                                          WHAT PARENTS CAN DO ABOUT BULLYING
                                        </a>
                                      </h2>
                                      <div class="post-meta vcard">
                                        <p>
                                          by
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                            class="url fn"
                                            title="Posts by user"
                                            rel="author"
                                          >
                                            user
                                          </a>
                                          |
                                          <span class="updated">
                                            Jul 8, 2021
                                          </span>{" "}
                                          |
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/category/most-popular/"
                                            rel="tag"
                                          >
                                            MOST POPULAR
                                          </a>
                                          |
                                          <a
                                            class="comments-link"
                                            href="http://stgblog.nationalassistancenetwork.org/2021/07/08/what-parents-can-do-about-bullying/#comments"
                                          >
                                            0
                                            <span
                                              title="comment count"
                                              class="comment-bubble post-meta-icon"
                                            ></span>
                                          </a>
                                          |
                                          <span
                                            class="rating-stars"
                                            title="Rating: 0.00"
                                          >
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                          </span>
                                        </p>
                                      </div>
                                      <div class="excerpt entry-summary">
                                        <p>
                                          Lorem Ipsum&nbsp;is simply dummy text
                                          of the printing and typesetting
                                          industry. Lorem Ipsum has been...
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </div>
                                <ul class="posts-list"></ul>
                              </div>
                              <div class="tab-content tab-content-1 et_pb_tabbed_posts_tab_1 et_pb_tabbed_posts_tab_1">
                                <div class="main-post">
                                  <article
                                    id="post-49"
                                    class="post-49 post type-post status-publish format-standard has-post-thumbnail hentry category-grants et-has-post-format-content et_post_format-et-post-format-standard"
                                  >
                                    <div class="header">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/"
                                        title="HOW TO GET YOUR TIME (AND YOUR LIFE) BACK"
                                        class="featured-image"
                                      >
                                        <img
                                          src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post4-440x264.png"
                                          alt="HOW TO GET YOUR TIME (AND YOUR LIFE) BACK"
                                          data-pagespeed-url-hash="1654249950"
                                          onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                        />
                                        <span class="et_pb_extra_overlay"></span>
                                      </a>
                                    </div>
                                    <div class="post-content">
                                      <h2 class="entry-title">
                                        <a
                                          class="et-accent-color"
                                          style={{ color: "#005608" }}
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/"
                                        >
                                          HOW TO GET YOUR TIME (AND YOUR LIFE)
                                          BACK
                                        </a>
                                      </h2>
                                      <div class="post-meta vcard">
                                        <p>
                                          by
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                            class="url fn"
                                            title="Posts by user"
                                            rel="author"
                                          >
                                            user
                                          </a>
                                          |
                                          <span class="updated">
                                            Jul 8, 2021
                                          </span>{" "}
                                          |
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/category/grants/"
                                            rel="tag"
                                          >
                                            GRANTS
                                          </a>
                                          |
                                          <a
                                            class="comments-link"
                                            href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/#comments"
                                          >
                                            0
                                            <span
                                              title="comment count"
                                              class="comment-bubble post-meta-icon"
                                            ></span>
                                          </a>
                                          |
                                          <span
                                            class="rating-stars"
                                            title="Rating: 0.00"
                                          >
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                          </span>
                                        </p>
                                      </div>
                                      <div class="excerpt entry-summary">
                                        <p>
                                          Lorem Ipsum&nbsp;is simply dummy text
                                          of the printing and typesetting
                                          industry. Lorem Ipsum has been...
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </div>
                                <ul class="posts-list"></ul>
                              </div>
                              <div class="tab-content tab-content-2 et_pb_tabbed_posts_tab_2 et_pb_tabbed_posts_tab_2">
                                <div class="main-post">
                                  <article
                                    id="post-43"
                                    class="post-43 post type-post status-publish format-standard has-post-thumbnail hentry category-college et-has-post-format-content et_post_format-et-post-format-standard"
                                  >
                                    <div class="header">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/an-informational-guide-to-floridas-bright-futures-scholarship-program/"
                                        title="AN INFORMATIONAL GUIDE TO FLORIDA’S BRIGHT FUTURES SCHOLARSHIP PROGRAM"
                                        class="featured-image"
                                      >
                                        <img
                                          src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post2-440x264.png"
                                          alt="AN INFORMATIONAL GUIDE TO FLORIDA’S BRIGHT FUTURES SCHOLARSHIP PROGRAM"
                                          data-pagespeed-url-hash="3780173308"
                                          onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                        />
                                        <span class="et_pb_extra_overlay"></span>
                                      </a>
                                    </div>
                                    <div class="post-content">
                                      <h2 class="entry-title">
                                        <a
                                          class="et-accent-color"
                                          style={{ color: "#003d47" }}
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/an-informational-guide-to-floridas-bright-futures-scholarship-program/"
                                        >
                                          AN INFORMATIONAL GUIDE TO FLORIDA’S
                                          BRIGHT FUTURES SCHOLARSHIP PROGRAM
                                        </a>
                                      </h2>
                                      <div class="post-meta vcard">
                                        <p>
                                          by
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                            class="url fn"
                                            title="Posts by user"
                                            rel="author"
                                          >
                                            user
                                          </a>
                                          |
                                          <span class="updated">
                                            Jul 8, 2021
                                          </span>{" "}
                                          |
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/category/college/"
                                            rel="tag"
                                          >
                                            COLLEGE
                                          </a>
                                          |
                                          <a
                                            class="comments-link"
                                            href="http://stgblog.nationalassistancenetwork.org/2021/07/08/an-informational-guide-to-floridas-bright-futures-scholarship-program/#comments"
                                          >
                                            0
                                            <span
                                              title="comment count"
                                              class="comment-bubble post-meta-icon"
                                            ></span>
                                          </a>
                                          |
                                          <span
                                            class="rating-stars"
                                            title="Rating: 0.00"
                                          >
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                          </span>
                                        </p>
                                      </div>
                                      <div class="excerpt entry-summary">
                                        <p>
                                          Lorem Ipsum&nbsp;is simply dummy text
                                          of the printing and typesetting
                                          industry. Lorem Ipsum has been...
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </div>
                                <ul class="posts-list"></ul>
                              </div>
                              <div class="tab-content tab-content-3 et_pb_tabbed_posts_tab_3 et_pb_tabbed_posts_tab_3">
                                <div class="main-post">
                                  <article
                                    id="post-55"
                                    class="post-55 post type-post status-publish format-standard has-post-thumbnail hentry category-work-from-home et-has-post-format-content et_post_format-et-post-format-standard"
                                  >
                                    <div class="header">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/everything-you-could-find-at-your-local-library/"
                                        title="EVERYTHING YOU COULD FIND AT YOUR LOCAL LIBRARY"
                                        class="featured-image"
                                      >
                                        <img
                                          src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post6-440x264.png"
                                          alt="EVERYTHING YOU COULD FIND AT YOUR LOCAL LIBRARY"
                                          data-pagespeed-url-hash="3823293888"
                                          onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                        />
                                        <span class="et_pb_extra_overlay"></span>
                                      </a>
                                    </div>
                                    <div class="post-content">
                                      <h2 class="entry-title">
                                        <a
                                          class="et-accent-color"
                                          style={{ color: "#186b62" }}
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/everything-you-could-find-at-your-local-library/"
                                        >
                                          EVERYTHING YOU COULD FIND AT YOUR
                                          LOCAL LIBRARY
                                        </a>
                                      </h2>
                                      <div class="post-meta vcard">
                                        <p>
                                          by
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                            class="url fn"
                                            title="Posts by user"
                                            rel="author"
                                          >
                                            user
                                          </a>
                                          |
                                          <span class="updated">
                                            Jul 8, 2021
                                          </span>{" "}
                                          |
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/category/work-from-home/"
                                            rel="tag"
                                          >
                                            WORK FROM HOME
                                          </a>
                                          |
                                          <a
                                            class="comments-link"
                                            href="http://stgblog.nationalassistancenetwork.org/2021/07/08/everything-you-could-find-at-your-local-library/#comments"
                                          >
                                            0
                                            <span
                                              title="comment count"
                                              class="comment-bubble post-meta-icon"
                                            ></span>
                                          </a>
                                          |
                                          <span
                                            class="rating-stars"
                                            title="Rating: 0.00"
                                          >
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                          </span>
                                        </p>
                                      </div>
                                      <div class="excerpt entry-summary">
                                        <p>
                                          Lorem Ipsum&nbsp;is simply dummy text
                                          of the printing and typesetting
                                          industry. Lorem Ipsum has been...
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </div>
                                <ul class="posts-list"></ul>
                              </div>
                              <div class="tab-content tab-content-4 et_pb_tabbed_posts_tab_4 et_pb_tabbed_posts_tab_4">
                                <div class="main-post">
                                  <article
                                    id="post-40"
                                    class="post-40 post type-post status-publish format-standard has-post-thumbnail hentry category-budgeting et-has-post-format-content et_post_format-et-post-format-standard"
                                  >
                                    <div class="header">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/tennessee-education-lottery-scholarship-program-lets-students-jam-out-to-college/"
                                        title="TENNESSEE EDUCATION LOTTERY SCHOLARSHIP PROGRAM LETS STUDENTS JAM OUT TO COLLEGE"
                                        class="featured-image"
                                      >
                                        <img
                                          src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post1-440x264.png"
                                          alt="TENNESSEE EDUCATION LOTTERY SCHOLARSHIP PROGRAM LETS STUDENTS JAM OUT TO COLLEGE"
                                          data-pagespeed-url-hash="2695651339"
                                          onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                        />
                                        <span class="et_pb_extra_overlay"></span>
                                      </a>
                                    </div>
                                    <div class="post-content">
                                      <h2 class="entry-title">
                                        <a
                                          class="et-accent-color"
                                          style={{ color: "#681f00" }}
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/tennessee-education-lottery-scholarship-program-lets-students-jam-out-to-college/"
                                        >
                                          TENNESSEE EDUCATION LOTTERY
                                          SCHOLARSHIP PROGRAM LETS STUDENTS JAM
                                          OUT TO COLLEGE
                                        </a>
                                      </h2>
                                      <div class="post-meta vcard">
                                        <p>
                                          by
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                            class="url fn"
                                            title="Posts by user"
                                            rel="author"
                                          >
                                            user
                                          </a>
                                          |
                                          <span class="updated">
                                            Jul 8, 2021
                                          </span>{" "}
                                          |
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/category/budgeting/"
                                            rel="tag"
                                          >
                                            BUDGETING
                                          </a>
                                          |
                                          <a
                                            class="comments-link"
                                            href="http://stgblog.nationalassistancenetwork.org/2021/07/08/tennessee-education-lottery-scholarship-program-lets-students-jam-out-to-college/#comments"
                                          >
                                            0
                                            <span
                                              title="comment count"
                                              class="comment-bubble post-meta-icon"
                                            ></span>
                                          </a>
                                          |
                                          <span
                                            class="rating-stars"
                                            title="Rating: 0.00"
                                          >
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                          </span>
                                        </p>
                                      </div>
                                      <div class="excerpt entry-summary">
                                        <p>
                                          Lorem Ipsum&nbsp;is simply dummy text
                                          of the printing and typesetting
                                          industry. Lorem Ipsum has been...
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </div>
                                <ul class="posts-list"></ul>
                              </div>
                              <div class="tab-content tab-content-5 et_pb_tabbed_posts_tab_5 et_pb_tabbed_posts_tab_5">
                                <div class="main-post">
                                  <article
                                    id="post-46"
                                    class="post-46 post type-post status-publish format-standard has-post-thumbnail hentry category-family et-has-post-format-content et_post_format-et-post-format-standard"
                                  >
                                    <div class="header">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/"
                                        title="COVID-19 CONTACT TRACING: WHAT IT IS AND HOW YOU COULD GET HIRED"
                                        class="featured-image"
                                      >
                                        <img
                                          src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post3-440x264.png"
                                          alt="COVID-19 CONTACT TRACING: WHAT IT IS AND HOW YOU COULD GET HIRED"
                                          data-pagespeed-url-hash="569727981"
                                          onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                        />
                                        <span class="et_pb_extra_overlay"></span>
                                      </a>
                                    </div>
                                    <div class="post-content">
                                      <h2 class="entry-title">
                                        <a
                                          class="et-accent-color"
                                          style={{ color: "#5e4900" }}
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/"
                                        >
                                          COVID-19 CONTACT TRACING: WHAT IT IS
                                          AND HOW YOU COULD GET HIRED
                                        </a>
                                      </h2>
                                      <div class="post-meta vcard">
                                        <p>
                                          by
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                            class="url fn"
                                            title="Posts by user"
                                            rel="author"
                                          >
                                            user
                                          </a>
                                          |
                                          <span class="updated">
                                            Jul 8, 2021
                                          </span>{" "}
                                          |
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/category/family/"
                                            rel="tag"
                                          >
                                            FAMILY
                                          </a>
                                          |
                                          <a
                                            class="comments-link"
                                            href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/#comments"
                                          >
                                            0
                                            <span
                                              title="comment count"
                                              class="comment-bubble post-meta-icon"
                                            ></span>
                                          </a>
                                          |
                                          <span
                                            class="rating-stars"
                                            title="Rating: 0.00"
                                          >
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                          </span>
                                        </p>
                                      </div>
                                      <div class="excerpt entry-summary">
                                        <p>
                                          Lorem Ipsum&nbsp;is simply dummy text
                                          of the printing and typesetting
                                          industry. Lorem Ipsum has been...
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </div>
                                <ul class="posts-list"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- .et_pb_column --> */}
                      </div>
                      {/* <!-- .et_pb_row --> */}
                      <div class="et_pb_row et_pb_row_5">
                        <div class="et_pb_column et_pb_column_1_3 et_pb_column_7 et_pb_css_mix_blend_mode_passthrough">
                          <div
                            class="module featured-posts-slider-module et_pb_extra_module et_pb_featured_posts_slider_0 et_pb_bg_layout_dark"
                            data-breadcrumbs="enabled"
                          >
                            <div class="posts-slider-module-items carousel-items et_pb_slides">
                              <article
                                id="post-55"
                                class="post carousel-item et_pb_slide post-55 type-post status-publish format-standard has-post-thumbnail hentry category-work-from-home et-has-post-format-content et_post_format-et-post-format-standard et-pb-active-slide"
                                style={{
                                  backgroundImage: `url(
                                    "http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post6.png"
                                  )`,
                                }}
                              >
                                <div
                                  class="post-content-box"
                                  style={{
                                    minHeight: "94px",
                                    marginTop: "236.861px",
                                  }}
                                >
                                  <div class="post-content">
                                    <h3 class="entry-title">
                                      <a href="http://stgblog.nationalassistancenetwork.org/2021/07/08/everything-you-could-find-at-your-local-library/">
                                        EVERYTHING YOU COULD FIND AT YOUR LOCAL
                                        LIBRARY
                                      </a>
                                    </h3>
                                    <div class="post-meta vcard">
                                      <p></p>
                                    </div>
                                  </div>
                                </div>
                              </article>
                            </div>
                          </div>
                        </div>
                        {/* <!-- .et_pb_column --> */}
                        <div class="et_pb_column et_pb_column_1_3 et_pb_column_8 et_pb_css_mix_blend_mode_passthrough">
                          <div
                            class="module featured-posts-slider-module et_pb_extra_module et_pb_featured_posts_slider_1 et_pb_bg_layout_dark"
                            data-breadcrumbs="enabled"
                          >
                            <div class="posts-slider-module-items carousel-items et_pb_slides">
                              <article
                                id="post-40"
                                class="post carousel-item et_pb_slide post-40 type-post status-publish format-standard has-post-thumbnail hentry category-budgeting et-has-post-format-content et_post_format-et-post-format-standard et-pb-active-slide"
                                style={{
                                  backgroundImage: `url(
                                    "http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post1.png"
                                  )`,
                                }}
                              >
                                <div
                                  class="post-content-box"
                                  style={{
                                    minHeight: "94px",
                                    marginTop: "236.861px",
                                  }}
                                >
                                  <div class="post-content">
                                    <h3 class="entry-title">
                                      <a href="http://stgblog.nationalassistancenetwork.org/2021/07/08/tennessee-education-lottery-scholarship-program-lets-students-jam-out-to-college/">
                                        TENNESSEE EDUCATION LOTTERY SCHOLARSHIP
                                        PROGRAM LE...
                                      </a>
                                    </h3>
                                    <div class="post-meta vcard">
                                      <p></p>
                                    </div>
                                  </div>
                                </div>
                              </article>
                            </div>
                          </div>
                        </div>
                        {/* <!-- .et_pb_column --> */}
                        <div class="et_pb_column et_pb_column_1_3 et_pb_column_9 et_pb_css_mix_blend_mode_passthrough et-last-child">
                          <div class="et_pb_module et_pb_text et_pb_text_2 et_pb_text_align_left et_pb_bg_layout_light">
                            <div class="et_pb_text_inner">
                              <h2>Subscribe To Our Newsletter</h2>
                            </div>
                          </div>
                          {/* <!-- .et_pb_text --> */}
                        </div>
                        {/* <!-- .et_pb_column --> */}
                      </div>
                      {/* <!-- .et_pb_row --> */}
                      <div class="et_pb_row et_pb_row_6">
                        <div class="et_pb_column et_pb_column_1_3 et_pb_column_10 et_pb_css_mix_blend_mode_passthrough">
                          <div
                            class="module post-module et_pb_extra_module et_pb_posts_3"
                            style={{ borderTopColor: "#186b62" }}
                          >
                            <div class="module-head">
                              <h1 style={{ color: "#186b62" }}>All</h1>
                              <span class="module-filter">Latest</span>
                            </div>
                            <div class="main-post">
                              <article
                                id="post-55"
                                class="post-55 post type-post status-publish format-standard has-post-thumbnail hentry category-work-from-home et-has-post-format-content et_post_format-et-post-format-standard"
                              >
                                <div class="header">
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/everything-you-could-find-at-your-local-library/"
                                    title="EVERYTHING YOU COULD FIND AT YOUR LOCAL LIBRARY"
                                    class="featured-image"
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post6-440x264.png"
                                      alt="EVERYTHING YOU COULD FIND AT YOUR LOCAL LIBRARY"
                                      data-pagespeed-url-hash="3823293888"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                    <span class="et_pb_extra_overlay"></span>
                                  </a>
                                </div>
                                <div class="post-content">
                                  <h2 class="entry-title">
                                    <a
                                      class="et-accent-color"
                                      style={{ color: "#186b62" }}
                                      href="http://stgblog.nationalassistancenetwork.org/2021/07/08/everything-you-could-find-at-your-local-library/"
                                    >
                                      EVERYTHING YOU COULD FIND AT YOUR LOCAL
                                      LIBRARY
                                    </a>
                                  </h2>
                                  <div class="post-meta vcard">
                                    <p>
                                      by
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                        class="url fn"
                                        title="Posts by user"
                                        rel="author"
                                      >
                                        user
                                      </a>
                                      | <span class="updated">Jul 8, 2021</span>{" "}
                                      |
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/category/work-from-home/"
                                        rel="tag"
                                      >
                                        WORK FROM HOME
                                      </a>
                                      |
                                      <a
                                        class="comments-link"
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/everything-you-could-find-at-your-local-library/#comments"
                                      >
                                        0
                                        <span
                                          title="comment count"
                                          class="comment-bubble post-meta-icon"
                                        ></span>
                                      </a>
                                      |
                                      <span
                                        class="rating-stars"
                                        title="Rating: 0.00"
                                      >
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                      </span>
                                    </p>
                                  </div>
                                  <div class="excerpt entry-summary">
                                    <p>
                                      Lorem Ipsum&nbsp;is simply dummy text of
                                      the printing and typesetting industry.
                                      Lorem Ipsum has been...
                                    </p>
                                  </div>
                                </div>
                              </article>
                            </div>
                            <ul class="posts-list">
                              <li>
                                <article
                                  id="post-52"
                                  class="title-thumb-hover post-52 post type-post status-publish format-standard has-post-thumbnail hentry category-most-popular et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/what-parents-can-do-about-bullying/"
                                    title="WHAT PARENTS CAN DO ABOUT BULLYING"
                                    class="post-thumbnail"
                                    style={{ backgroundColor: "#186b62" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post5-150x150.png"
                                      alt="WHAT PARENTS CAN DO ABOUT BULLYING"
                                      style={{ backgroundColor: "#186b62" }}
                                      data-pagespeed-url-hash="1277786023"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                  </a>
                                  <div class="post-content">
                                    <h3 class="entry-title">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/what-parents-can-do-about-bullying/"
                                        data-hover-color="#186b62"
                                      >
                                        WHAT PARENTS CAN DO ABOUT BULLYING
                                      </a>
                                    </h3>
                                    <div class="post-meta vcard">
                                      <p>
                                        by
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                          class="url fn"
                                          title="Posts by user"
                                          rel="author"
                                        >
                                          user
                                        </a>
                                        |{" "}
                                        <span class="updated">Jul 8, 2021</span>{" "}
                                        |
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/category/most-popular/"
                                          rel="tag"
                                        >
                                          MOST POPULAR
                                        </a>
                                        |
                                        <a
                                          class="comments-link"
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/what-parents-can-do-about-bullying/#comments"
                                        >
                                          0
                                          <span
                                            title="comment count"
                                            class="comment-bubble post-meta-icon"
                                          ></span>
                                        </a>
                                        |
                                        <span
                                          class="rating-stars"
                                          title="Rating: 0.00"
                                        >
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </article>
                              </li>
                              <li>
                                <article
                                  id="post-49"
                                  class="title-thumb-hover post-49 post type-post status-publish format-standard has-post-thumbnail hentry category-grants et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/"
                                    title="HOW TO GET YOUR TIME (AND YOUR LIFE) BACK"
                                    class="post-thumbnail"
                                    style={{ backgroundColor: "#186b62" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post4-150x150.png"
                                      alt="HOW TO GET YOUR TIME (AND YOUR LIFE) BACK"
                                      style={{ backgroundColor: "#186b62" }}
                                      data-pagespeed-url-hash="193264054"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                  </a>
                                  <div class="post-content">
                                    <h3 class="entry-title">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/"
                                        data-hover-color="#186b62"
                                      >
                                        HOW TO GET YOUR TIME (AND YOUR LIFE)
                                        BACK
                                      </a>
                                    </h3>
                                    <div class="post-meta vcard">
                                      <p>
                                        by
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                          class="url fn"
                                          title="Posts by user"
                                          rel="author"
                                        >
                                          user
                                        </a>
                                        |{" "}
                                        <span class="updated">Jul 8, 2021</span>{" "}
                                        |
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/category/grants/"
                                          rel="tag"
                                        >
                                          GRANTS
                                        </a>
                                        |
                                        <a
                                          class="comments-link"
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/#comments"
                                        >
                                          0
                                          <span
                                            title="comment count"
                                            class="comment-bubble post-meta-icon"
                                          ></span>
                                        </a>
                                        |
                                        <span
                                          class="rating-stars"
                                          title="Rating: 0.00"
                                        >
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </article>
                              </li>
                              <li>
                                <article
                                  id="post-46"
                                  class="title-thumb-hover post-46 post type-post status-publish format-standard has-post-thumbnail hentry category-family et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/"
                                    title="COVID-19 CONTACT TRACING: WHAT IT IS AND HOW YOU COULD GET HIRED"
                                    class="post-thumbnail"
                                    style={{ backgroundColor: "#186b62" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post3-150x150.png"
                                      alt="COVID-19 CONTACT TRACING: WHAT IT IS AND HOW YOU COULD GET HIRED"
                                      style={{ backgroundColor: "#186b62" }}
                                      data-pagespeed-url-hash="3403709381"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                      class=""
                                    />
                                  </a>
                                  <div class="post-content">
                                    <h3 class="entry-title">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/"
                                        data-hover-color="#186b62"
                                      >
                                        COVID-19 CONTACT TRACING: WHAT IT IS AND
                                        HOW YOU COULD GET HIRED
                                      </a>
                                    </h3>
                                    <div class="post-meta vcard">
                                      <p>
                                        by
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                          class="url fn"
                                          title="Posts by user"
                                          rel="author"
                                        >
                                          user
                                        </a>
                                        |{" "}
                                        <span class="updated">Jul 8, 2021</span>{" "}
                                        |
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/category/family/"
                                          rel="tag"
                                        >
                                          FAMILY
                                        </a>
                                        |
                                        <a
                                          class="comments-link"
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/#comments"
                                        >
                                          0
                                          <span
                                            title="comment count"
                                            class="comment-bubble post-meta-icon"
                                          ></span>
                                        </a>
                                        |
                                        <span
                                          class="rating-stars"
                                          title="Rating: 0.00"
                                        >
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </article>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* <!-- .et_pb_column --> */}
                        <div class="et_pb_column et_pb_column_1_3 et_pb_column_12 et_pb_css_mix_blend_mode_passthrough">
                          <div
                            class="module tabbed-post-module et_pb_extra_module et_pb_tabbed_posts_1"
                            style={{ borderTopColor: "rgb(0, 50, 132)" }}
                          >
                            <div class="tabs clearfix">
                              <ul>
                                <li
                                  id="category-tab-0"
                                  class="et-accent-color-parent-term active"
                                  data-tab-id="0"
                                  data-term-color="#003284"
                                  ripple=""
                                  ripple-inverse=""
                                  style={{ color: "rgb(0, 50, 132)" }}
                                >
                                  <span> MOST POPULAR </span>
                                </li>
                                <li
                                  id="category-tab-1"
                                  class="et-accent-color-parent-term"
                                  data-tab-id="1"
                                  data-term-color="#005608"
                                  ripple=""
                                  ripple-inverse=""
                                >
                                  <span> GRANTS </span>
                                </li>
                                <li
                                  id="category-tab-2"
                                  class="et-accent-color-parent-term"
                                  data-tab-id="2"
                                  data-term-color="#003d47"
                                  ripple=""
                                  ripple-inverse=""
                                >
                                  <span> COLLEGE </span>
                                </li>
                                <li
                                  id="category-tab-3"
                                  class="et-accent-color-parent-term"
                                  data-tab-id="3"
                                  data-term-color="#186b62"
                                  ripple=""
                                  ripple-inverse=""
                                >
                                  <span> WORK FROM HOME </span>
                                </li>
                                <li
                                  id="category-tab-4"
                                  class="et-accent-color-parent-term"
                                  data-tab-id="4"
                                  data-term-color="#681f00"
                                  ripple=""
                                  ripple-inverse=""
                                >
                                  <span> BUDGETING </span>
                                </li>
                                <li
                                  id="category-tab-5"
                                  class="et-accent-color-parent-term"
                                  data-tab-id="5"
                                  data-term-color="#5e4900"
                                  ripple=""
                                  ripple-inverse=""
                                >
                                  <span> FAMILY </span>
                                </li>
                              </ul>
                              <div class="tab-nav">
                                <span
                                  class="prev arrow"
                                  title="Previous Tab"
                                ></span>
                                <span
                                  class="next arrow"
                                  title="Next Tab"
                                ></span>
                              </div>
                            </div>

                            <div class="tab-contents">
                              <div class="tab-content tab-content-0 et_pb_tabbed_posts_tab_6 et_pb_tabbed_posts_tab_6">
                                <div class="main-post">
                                  <article
                                    id="post-52"
                                    class="post-52 post type-post status-publish format-standard has-post-thumbnail hentry category-most-popular et-has-post-format-content et_post_format-et-post-format-standard"
                                  >
                                    <div class="header">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/what-parents-can-do-about-bullying/"
                                        title="WHAT PARENTS CAN DO ABOUT BULLYING"
                                        class="featured-image"
                                      >
                                        <img
                                          src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post5-440x264.png"
                                          alt="WHAT PARENTS CAN DO ABOUT BULLYING"
                                          data-pagespeed-url-hash="2738771919"
                                          onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                        />
                                        <span class="et_pb_extra_overlay"></span>
                                      </a>
                                    </div>
                                    <div class="post-content">
                                      <h2 class="entry-title">
                                        <a
                                          class="et-accent-color"
                                          style={{ color: "#003284" }}
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/what-parents-can-do-about-bullying/"
                                        >
                                          WHAT PARENTS CAN DO ABOUT BULLYING
                                        </a>
                                      </h2>
                                      <div class="post-meta vcard">
                                        <p>
                                          by
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                            class="url fn"
                                            title="Posts by user"
                                            rel="author"
                                          >
                                            user
                                          </a>
                                          |
                                          <span class="updated">
                                            Jul 8, 2021
                                          </span>{" "}
                                          |
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/category/most-popular/"
                                            rel="tag"
                                          >
                                            MOST POPULAR
                                          </a>
                                          |
                                          <a
                                            class="comments-link"
                                            href="http://stgblog.nationalassistancenetwork.org/2021/07/08/what-parents-can-do-about-bullying/#comments"
                                          >
                                            0
                                            <span
                                              title="comment count"
                                              class="comment-bubble post-meta-icon"
                                            ></span>
                                          </a>
                                          |
                                          <span
                                            class="rating-stars"
                                            title="Rating: 0.00"
                                          >
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                          </span>
                                        </p>
                                      </div>
                                      <div class="excerpt entry-summary">
                                        <p>
                                          Lorem Ipsum&nbsp;is simply dummy text
                                          of the printing and typesetting
                                          industry. Lorem Ipsum has been...
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </div>
                                <ul class="posts-list"></ul>
                              </div>
                              <div class="tab-content tab-content-1 et_pb_tabbed_posts_tab_7 et_pb_tabbed_posts_tab_7">
                                <div class="main-post">
                                  <article
                                    id="post-49"
                                    class="post-49 post type-post status-publish format-standard has-post-thumbnail hentry category-grants et-has-post-format-content et_post_format-et-post-format-standard"
                                  >
                                    <div class="header">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/"
                                        title="HOW TO GET YOUR TIME (AND YOUR LIFE) BACK"
                                        class="featured-image"
                                      >
                                        <img
                                          src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post4-440x264.png"
                                          alt="HOW TO GET YOUR TIME (AND YOUR LIFE) BACK"
                                          data-pagespeed-url-hash="1654249950"
                                          onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                        />
                                        <span class="et_pb_extra_overlay"></span>
                                      </a>
                                    </div>
                                    <div class="post-content">
                                      <h2 class="entry-title">
                                        <a
                                          class="et-accent-color"
                                          style={{ color: "#005608" }}
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/"
                                        >
                                          HOW TO GET YOUR TIME (AND YOUR LIFE)
                                          BACK
                                        </a>
                                      </h2>
                                      <div class="post-meta vcard">
                                        <p>
                                          by
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                            class="url fn"
                                            title="Posts by user"
                                            rel="author"
                                          >
                                            user
                                          </a>
                                          |
                                          <span class="updated">
                                            Jul 8, 2021
                                          </span>{" "}
                                          |
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/category/grants/"
                                            rel="tag"
                                          >
                                            GRANTS
                                          </a>
                                          |
                                          <a
                                            class="comments-link"
                                            href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/#comments"
                                          >
                                            0
                                            <span
                                              title="comment count"
                                              class="comment-bubble post-meta-icon"
                                            ></span>
                                          </a>
                                          |
                                          <span
                                            class="rating-stars"
                                            title="Rating: 0.00"
                                          >
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                          </span>
                                        </p>
                                      </div>
                                      <div class="excerpt entry-summary">
                                        <p>
                                          Lorem Ipsum&nbsp;is simply dummy text
                                          of the printing and typesetting
                                          industry. Lorem Ipsum has been...
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </div>
                                <ul class="posts-list"></ul>
                              </div>
                              <div class="tab-content tab-content-2 et_pb_tabbed_posts_tab_8 et_pb_tabbed_posts_tab_8">
                                <div class="main-post">
                                  <article
                                    id="post-43"
                                    class="post-43 post type-post status-publish format-standard has-post-thumbnail hentry category-college et-has-post-format-content et_post_format-et-post-format-standard"
                                  >
                                    <div class="header">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/an-informational-guide-to-floridas-bright-futures-scholarship-program/"
                                        title="AN INFORMATIONAL GUIDE TO FLORIDA’S BRIGHT FUTURES SCHOLARSHIP PROGRAM"
                                        class="featured-image"
                                      >
                                        <img
                                          src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post2-440x264.png"
                                          alt="AN INFORMATIONAL GUIDE TO FLORIDA’S BRIGHT FUTURES SCHOLARSHIP PROGRAM"
                                          data-pagespeed-url-hash="3780173308"
                                          onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                        />
                                        <span class="et_pb_extra_overlay"></span>
                                      </a>
                                    </div>
                                    <div class="post-content">
                                      <h2 class="entry-title">
                                        <a
                                          class="et-accent-color"
                                          style={{ color: "#003d47" }}
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/an-informational-guide-to-floridas-bright-futures-scholarship-program/"
                                        >
                                          AN INFORMATIONAL GUIDE TO FLORIDA’S
                                          BRIGHT FUTURES SCHOLARSHIP PROGRAM
                                        </a>
                                      </h2>
                                      <div class="post-meta vcard">
                                        <p>
                                          by
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                            class="url fn"
                                            title="Posts by user"
                                            rel="author"
                                          >
                                            user
                                          </a>
                                          |
                                          <span class="updated">
                                            Jul 8, 2021
                                          </span>{" "}
                                          |
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/category/college/"
                                            rel="tag"
                                          >
                                            COLLEGE
                                          </a>
                                          |
                                          <a
                                            class="comments-link"
                                            href="http://stgblog.nationalassistancenetwork.org/2021/07/08/an-informational-guide-to-floridas-bright-futures-scholarship-program/#comments"
                                          >
                                            0
                                            <span
                                              title="comment count"
                                              class="comment-bubble post-meta-icon"
                                            ></span>
                                          </a>
                                          |
                                          <span
                                            class="rating-stars"
                                            title="Rating: 0.00"
                                          >
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                          </span>
                                        </p>
                                      </div>
                                      <div class="excerpt entry-summary">
                                        <p>
                                          Lorem Ipsum&nbsp;is simply dummy text
                                          of the printing and typesetting
                                          industry. Lorem Ipsum has been...
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </div>
                                <ul class="posts-list"></ul>
                              </div>
                              <div class="tab-content tab-content-3 et_pb_tabbed_posts_tab_9 et_pb_tabbed_posts_tab_9">
                                <div class="main-post">
                                  <article
                                    id="post-55"
                                    class="post-55 post type-post status-publish format-standard has-post-thumbnail hentry category-work-from-home et-has-post-format-content et_post_format-et-post-format-standard"
                                  >
                                    <div class="header">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/everything-you-could-find-at-your-local-library/"
                                        title="EVERYTHING YOU COULD FIND AT YOUR LOCAL LIBRARY"
                                        class="featured-image"
                                      >
                                        <img
                                          src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post6-440x264.png"
                                          alt="EVERYTHING YOU COULD FIND AT YOUR LOCAL LIBRARY"
                                          data-pagespeed-url-hash="3823293888"
                                          onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                        />
                                        <span class="et_pb_extra_overlay"></span>
                                      </a>
                                    </div>
                                    <div class="post-content">
                                      <h2 class="entry-title">
                                        <a
                                          class="et-accent-color"
                                          style={{ color: "#186b62" }}
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/everything-you-could-find-at-your-local-library/"
                                        >
                                          EVERYTHING YOU COULD FIND AT YOUR
                                          LOCAL LIBRARY
                                        </a>
                                      </h2>
                                      <div class="post-meta vcard">
                                        <p>
                                          by
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                            class="url fn"
                                            title="Posts by user"
                                            rel="author"
                                          >
                                            user
                                          </a>
                                          |
                                          <span class="updated">
                                            Jul 8, 2021
                                          </span>{" "}
                                          |
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/category/work-from-home/"
                                            rel="tag"
                                          >
                                            WORK FROM HOME
                                          </a>
                                          |
                                          <a
                                            class="comments-link"
                                            href="http://stgblog.nationalassistancenetwork.org/2021/07/08/everything-you-could-find-at-your-local-library/#comments"
                                          >
                                            0
                                            <span
                                              title="comment count"
                                              class="comment-bubble post-meta-icon"
                                            ></span>
                                          </a>
                                          |
                                          <span
                                            class="rating-stars"
                                            title="Rating: 0.00"
                                          >
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                          </span>
                                        </p>
                                      </div>
                                      <div class="excerpt entry-summary">
                                        <p>
                                          Lorem Ipsum&nbsp;is simply dummy text
                                          of the printing and typesetting
                                          industry. Lorem Ipsum has been...
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </div>
                                <ul class="posts-list"></ul>
                              </div>
                              <div class="tab-content tab-content-4 et_pb_tabbed_posts_tab_10 et_pb_tabbed_posts_tab_10">
                                <div class="main-post">
                                  <article
                                    id="post-40"
                                    class="post-40 post type-post status-publish format-standard has-post-thumbnail hentry category-budgeting et-has-post-format-content et_post_format-et-post-format-standard"
                                  >
                                    <div class="header">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/tennessee-education-lottery-scholarship-program-lets-students-jam-out-to-college/"
                                        title="TENNESSEE EDUCATION LOTTERY SCHOLARSHIP PROGRAM LETS STUDENTS JAM OUT TO COLLEGE"
                                        class="featured-image"
                                      >
                                        <img
                                          src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post1-440x264.png"
                                          alt="TENNESSEE EDUCATION LOTTERY SCHOLARSHIP PROGRAM LETS STUDENTS JAM OUT TO COLLEGE"
                                          data-pagespeed-url-hash="2695651339"
                                          onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                        />
                                        <span class="et_pb_extra_overlay"></span>
                                      </a>
                                    </div>
                                    <div class="post-content">
                                      <h2 class="entry-title">
                                        <a
                                          class="et-accent-color"
                                          style={{ color: "#681f00" }}
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/tennessee-education-lottery-scholarship-program-lets-students-jam-out-to-college/"
                                        >
                                          TENNESSEE EDUCATION LOTTERY
                                          SCHOLARSHIP PROGRAM LETS STUDENTS JAM
                                          OUT TO COLLEGE
                                        </a>
                                      </h2>
                                      <div class="post-meta vcard">
                                        <p>
                                          by
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                            class="url fn"
                                            title="Posts by user"
                                            rel="author"
                                          >
                                            user
                                          </a>
                                          |
                                          <span class="updated">
                                            Jul 8, 2021
                                          </span>{" "}
                                          |
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/category/budgeting/"
                                            rel="tag"
                                          >
                                            BUDGETING
                                          </a>
                                          |
                                          <a
                                            class="comments-link"
                                            href="http://stgblog.nationalassistancenetwork.org/2021/07/08/tennessee-education-lottery-scholarship-program-lets-students-jam-out-to-college/#comments"
                                          >
                                            0
                                            <span
                                              title="comment count"
                                              class="comment-bubble post-meta-icon"
                                            ></span>
                                          </a>
                                          |
                                          <span
                                            class="rating-stars"
                                            title="Rating: 0.00"
                                          >
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                          </span>
                                        </p>
                                      </div>
                                      <div class="excerpt entry-summary">
                                        <p>
                                          Lorem Ipsum&nbsp;is simply dummy text
                                          of the printing and typesetting
                                          industry. Lorem Ipsum has been...
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </div>
                                <ul class="posts-list"></ul>
                              </div>
                              <div class="tab-content tab-content-5 et_pb_tabbed_posts_tab_11 et_pb_tabbed_posts_tab_11">
                                <div class="main-post">
                                  <article
                                    id="post-46"
                                    class="post-46 post type-post status-publish format-standard has-post-thumbnail hentry category-family et-has-post-format-content et_post_format-et-post-format-standard"
                                  >
                                    <div class="header">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/"
                                        title="COVID-19 CONTACT TRACING: WHAT IT IS AND HOW YOU COULD GET HIRED"
                                        class="featured-image"
                                      >
                                        <img
                                          src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post3-440x264.png"
                                          alt="COVID-19 CONTACT TRACING: WHAT IT IS AND HOW YOU COULD GET HIRED"
                                          data-pagespeed-url-hash="569727981"
                                          onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                        />
                                        <span class="et_pb_extra_overlay"></span>
                                      </a>
                                    </div>
                                    <div class="post-content">
                                      <h2 class="entry-title">
                                        <a
                                          class="et-accent-color"
                                          style={{ color: "#5e4900" }}
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/"
                                        >
                                          COVID-19 CONTACT TRACING: WHAT IT IS
                                          AND HOW YOU COULD GET HIRED
                                        </a>
                                      </h2>
                                      <div class="post-meta vcard">
                                        <p>
                                          by
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                            class="url fn"
                                            title="Posts by user"
                                            rel="author"
                                          >
                                            user
                                          </a>
                                          |
                                          <span class="updated">
                                            Jul 8, 2021
                                          </span>{" "}
                                          |
                                          <a
                                            href="http://stgblog.nationalassistancenetwork.org/category/family/"
                                            rel="tag"
                                          >
                                            FAMILY
                                          </a>
                                          |
                                          <a
                                            class="comments-link"
                                            href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/#comments"
                                          >
                                            0
                                            <span
                                              title="comment count"
                                              class="comment-bubble post-meta-icon"
                                            ></span>
                                          </a>
                                          |
                                          <span
                                            class="rating-stars"
                                            title="Rating: 0.00"
                                          >
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                            <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                          </span>
                                        </p>
                                      </div>
                                      <div class="excerpt entry-summary">
                                        <p>
                                          Lorem Ipsum&nbsp;is simply dummy text
                                          of the printing and typesetting
                                          industry. Lorem Ipsum has been...
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </div>
                                <ul class="posts-list"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- .et_pb_column --> */}
                        <div class="et_pb_column et_pb_column_1_3 et_pb_column_14 et_pb_css_mix_blend_mode_passthrough">
                          <div
                            class="module post-module et_pb_extra_module et_pb_posts_4"
                            style={{ borderTopColor: "#681f00" }}
                          >
                            <div class="module-head">
                              <h1 style={{ color: "#681f00" }}>All</h1>
                              <span class="module-filter">Popular</span>
                            </div>
                            <div class="main-post">
                              <article
                                id="post-40"
                                class="post-40 post type-post status-publish format-standard has-post-thumbnail hentry category-budgeting et-has-post-format-content et_post_format-et-post-format-standard"
                              >
                                <div class="header">
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/tennessee-education-lottery-scholarship-program-lets-students-jam-out-to-college/"
                                    title="TENNESSEE EDUCATION LOTTERY SCHOLARSHIP PROGRAM LETS STUDENTS JAM OUT TO COLLEGE"
                                    class="featured-image"
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post1-440x264.png"
                                      alt="TENNESSEE EDUCATION LOTTERY SCHOLARSHIP PROGRAM LETS STUDENTS JAM OUT TO COLLEGE"
                                      data-pagespeed-url-hash="2695651339"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                    <span class="et_pb_extra_overlay"></span>
                                  </a>
                                </div>
                                <div class="post-content">
                                  <h2 class="entry-title">
                                    <a
                                      class="et-accent-color"
                                      style={{ color: "#681f00" }}
                                      href="http://stgblog.nationalassistancenetwork.org/2021/07/08/tennessee-education-lottery-scholarship-program-lets-students-jam-out-to-college/"
                                    >
                                      TENNESSEE EDUCATION LOTTERY SCHOLARSHIP
                                      PROGRAM LETS STUDENTS JAM OUT TO COLLEGE
                                    </a>
                                  </h2>
                                  <div class="post-meta vcard">
                                    <p>
                                      by
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                        class="url fn"
                                        title="Posts by user"
                                        rel="author"
                                      >
                                        user
                                      </a>
                                      | <span class="updated">Jul 8, 2021</span>{" "}
                                      |
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/category/budgeting/"
                                        rel="tag"
                                      >
                                        BUDGETING
                                      </a>
                                      |
                                      <a
                                        class="comments-link"
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/tennessee-education-lottery-scholarship-program-lets-students-jam-out-to-college/#comments"
                                      >
                                        0
                                        <span
                                          title="comment count"
                                          class="comment-bubble post-meta-icon"
                                        ></span>
                                      </a>
                                      |
                                      <span
                                        class="rating-stars"
                                        title="Rating: 0.00"
                                      >
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                        <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                      </span>
                                    </p>
                                  </div>
                                  <div class="excerpt entry-summary">
                                    <p>
                                      Lorem Ipsum&nbsp;is simply dummy text of
                                      the printing and typesetting industry.
                                      Lorem Ipsum has been...
                                    </p>
                                  </div>
                                </div>
                              </article>
                            </div>
                            <ul class="posts-list">
                              <li>
                                <article
                                  id="post-43"
                                  class="title-thumb-hover post-43 post type-post status-publish format-standard has-post-thumbnail hentry category-college et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/an-informational-guide-to-floridas-bright-futures-scholarship-program/"
                                    title="AN INFORMATIONAL GUIDE TO FLORIDA’S BRIGHT FUTURES SCHOLARSHIP PROGRAM"
                                    class="post-thumbnail"
                                    style={{ backgroundColor: "#681f00" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post2-150x150.png"
                                      alt="AN INFORMATIONAL GUIDE TO FLORIDA’S BRIGHT FUTURES SCHOLARSHIP PROGRAM"
                                      style={{ backgroundColor: "#681f00" }}
                                      data-pagespeed-url-hash="2319187412"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                  </a>
                                  <div class="post-content">
                                    <h3 class="entry-title">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/an-informational-guide-to-floridas-bright-futures-scholarship-program/"
                                        data-hover-color="#681f00"
                                      >
                                        AN INFORMATIONAL GUIDE TO FLORIDA’S
                                        BRIGHT FUTURES SCHOLARSHIP PROGRAM
                                      </a>
                                    </h3>
                                    <div class="post-meta vcard">
                                      <p>
                                        by
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                          class="url fn"
                                          title="Posts by user"
                                          rel="author"
                                        >
                                          user
                                        </a>
                                        |{" "}
                                        <span class="updated">Jul 8, 2021</span>{" "}
                                        |
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/category/college/"
                                          rel="tag"
                                        >
                                          COLLEGE
                                        </a>
                                        |
                                        <a
                                          class="comments-link"
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/an-informational-guide-to-floridas-bright-futures-scholarship-program/#comments"
                                        >
                                          0
                                          <span
                                            title="comment count"
                                            class="comment-bubble post-meta-icon"
                                          ></span>
                                        </a>
                                        |
                                        <span
                                          class="rating-stars"
                                          title="Rating: 0.00"
                                        >
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </article>
                              </li>
                              <li>
                                <article
                                  id="post-46"
                                  class="title-thumb-hover post-46 post type-post status-publish format-standard has-post-thumbnail hentry category-family et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/"
                                    title="COVID-19 CONTACT TRACING: WHAT IT IS AND HOW YOU COULD GET HIRED"
                                    class="post-thumbnail"
                                    style={{ backgroundColor: "#681f00" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post3-150x150.png"
                                      alt="COVID-19 CONTACT TRACING: WHAT IT IS AND HOW YOU COULD GET HIRED"
                                      style={{ backgroundColor: "#681f00" }}
                                      data-pagespeed-url-hash="3403709381"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                  </a>
                                  <div class="post-content">
                                    <h3 class="entry-title">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/"
                                        data-hover-color="#681f00"
                                      >
                                        COVID-19 CONTACT TRACING: WHAT IT IS AND
                                        HOW YOU COULD GET HIRED
                                      </a>
                                    </h3>
                                    <div class="post-meta vcard">
                                      <p>
                                        by
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                          class="url fn"
                                          title="Posts by user"
                                          rel="author"
                                        >
                                          user
                                        </a>
                                        |{" "}
                                        <span class="updated">Jul 8, 2021</span>{" "}
                                        |
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/category/family/"
                                          rel="tag"
                                        >
                                          FAMILY
                                        </a>
                                        |
                                        <a
                                          class="comments-link"
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/covid-19-contact-tracing-what-it-is-and-how-you-could-get-hired/#comments"
                                        >
                                          0
                                          <span
                                            title="comment count"
                                            class="comment-bubble post-meta-icon"
                                          ></span>
                                        </a>
                                        |
                                        <span
                                          class="rating-stars"
                                          title="Rating: 0.00"
                                        >
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </article>
                              </li>
                              <li>
                                <article
                                  id="post-49"
                                  class="title-thumb-hover post-49 post type-post status-publish format-standard has-post-thumbnail hentry category-grants et-has-post-format-content et_post_format-et-post-format-standard"
                                >
                                  <a
                                    href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/"
                                    title="HOW TO GET YOUR TIME (AND YOUR LIFE) BACK"
                                    class="post-thumbnail"
                                    style={{ backgroundColor: "#681f00" }}
                                  >
                                    <img
                                      src="http://stgblog.nationalassistancenetwork.org/wp-content/uploads/2021/07/post4-150x150.png"
                                      alt="HOW TO GET YOUR TIME (AND YOUR LIFE) BACK"
                                      style={{ backgroundColor: "#681f00" }}
                                      data-pagespeed-url-hash="193264054"
                                      onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                                    />
                                  </a>
                                  <div class="post-content">
                                    <h3 class="entry-title">
                                      <a
                                        href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/"
                                        data-hover-color="#681f00"
                                      >
                                        HOW TO GET YOUR TIME (AND YOUR LIFE)
                                        BACK
                                      </a>
                                    </h3>
                                    <div class="post-meta vcard">
                                      <p>
                                        by
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/author/user/"
                                          class="url fn"
                                          title="Posts by user"
                                          rel="author"
                                        >
                                          user
                                        </a>
                                        |{" "}
                                        <span class="updated">Jul 8, 2021</span>{" "}
                                        |
                                        <a
                                          href="http://stgblog.nationalassistancenetwork.org/category/grants/"
                                          rel="tag"
                                        >
                                          GRANTS
                                        </a>
                                        |
                                        <a
                                          class="comments-link"
                                          href="http://stgblog.nationalassistancenetwork.org/2021/07/08/how-to-get-your-time-and-your-life-back/#comments"
                                        >
                                          0
                                          <span
                                            title="comment count"
                                            class="comment-bubble post-meta-icon"
                                          ></span>
                                        </a>
                                        |
                                        <span
                                          class="rating-stars"
                                          title="Rating: 0.00"
                                        >
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-1"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-2"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-3"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-4"></span>
                                          <span class="post-meta-icon rating-star rating-star-empty rating-star-5"></span>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </article>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* <!-- .et_pb_column --> */}
                      </div>
                      {/* <!-- .et_pb_row --> */}
                    </div>
                    {/* <!-- .et_pb_section --> */}
                  </div>
                </div>
                {/* <!-- #content-area --> */}
              </div>
              {/* <!-- .container --> */}
            </div>
            {/* <!-- #main-content --> */}

            <footer class="et-l et-l--footer">
              <div class="et_builder_inner_content et_pb_gutters3">
                <div class="et_pb_section et_pb_section_0_tb_footer et_pb_with_background et_section_regular">
                  <div class="et_pb_row et_pb_row_0_tb_footer">
                    <div class="et_pb_column et_pb_column_1_2 et_pb_column_0_tb_footer et_pb_css_mix_blend_mode_passthrough">
                      <div class="et_pb_module et_pb_code et_pb_code_0_tb_footer">
                        <div class="et_pb_code_inner">
                          <p
                            align="center"
                            style={{
                              color: "#ffffff !important",
                              fontFamily: "Roboto, sans-serif",
                              fontSize: "14px",
                            }}
                          >
                            <a
                              href="https://www.nationalassistancenetwork.org/terms.html"
                              style={{ color: "#ffffff !important" }}
                              target="_blank"
                              rel="noopener"
                            >
                              Terms
                            </a>
                            &nbsp;|
                            <a
                              href="https://www.nationalassistancenetwork.org/privacy.html"
                              style={{ color: "#ffffff !important" }}
                              target="_blank"
                              rel="noopener"
                            >
                              Privacy
                            </a>
                            |&nbsp;
                            <a
                              href="https://www.nationalassistancenetwork.org/ccpa_unsub.html"
                              style={{ color: "#ffffff !important" }}
                              target="_blank"
                              rel="noopener"
                            >
                              Don't Sell My Info
                            </a>
                            |&nbsp;
                            <a
                              href="https://www.nationalassistancenetwork.org/contact_us.html"
                              style={{ color: "#ffffff !important" }}
                              target="_blank"
                              rel="noopener"
                            >
                              Contact
                            </a>
                            |&nbsp;
                            <a
                              href="https://www.nationalassistancenetwork.org/unsubscribe.html"
                              style={{ color: "#ffffff !important" }}
                              target="_blank"
                              rel="noopener"
                            >
                              Unsubscribe
                            </a>
                          </p>
                        </div>
                      </div>
                      {/* <!-- .et_pb_code --> */}
                    </div>
                    {/* <!-- .et_pb_column --> */}
                    <div class="et_pb_column et_pb_column_1_2 et_pb_column_1_tb_footer et_pb_css_mix_blend_mode_passthrough et-last-child">
                      <div class="et_pb_module et_pb_code et_pb_code_1_tb_footer">
                        <div class="et_pb_code_inner">
                          <p
                            align="center"
                            style={{
                              color: "#ccc",
                              fontFamily: "Open Sans, sans-serif",
                              fontSize: "12px",
                            }}
                          >
                            © Copyright 2017 - 2024 National Assistance Network
                            All Rights Reserved
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>

        <span title="Back To Top" id="back_to_top" class="hidden"></span>

        {/* <script>
      var xoptinSettings = {
        aid: "01HD6NAXQWBQPNP14KDYQRNY23",
        sub1: "",
        sub2: "",
        sub3: "",
        "": "[]",
      };
      initialize_ads(xoptinSettings);
    </script> */}
        {/* <script>
      window.addEventListener("sfsi_functions_loaded", function () {
        if (typeof sfsi_responsive_toggle == "function") {
          sfsi_responsive_toggle(0);
        }
      });
    </script> */}

        <div class="sfsi_sticky_icons_container_wrapper">
          <div
            class="sfsi_premium_desktop_display sfsi_premium_sticky_icons_container sfsi_premium_sticky_left_button_container sfsi_premium_sticky_up"
            style={{ textAlign: "center" }}
          >
            <a
              target="_blank"
              href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fstgblog.nationalassistancenetwork.org%2F2021%2F07%2F08%2Feverything-you-could-find-at-your-local-library%2F"
              style={{ display: "block" }}
              class=""
            >
              <div class="sfsi_premium_sticky_icon_item_container sfsi_responsive_icon_facebook_container">
                <img
                  style={{ maxHeight: "25px", display: "unset", margin: "0" }}
                  class="sfsi_premium_wicon"
                  src="http://stgblog.nationalassistancenetwork.org/wp-content/plugins/ultimate-social-media-icons/images/responsive-icon/facebook.svg"
                  data-pagespeed-url-hash="847692254"
                  onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                />
              </div>
            </a>

            <a
              target="_blank"
              href="https://twitter.com/intent/tweet?text=Hey%2C+check+out+this+cool+site+I+found%3A+www.yourname.com+%23Topic+via%40my_twitter_name&amp;url=http%3A%2F%2Fstgblog.nationalassistancenetwork.org%2F2021%2F07%2F08%2Feverything-you-could-find-at-your-local-library%2F"
              style={{ display: "block" }}
              class=""
            >
              <div class="sfsi_premium_sticky_icon_item_container sfsi_responsive_icon_twitter_container">
                <img
                  style={{ maxHeight: "25px", display: "unset", margin: "0" }}
                  class="sfsi_premium_wicon"
                  src="http://stgblog.nationalassistancenetwork.org/wp-content/plugins/ultimate-social-media-icons/images/responsive-icon/Twitter.svg"
                  data-pagespeed-url-hash="14464695"
                  onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                />
              </div>
            </a>

            <a
              target="_blank"
              href="https://api.follow.it/widgets/icon/ZXZRVXdHOHNtMGJVSXZId0sxdm83Z3p3clBDZVFTekN0bytvTktCMkM0eUZwenY0MXF0eVhlNnUrMzR1OFpNcTlMUlQ0TDBZamJBaEllQzl3WVl6YllPbXJJKzU3eUpLTERuQ05qYnY2ZWpYWS9UNEwwRkovdlBkejNJMVkzUTR8WW1mNlZEd2R4Mzd0aEZadEw4clk1S3RPSTJCRkJtWjlleGEralB4OGhzYz0=/OA==/"
              style={{ display: "none" }}
              class=""
            >
              <div class="sfsi_premium_sticky_icon_item_container sfsi_responsive_icon_follow_container">
                <img
                  style={{ maxHeight: "25px", display: "unset", margin: "0" }}
                  class="sfsi_premium_wicon"
                  src="http://stgblog.nationalassistancenetwork.org/wp-content/plugins/ultimate-social-media-icons/images/responsive-icon/Follow.png"
                  data-pagespeed-url-hash="650797394"
                  onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                />
              </div>
            </a>

            <a
              target="_blank"
              href="https://www.pinterest.com/pin/create/link/?url=http://stgblog.nationalassistancenetwork.org/2021/07/08/everything-you-could-find-at-your-local-library/"
              style={{ display: "none" }}
              class=""
            >
              <div class="sfsi_premium_sticky_icon_item_container sfsi_responsive_icon_pinterest_container">
                <img
                  style={{ maxHeight: "25px", display: "unset", margin: "0" }}
                  class="sfsi_premium_wicon"
                  src="http://stgblog.nationalassistancenetwork.org/wp-content/plugins/ultimate-social-media-icons/images/responsive-icon/Pinterest.svg"
                  data-pagespeed-url-hash="1507489094"
                  onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                />
              </div>
            </a>
          </div>
        </div>
        {/* <!--end responsive_icons-->
    <script>
      window.addEventListener("sfsi_functions_loaded", function () {
        if (typeof sfsi_plugin_version == "function") {
          sfsi_plugin_version(2.73);
        }
      });
      function sfsi_processfurther(ref) {
        var feed_id =
          "ZXZRVXdHOHNtMGJVSXZId0sxdm83Z3p3clBDZVFTekN0bytvTktCMkM0eUZwenY0MXF0eVhlNnUrMzR1OFpNcTlMUlQ0TDBZamJBaEllQzl3WVl6YllPbXJJKzU3eUpLTERuQ05qYnY2ZWpYWS9UNEwwRkovdlBkejNJMVkzUTR8WW1mNlZEd2R4Mzd0aEZadEw4clk1S3RPSTJCRkJtWjlleGEralB4OGhzYz0=";
        var feedtype = 8;
        var email = jQuery(ref).find('input[name="email"]').val();
        var filter =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (email != "Enter your email" && filter.test(email)) {
          if (feedtype == "8") {
            var url =
              "https://api.follow.it/subscription-form/" +
              feed_id +
              "/" +
              feedtype;
            window.open(
              url,
              "popupwindow",
              "scrollbars=yes,width=1080,height=760"
            );
            return true;
          }
        } else {
          alert("Please enter email address");
          jQuery(ref).find('input[name="email"]').focus();
          return false;
        }
      }
    </script> */}

        <link
          rel="stylesheet"
          id="et-builder-googlefonts-css"
          href="http://fonts.googleapis.com/css?family=Roboto:100,100italic,300,300italic,regular,italic,500,500italic,700,700italic,900,900italic&amp;subset=latin,latin-ext&amp;display=swap"
          type="text/css"
          media="all"
        />
        <script
          type="text/javascript"
          src="http://stgblog.nationalassistancenetwork.org/wp-includes/js/jquery/ui/core.min.js?ver=1.13.2"
          id="jquery-ui-core-js"
        ></script>
        <script
          type="text/javascript"
          src="http://stgblog.nationalassistancenetwork.org/wp-content/plugins/ultimate-social-media-icons/js/shuffle/modernizr.custom.min.js?ver=6.5.2"
          id="SFSIjqueryModernizr-js"
        ></script>
        <script
          type="text/javascript"
          src="http://stgblog.nationalassistancenetwork.org/wp-content/plugins/ultimate-social-media-icons/js/shuffle/jquery.shuffle.min.js?ver=6.5.2"
          id="SFSIjqueryShuffle-js"
        ></script>
        <script
          type="text/javascript"
          src="http://stgblog.nationalassistancenetwork.org/wp-content/plugins/ultimate-social-media-icons/js/shuffle/random-shuffle-min.js?ver=6.5.2"
          id="SFSIjqueryrandom-shuffle-js"
        ></script>
        <script type="text/javascript" id="SFSICustomJs-js-extra">
          {/* //<![CDATA[
      var sfsi_icon_ajax_object = {
        ajax_url:
          "http:\/\/stgblog.nationalassistancenetwork.org\/wp-admin\/admin-ajax.php",
        plugin_url:
          "http:\/\/stgblog.nationalassistancenetwork.org\/wp-content\/plugins\/ultimate-social-media-icons\/",
      };
      //]]> */}
        </script>
        <script
          type="text/javascript"
          src="http://stgblog.nationalassistancenetwork.org/wp-content/plugins/ultimate-social-media-icons/js/custom.js?ver=2.7.3"
          id="SFSICustomJs-js"
        ></script>
        <script
          type="text/javascript"
          src="http://stgblog.nationalassistancenetwork.org/wp-includes/js/masonry.min.js?ver=4.2.2"
          id="masonry-js"
        ></script>
        {/* <script type="text/javascript" id="extra-scripts-js-extra">
      //<![CDATA[
      var EXTRA = {
        images_uri:
          "http:\/\/stgblog.nationalassistancenetwork.org\/wp-content\/themes\/Extra\/images\/",
        ajaxurl:
          "http:\/\/stgblog.nationalassistancenetwork.org\/wp-admin\/admin-ajax.php",
        your_rating: "Your Rating:",
        item_in_cart_count: "%d Item in Cart",
        items_in_cart_count: "%d Items in Cart",
        item_count: "%d Item",
        items_count: "%d Items",
        rating_nonce: "123d57843c",
        timeline_nonce: "a36c311088",
        blog_feed_nonce: "0ee7df8e71",
        error: "There was a problem, please try again.",
        contact_error_name_required: "Name field cannot be empty.",
        contact_error_email_required: "Email field cannot be empty.",
        contact_error_email_invalid: "Please enter a valid email address.",
        is_ab_testing_active: "",
        is_cache_plugin_active: "no",
      };
      var et_shortcodes_strings = { previous: "Previous", next: "Next" };
      var et_pb_custom = {
        ajaxurl:
          "http:\/\/stgblog.nationalassistancenetwork.org\/wp-admin\/admin-ajax.php",
        images_uri:
          "http:\/\/stgblog.nationalassistancenetwork.org\/wp-content\/themes\/Extra\/images",
        builder_images_uri:
          "http:\/\/stgblog.nationalassistancenetwork.org\/wp-content\/themes\/Extra\/includes\/builder\/images",
        et_frontend_nonce: "f468a6cb49",
        subscription_failed:
          "Please, check the fields below to make sure you entered the correct information.",
        et_ab_log_nonce: "3665511ed4",
        fill_message: "Please, fill in the following fields:",
        contact_error_message: "Please, fix the following errors:",
        invalid: "Invalid email",
        captcha: "Captcha",
        prev: "Prev",
        previous: "Previous",
        next: "Next",
        wrong_captcha: "You entered the wrong number in captcha.",
        wrong_checkbox: "Checkbox",
        ignore_waypoints: "no",
        is_divi_theme_used: "",
        widget_search_selector: ".widget_search",
        ab_tests: [],
        is_ab_testing_active: "",
        page_id: "14",
        unique_test_id: "",
        ab_bounce_rate: "5",
        is_cache_plugin_active: "no",
        is_shortcode_tracking: "",
        tinymce_uri: "",
      };
      var et_builder_utils_params = {
        condition: { diviTheme: false, extraTheme: true },
        scrollLocations: ["app", "top"],
        builderScrollLocations: { desktop: "app", tablet: "app", phone: "app" },
        onloadScrollLocation: "app",
        builderType: "fe",
      };
      var et_frontend_scripts = {
        builderCssContainerPrefix: "#et-boc",
        builderCssLayoutPrefix: "#et-boc .et-l",
      };
      var et_pb_box_shadow_elements = [];
      var et_pb_motion_elements = { desktop: [], tablet: [], phone: [] };
      var et_pb_sticky_elements = [];
      //]]>
    </script> */}
        <script
          type="text/javascript"
          src="http://stgblog.nationalassistancenetwork.org/wp-content/themes/Extra/scripts/scripts.unified.js?ver=4.9.4"
          id="extra-scripts-js"
        ></script>
        <script
          type="text/javascript"
          src="http://stgblog.nationalassistancenetwork.org/wp-content/themes/Extra/core/admin/js/common.js?ver=4.9.4"
          id="et-core-common-js"
        ></script>

        {/* <!-- Cookie Notice plugin v2.2.3 by Hu-manity.co https://hu-manity.co/ --> */}
        <div
          id="cookie-notice"
          role="dialog"
          class="cookie-revoke-hidden cn-position-bottom cn-effect-fade cookie-notice-visible"
          aria-label="Cookie Notice"
          style={{ backgroundColor: "rgba(50, 50, 58, 1)" }}
        >
          <div class="cookie-notice-container" style={{ color: "#fff" }}>
            <span id="cn-notice-text" class="cn-text-container">
              Welcome to NationalAssistanceNetwork.org In order to provide a
              more relevant experience for you, we use cookies to enable some
              website functionality. Cookies help us see which articles most
              interest you; allow you to easily share articles on social media;
              permit us to deliver content, jobs and ads tailored to your
              interests and locations; and provide many other site benefits. For
              more information,
              <a
                href="https://www.nationalassistancenetwork.org/cookieprivacy.html"
                style={{ color: "#ffa000", textDecoration: "underline" }}
              >
                Please Review Our Cookies Policy
              </a>
              and
              <a
                href="https://www.nationalassistancenetwork.org/privacy.html"
                style={{ color: "#ffa000", textDecoration: "underline" }}
              >
                Privacy Statement
              </a>
            </span>
            <span id="cn-notice-buttons" class="cn-buttons-container">
              <a
                href="#"
                id="cn-accept-cookie"
                data-cookie-set="accept"
                class="cn-set-cookie cn-button"
                aria-label="Accept"
                style={{ backgroundColor: "#00a99d" }}
              >
                Accept
              </a>
            </span>
            <span
              id="cn-close-notice"
              data-cookie-set="accept"
              class="cn-close-icon"
              title="No"
            ></span>
          </div>
        </div>
        {/* <!-- / Cookie Notice plugin --> */}
      </body>
    </>
  );
};

export default CustomHeaderPage;
