import {
   BrowserRouter,
   Routes,
   Route,
   Router
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import AppStore from '../../config/AppStore';
import {createPublisherBtnClick} from '../../view_logics/Publishers'
import CreateAddUnit from './CreateAddUnit';
import AddUnitsList from './AddUnitsList';


const AddUnits = () => {

   const [showCreatePublishersContent, setShowCreatePublishersContent] = useState(false);
   const handleCreatePublishersClick = (menuItem) => {
      setShowCreatePublishersContent(menuItem);
   };

   let store = AppStore.getAppStoreInstance();
   const navigate = useNavigate(); // we can pass navigation to clickhandler
   const inputObj = {
      accessToken: store.getState().login.accessToken,
      url: 'create',
      history: navigate
   };

   return (
      <div className="">
         <main className="content">
            <div className="container-fluid p-0">
               <h1>Ad Units</h1>
               {!showCreatePublishersContent ?
                  <div className="col-md-3">
                     <a
                        // onClick={() => handleCreatePublishersClick('createPublishers')}
                        onClick={() => createPublisherBtnClick(inputObj)}
                        className="btn btn-primary float-right mb-3"
                     >
                        Create New Add
                     </a>
                  </div>
                  :
                  <div className="col-md-3">
                     <a
                        // onClick={() => handleCreatePublishersClick(false)}
                        className="btn btn-primary float-right mb-3"
                     >
                        Back
                     </a>
                  </div>
               }
               {showCreatePublishersContent ? <CreateAddUnit /> : <AddUnitsList />}
            </div>
         </main>
      </div>

   );
};

export default AddUnits;