import { React, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";
import { useForm, useStep } from "react-hooks-helper";
import AppStore from "../../config/AppStore";
import Constants from "../../config/Constants";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

import { sendRequestToFetchAdUnitDetails } from "../../view_logics/AddUnits/EditAddUnit";

const EditAddUnit = () => {
  let store = AppStore.getAppStoreInstance();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const accessToken = store.getState().login.accessToken;
  const [websitesData, setwebsitesData] = useState(null);
  const [publisherData, setPublisherData] = useState(null);
  const [formData, setFormData] = useState({
    type: "header_template",
    custom_html: "",
    no_of_offers: 2,
    min_ecpm: 0,
    tags: [],
    website_id: "",
    params: [""],
    encrypt_cookies_flag: 1,
    offer_rotation_time: 30,
    offer_rotation_flag: 1,
    ad_unit_name: "",
    website_name: "",
    publisher_id: "",
    redirect_url: "",
  });
  console.log(
    "🚀 ~ file: EditAddUnit.js:33 ~ EditAddUnit ~ formData:",
    formData
  );

  const [tagsData, setTagsData] = useState(null);
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + accessToken,
  };

  const { id } = useParams();
  const [adUnitInfo, setAdUnitInfo] = useState({});

  const inputObj = {
    accessToken: store.getState().login.accessToken,
    url: "editAdvertiser",
    history: navigate,
  };
  inputObj.dataHandler = setAdUnitInfo; // you setting data in this state you don't need to update setData again
  inputObj.id = id;
  useEffect(() => {
    const url = Constants.APP_SERVER_OPTION_WEBSITES_LIST_URL;
    fetch(url, {
      headers: headersToPost,
    })
      .then((response) => response.json())
      .then((data) => {
        // Store the fetched data in the state
        setwebsitesData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    const url1 = Constants.APP_SERVER_OPTION_TAGS_LIST_URL;
    fetch(url1, {
      headers: headersToPost,
    })
      .then((response) => response.json())
      .then((data) => {
        // Store the fetched data in the state
        setTagsData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    const url2 = Constants.APP_SERVER_OPTION_PUBLISHERS_LIST_URL;
    fetch(url2, {
      headers: headersToPost,
    })
      .then((response) => response.json())
      .then((data) => {
        // Store the fetched data in the state
        setPublisherData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    const fetchData = async () => {
      try {
        let result = await sendRequestToFetchAdUnitDetails(inputObj);
        console.warn(result);
        Object.entries(result).map(([key, value]) => {
          if (key == "tag_ids") {
            result.tags = value;
          }
          if (key == "parameters") {
            result.params = value;
          }
          if (key == "name") {
            result.ad_unit_name = value;
            //  result.website_name = value;
          }
          if (key == "website_name") {
            result.website_name = value;
          }
          if (key == "id") {
            result.ad_unit_id = value;
          }
          if (key == "encrypt_cookies_flag") {
            if (value !== undefined) {
              result.encrypt_cookies_flag = value;
            } else {
              result.encrypt_cookies_flag = 1;
            }
          }
        });
        console.warn(result);
        setFormData(result);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const steps = [
    { id: "step1" },
    { id: "step2" },
    { id: "step3" },
    { id: "step4" },
  ];

  //   const [formData, setFormData] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const props = {
    formData,
    setFormData,
    navigation,
    websitesData,
    publisherData,
    tagsData,
  };

  switch (step.id) {
    case "step1":
      return <Step1 {...props} />;
    case "step2":
      return <Step2 {...props} />;
    case "step3":
      return <Step3 {...props} />;
    // case "step4":
    //   return <Step4 {...props} />;
  }

  // return (

  //     <div className="">
  //         <main className="content">
  //             <div className="container-fluid p-0">
  //                 <h1>Create New Add Unit</h1>
  //                 <div className="tab-content">
  //                     <TabContentComponent onNextClick={handleStep1}  { ...props} />
  //                 </div>
  //                 <div className="row">
  //                     <div className="col-md-1 offset-md-10">
  //                         {activeTab === 1 ?  '' : <button
  //                         onClick={() => setActiveTab(activeTab-1)}
  //                         className="btn btn-warning btn-lg">Back
  //                         </button>}
  //                     </div>
  //                     <div className="col-md-1 offset-md-0">
  //                         {activeTab === 3 ?  <button className="btn btn-primary btn-lg">Finish</button> : <button
  //                         onClick={() => setActiveTab(activeTab+1)}
  //                         className="btn btn-primary btn-lg">Next
  //                         </button>}
  //                     </div>
  //                 </div>

  //                 {/* <ul className="nav nav-tabs" role="tablist">
  //                     <li
  //                         role="presentation"
  //                         className='nav-item'

  //                     >
  //                         <a className={activeTab === 1 ? 'active nav-link' : 'nav-link'}  data-bs-toggle="tab" role="tab" aria-selected="true">Step 1</a>

  //                     </li>
  //                     <li style={activeTab === 1 ? {display: 'block'} : {display: 'none'}}
  //                         role="presentation"
  //                         className='nav-item'
  //                         onClick={() => setActiveTab(2)}
  //                     >

  //                         <a className={activeTab === 2 ? 'active nav-link' : 'nav-link'}  data-bs-toggle="tab" role="tab" aria-selected="true">Step 2</a>
  //                     </li>
  //                     <li style={activeTab === 2 ? {display: 'block'} : {display: 'none'}}
  //                         role="presentation"
  //                         className='nav-item'
  //                         onClick={() => setActiveTab(3)}
  //                     >
  //                         <a className={activeTab === 3 ? 'active nav-link' : 'nav-link'}  data-bs-toggle="tab" role="tab" aria-selected="true">Step 3</a>
  //                     </li>
  //                 </ul> */}
  //             </div>
  //         </main>
  //     </div>
  // );
};

export default EditAddUnit;
