import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { sendRequestToFetchApiImplementationDetails } from "../view_logics/ApiImplementation";
import AppStore from "../config/AppStore";
import Constants from "../config/Constants";
import { sendPUTRequest } from "../utils/common_functions";

Modal.setAppElement("#root");

const ApiImplementationModal = ({ isOpen, onClose, data }) => {
  let store = AppStore.getAppStoreInstance();
  let accessToken = store.getState().login.accessToken;
  let publisherId = store.getState().login?.publisherId;
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const [loadingKeys, setLoadingKeys] = useState(false);

  const [apiKeys, setApiKeys] = useState({
    key1: "",
    key2: "",
  });

  useEffect(() => {
    fetchApiKeys();
  }, [publisherId]);

  const fetchApiKeys = async () => {
    if (publisherId != null) {
      setLoadingKeys(true);
      const res = await sendRequestToFetchApiImplementationDetails({
        id: publisherId,
        accessToken: accessToken,
      });
      console.log("🚀 ~ fetchApiKeys ~ res:", res);
      if (res?.id) {
        setApiKeys({ key1: res?.api_key, key2: res?.api_key2 });
      }
      setLoadingKeys(false);
    }
  };

  function resetAPISuccessHandler(response, request) {
    // if (handlersObj != undefined && handlersObj.hasOwnProperty("AdvertiserId")) {
    //    handlersObj.tickerTypes(response);
    // }
    fetchApiKeys(); // Reload API keys after resetting
    // request.dataHander(response);
  }
  function resetAPIFailedHandler() {}

  const inputObj = {
    accessToken: store.getState().login.accessToken,
    history: navigate,
  };
  const resetApiKey = async (type) => {
    try {
      setLoadingKeys(true);
      const headersToPost = {
        "Content-type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + inputObj.accessToken,
      };
      const url = Constants.APP_SERVER_OPTION_RESET_API_KEYS_URL;
      const data = { type: type, publisher_id: publisherId };
      const response = await sendPUTRequest(
        url,
        data,
        headersToPost,
        resetAPISuccessHandler,
        resetAPIFailedHandler
      );

      if (response.ok) {
        console.log(`Successfully reset ${type}`);
        await fetchApiKeys(); // Reload API keys after resetting
      } else {
        console.error("Failed to reset key:", response.statusText);
      }
    } catch (error) {
      console.error("Error resetting API key:", error);
    } finally {
      setLoadingKeys(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Api integration"
      className="custom-modal"
    >
      <div className="d-flex flex-column gap-3 p-2">
        <h2>API IMPLEMENTATION</h2>

        <div className="border border-dark d-flex flex-column gap-3 p-4">
          <h4 className="fw-bold" style={{ lineHeight: "26px" }}>
            API implementation can be used to access your reports, for
            integrating it with your CRM.
            <br />
            We provide 2 working API Keys for your convenience. You can reset
            them anytime.
          </h4>

          {/* <h5 className="fw-bold" style={{ color: "#0dcaf0" }}>
            <u> Review our Developer Documentation </u>
          </h5> */}
          <a
            href="/api_implementation"
            target="_blank"
            rel="noopener noreferrer"
            title="Open Developer Documentation"
            style={{ color: "#0dcaf0" }}
          >
            <u> Review our Developer Documentation </u>
          </a>

          <div className="ps-4 d-flex flex-column gap-1">
            {loadingKeys && (
              <h5 className="card-title placeholder-glow d-flex flex-column gap-2">
                <span className="placeholder col-6"></span>
                <span className="placeholder col-6"></span>
              </h5>
            )}
            {!loadingKeys && !apiKeys?.key1 && !apiKeys?.key2 && (
              <p>No Keys Provided.</p>
            )}
            {apiKeys?.key1 !== "" && (
              <div className="d-flex gap-5">
                <h5 className="fw-bold">API Key : {apiKeys?.key1}</h5>
                <h5
                  className="fw-bold"
                  style={{ color: "#0d6efd", cursor: "pointer" }}
                  onClick={() => resetApiKey("key1")}
                >
                  <u> Reset Key </u>
                </h5>
              </div>
            )}
            {apiKeys?.key2 !== "" && (
              <div className="d-flex gap-5">
                <h5 className="fw-bold">API Key : {apiKeys?.key2}</h5>
                <h5
                  className="fw-bold"
                  style={{ color: "#0d6efd", cursor: "pointer" }}
                  onClick={() => resetApiKey("key2")}
                >
                  <u> Reset Key </u>
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ApiImplementationModal;
