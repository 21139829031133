import { React, useRef, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import Choices from "choices.js";
import "choices.js/public/assets/styles/choices.min.css";
import { isUserTokenValid, readUserToken } from "../../utils/common_functions";
import AppStore from "../../config/AppStore";
import { sendCreateAdUnitFormSubmitRequest } from "../../view_logics/AddUnits";
import Alert from "../../components/common/Alert";
import useAlertOnNavigation from "../../utils/useAlertOnNavigation";
import Spinner from "../../components/common/Spinner";
import LoadingButton from "../../components/common/LoadingButton";
import "./choices-custom-styles.css";

const Step2 = (props) => {
  const { id } = useParams();
  const [adUnitFormLoading, setAdUnitFormLoading] = useState(false);
  const store = AppStore.getAppStoreInstance();
  const navigate = useNavigate();
  const selectRef = useRef(null);
  const selectTagsRef = useRef(null);
  const name_reference = useRef(null);
  const [filteredWebsites, setFilteredWebsites] = useState(null);
  const { userType, publisherId } = useSelector((state) => state?.login);

  const formik = useFormik({
    initialValues: {
      type: "",
      custom_html: "",
      no_of_offers: "",
      min_ecpm: "",
      website_id: "",
      publisher_id: "",
      website_name: "",
      offer_rotation_time: 0,
      offer_rotation_flag: 0,
      ad_unit_name: "",
      ad_unit_id: "",
      name: "",
      encrypt_cookies_flag: 0,
      tags: [],
      params: [],
    },
    onSubmit: (event) => {
      //alert("onsubmnit");
      event.preventDefault();
    },
  });
  useEffect(() => {
    const selectedValues = props.formData.params;
    if (Array.isArray(selectedValues) && selectedValues.length > 0) {
      selectedValues.forEach((value) => {
        const option = selectRef.current.querySelector(`[value="${value}"]`);
        if (option) {
          option.selected = true;
        }
      });
    }

    if (userType !== "admin") {
      const filteredWebsites = props.websitesData.filter(
        (website) => website.publisher_id === publisherId
      );
      setFilteredWebsites(filteredWebsites);
      if (props.formData.website_id) {
        localWebsiteAsPerPublisher(props.formData.publisher_id);
      }
    } else {
      if (props.formData.publisher_id !== -1) {
        localWebsiteAsPerPublisher(props.formData.publisher_id);
      }
    }

    const choices = new Choices(selectRef.current, {
      removeItemButton: true,
    });

    const selectedTags = props.formData.tags;
    if (Array.isArray(selectedTags) && selectedTags.length > 0) {
      selectedTags.forEach((value) => {
        const option = selectTagsRef.current.querySelector(
          `[value="${value}"]`
        );
        if (option) {
          option.selected = true;
        }
      });
    }

    // const selectedOption =
    //   document.getElementById("website_id").options[
    //     document.getElementById("website_id").selectedIndex
    //   ];
    // let optionHTML = selectedOption.innerHTML;
    // optionHTML = optionHTML.replace(/\s/g, "");
    // props.formData.website_name = optionHTML;

    // let ad_unit_name = document.getElementById("ad_unit_name").value;
    // const newValue = ad_unit_name.replace(optionHTML + "_", "");
    // document.getElementById("ad_unit_name").value = newValue;
    const choices1 = new Choices(selectTagsRef.current);

    return () => {
      choices.destroy();
    };
  }, []);

  const handleParamsChange = (event, props) => {
    // Get the selected options as an array of values
    const selectedOptions = Array.from(event.target.selectedOptions).map(
      (option) => option.value
    );
    props.formData.params = selectedOptions;
  };
  const handleTagsChange = (event, props) => {
    // Get the selected options as an array of values
    const selectedOptions = Array.from(event.target.selectedOptions).map(
      (option) => option.value
    );
    props.formData.tags = selectedOptions;
  };

  const sendRequestToCreateAdUnit = (event) => {
    event.preventDefault();
    console.log(
      "🚀 ~ file: Step2.js:97 ~ sendRequestToCreateAdUnit ~ props:",
      event
    );

    const formData = new FormData(event.target);
    const serializedData = {};

    for (let [name, value] of formData.entries()) {
      if (name && value) {
        serializedData[name] = value;
      }
    }
    if (serializedData.ad_unit_name == "" || serializedData.website_id == "") {
      document.getElementById("ad_unit_name").focus();
      document.getElementById("website_id").focus();
      return;
    } else {
      props.formData.ad_unit_name = serializedData.ad_unit_name;
    }
    if (props.formData.encrypt_cookie_flag) {
      props.formData.encrypt_cookies_flag = props.formData.encrypt_cookie_flag;
      delete props.formData.encrypt_cookie_flag;
    }

    //delete props.formData.website_name;
    if (id) {
      props.formData.ad_unit_id = id;
    }
    props.formData.publisher_id =
      userType === "admin"
        ? document.getElementById("publisher_id").value
        : publisherId;

    // alert(props.formData.publisher_id);
    console.log("🚀 ~ sendRequestToCreateAdUnit ~ formData:", props.formData);

    const inputObj = {
      accessToken: store.getState().login.accessToken,
      url: "/adunits",
      dataToPost: props.formData,
      history: navigate,
      loadingHandler: setAdUnitFormLoading,
    };
    setAdUnitFormLoading(true);
    sendCreateAdUnitFormSubmitRequest(inputObj);
  };

  const handlePublisherChange = (event) => {
    if (event.target.value) {
      const publisherId = Number(event.target.value);
      const filteredWebsites = props.websitesData.filter(
        (website) => website.publisher_id === publisherId
      );
      props.formData.website_name = "";
      props.setFormData({
        ...props.formData,
        publisher_id: publisherId,
      });
      setFilteredWebsites(filteredWebsites);
    } else {
      setFilteredWebsites(null);
    }
  };

  const localWebsiteAsPerPublisher = (publisher_id) => {
    const filteredWebsites = props.websitesData.filter(
      (website) => website.publisher_id === parseInt(publisher_id, 10)
    );
    const website = filteredWebsites.find(
      (website) => website.id === parseInt(props.formData.website_id, 10)
    );
    props.formData.website_name = website?.name;

    setFilteredWebsites(filteredWebsites);
  };

  const handleWebsiteChange = (event, setFormValue) => {
    const selectElement = event.target;
    if (
      event.target.value &&
      selectElement.id === "website_id" &&
      selectElement.selectedIndex !== -1 &&
      selectElement.value != -1
    ) {
      const website = filteredWebsites.find(
        (website) => website.id === Number(event.target.value)
      );
      //setFormValue("website_name", website.name); //formik
      props.setFormData({
        ...props.formData,
        website_name: website.name,
        website_id: selectElement.value,
      });

      return;
    }
  };

  const paramOptions = [
    {
      value: "first_name",
      label: "First Name",
      description: "--- First Name of the User",
    },
    {
      value: "last_name",
      label: "Last Name",
      description: "--- Last Name of the User",
    },
    {
      value: "gender",
      label: "Gender",
      description: "--- Gender of the User",
    },
    { value: "email", label: "Email", description: "--- Email of the User" },
    {
      value: "dob",
      label: "DOB",
      description: "--- Date of Birth of the User",
    },
    {
      value: "address",
      label: "Address",
      description: "--- Address of the User",
    },
    { value: "zip", label: "ZIP", description: "--- Zip code of the User" },
    { value: "city", label: "City", description: "--- City of the User" },
    { value: "state", label: "State", description: "--- State of the User" },
    {
      value: "country",
      label: "Country",
      description: "--- Country of the User",
    },
    { value: "phone", label: "Phone", description: "--- Phone of the User" },
    {
      value: "sub1",
      label: "Sub1",
      description:
        "--- The primary traffice source or channel (e.g., Search, Socail, Direct, Email",
    },
    {
      value: "sub2",
      label: "Sub2",
      description:
        "--- The specific sub-source within the primary traffic source(e.g., Google Search, Facebook Traffic",
    },
    {
      value: "sub3",
      label: "Sub3",
      description:
        "--- The most granular level of traffic source, providing additional context",
    },
  ];

  return (
    <div className="">
      <main className="content">
        <div className="container-fluid p-0">
          <h1>
            {props.formData.ad_unit_id !== ""
              ? "Create New Add Unit"
              : "Edit Add Unit"}
          </h1>
          <div></div>

          <div className="tab-content">
            <div className="tab-pane active" id="tab-1" role="tabpanel">
              <Formik
                initialValues={props.formData || formik.initialValues}
                // validationSchema={Yup.object().shape({
                //   website_id: Yup.number().required("Please select Website ID"),
                //   ad_unit_name: Yup.string().required("Please Enter name"),
                // })}
                enableReinitialize
              >
                {({ handleSubmit, errors, isValid, setFieldValue, values }) => {
                  console.log(
                    "🚀 ~ file: CreateWebsite.js:166 ~ CreateWebsite ~ errors:",
                    values
                  );
                  return (
                    <div>
                      <form onSubmit={sendRequestToCreateAdUnit}>
                        <input
                          type="hidden"
                          name="_token"
                          value="C5Oe8oLVgFK6wo5dqcWEYVrjvHXO1yrmMsiMvqnf"
                        />
                        <div className="card">
                          <div className="card-header">
                            <div className="row">
                              <div className="mb-3 col-md-10">
                                <h5 className="card-title">STEP 2 - Configs</h5>
                              </div>
                              <div className="mb-3 col-md-2 text-align-right">
                                <button
                                  className="btn btn-danger"
                                  onClick={() => props.navigation.next()}
                                >
                                  Advanced Options
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="card-body">
                            {userType == "admin" && (
                              <>
                                <div className="row mb-3">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="publisher_id"
                                    >
                                      Select Publisher
                                    </label>
                                    <Field
                                      as="select"
                                      name="publisher_id"
                                      id="publisher_id"
                                      className="form-control"
                                      onClick={(e) => handlePublisherChange(e)}
                                    >
                                      <option value="">
                                        Please Select Publisher
                                      </option>
                                      {props.publisherData &&
                                        Array.isArray(props.publisherData) &&
                                        props.publisherData.map(
                                          (publisher, index) => (
                                            <option
                                              key={index}
                                              value={publisher.id}
                                            >
                                              {publisher.first_name +
                                                " " +
                                                publisher.last_name}
                                            </option>
                                          )
                                        )}
                                    </Field>

                                    <ErrorMessage
                                      name="publisher_id"
                                      component="div"
                                      className="invalid-feedback"
                                      style={{ display: "block" }}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="row mb-3">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="ad_unit_name"
                                >
                                  Select Website
                                </label>
                                {/* <Field as="select" name="website_id" id="website_id" className="form-control"
                                                        // onChange={props.setFormData}
                                                        onClick={handleWebsiteChange}
                                                    >
                                                        <option value="-1">Please Select Website</option>
                                                        <option value="1">Website 1</option>
                                                        <option value="2">Website 2</option>
                                                        <option value="3">Website 3</option>
                                                    </Field> */}
                                <Field
                                  // value={props.formData.website_id}
                                  as="select"
                                  name="website_id"
                                  id="website_id"
                                  className="form-control"
                                  onChange={(e) => {
                                    const selectedValue = e.target.value; // Get the selected value
                                    setFieldValue("website_id", selectedValue); // Update Formik's state
                                    handleWebsiteChange(e, setFieldValue); // Call your custom handler if needed
                                  }}
                                  // value={props.formData.website_id}
                                >
                                  <option value="">
                                    Please Select Website
                                  </option>
                                  {filteredWebsites &&
                                    Array.isArray(filteredWebsites) &&
                                    filteredWebsites.map((website, index) => (
                                      <option key={index} value={website.id}>
                                        {website.name}
                                      </option>
                                      // selected={props.formData.website_id == website.id? true : false}
                                    ))}
                                </Field>

                                <ErrorMessage
                                  name="website_id"
                                  component="div"
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="mb-3 col-md-12">
                                <label className="form-label" htmlFor="Type">
                                  Ad Unit Name
                                </label>
                                <div className="d-flex">
                                  <div
                                    className="d-flex align-items-center"
                                    style={{ width: "max-content" }}
                                  >
                                    <label htmlFor="Type" aria-readonly="true">
                                      {props.formData.website_name}
                                    </label>
                                    {/* <Field
                                    type="text"
                                      name="website_name"
                                      id="website_name"
                                      className="form-control"
                                      readOnly
                                      // innerRef={name_reference}
                                    /> */}
                                  </div>
                                  <div
                                    className="d-flex gap-1"
                                    style={{ flex: 1 }}
                                  >
                                    <span style={{ alignSelf: "flex-end" }}>
                                      __
                                    </span>
                                    {!id ? (
                                      <Field
                                        type="text"
                                        id="ad_unit_name"
                                        name="ad_unit_name"
                                        className="form-control"
                                        onChange={(e) => {
                                          props.setFormData({
                                            ...props.formData,
                                            ad_unit_name: e.target.value,
                                          });
                                        }}
                                        required
                                        //value={props.formData.ad_unit_name}
                                      />
                                    ) : (
                                      <Field
                                        type="text"
                                        id="ad_unit_name"
                                        name="ad_unit_name"
                                        className="form-control"
                                        onChange={(e) => {
                                          props.setFormData({
                                            ...props.formData,
                                            ad_unit_name: e.target.value,
                                          });
                                        }}
                                        required
                                        // value={props.formData.ad_unit_name}
                                      />
                                    )}
                                    <ErrorMessage
                                      name="ad_unit_name"
                                      component="div"
                                      className="invalid-feedback"
                                      style={{ display: "block" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group">
                                <label className="form-label" htmlFor="tags">
                                  Select Tags
                                </label>

                                <select
                                  name="tags"
                                  id="tags"
                                  ref={selectTagsRef}
                                  className="form-control"
                                  multiple
                                  onChange={(event) =>
                                    handleTagsChange(event, props)
                                  }
                                  defaultValue={props.formData.tags}
                                >
                                  {props.tagsData &&
                                    Array.isArray(props.tagsData) &&
                                    props.tagsData.map((tag, index) => (
                                      <option key={index} value={tag.id}>
                                        {tag.name}
                                      </option>
                                      // selected={props.formData.website_id == website.id? true : false}
                                    ))}
                                  {/* <option value="1">Tag 1</option>
                                                        <option value="2">Tag 2</option>
                                                        <option value="3">Tag 3</option> */}
                                </select>
                                <ErrorMessage
                                  name="tags"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="leadPostingUrl"
                                >
                                  Pass Parameters
                                  {/* <span className="text-danger">
                                    &nbsp;<strong>*</strong>
                                  </span> */}
                                </label>
                                <select
                                  name="params"
                                  id="params"
                                  ref={selectRef}
                                  className="form-control choices-multiple"
                                  multiple
                                  onChange={(event) =>
                                    handleParamsChange(event, props)
                                  }
                                  value={props.formData.params}
                                >
                                  {paramOptions.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      <span className="option-label">
                                        {option.label}
                                      </span>
                                      <span className="option-description">
                                        {option.description}
                                      </span>
                                    </option>
                                  ))}
                                </select>
                                <ErrorMessage
                                  name="params"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="d-flex">
                          <button
                            className="btn btn-warning btn-lg"
                            onClick={() => props.navigation.previous()}
                          >
                            Back
                          </button>

                          {adUnitFormLoading ? (
                            <LoadingButton size="btn-lg ml-5" name="Finish" />
                          ) : (
                            <button
                              // onClick={() => sendRequestToCreateAdUnit(props)}
                              className="btn btn-primary btn-lg ml-5"
                              type="submit"
                            >
                              Finish
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </main>

      {id && !props.formData?.id && <Spinner />}
    </div>
  );
};
export default Step2;
