import { useField } from "formik";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useState, useEffect } from "react";
import ReactSelect from "react-select";

const MultiSelectField = forwardRef((props, ref) => {
  const { options = null, isMulti = false, disabled, values } = props;
  console.log("🚀 ~ MultiSelectField ~ values:", values);
  const [data, setData] = useState([]);

  const focus = () => {
    // Check if selectRef exists and is not null
    if (selectRef && selectRef.current) {
      selectRef.current.focus();
    }
  };

  // Create a ref for the Select component
  const selectRef = useRef(null);

  // Expose the focus function to the parent component
  useImperativeHandle(ref, () => ({
    focus: focus,
  }));

  const [field, meta, helpers] = useField(props);

  const handleChange = (selectedOption) => {
    helpers.setValue(selectedOption);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  return (
    <ReactSelect
      ref={selectRef}
      className="flex-grow-1 flex-shrink-1 flex-basis-auto"
      // id={props.name}
      options={options}
      value={field.value}
      onChange={handleChange}
      onBlur={handleBlur}
      name={field.name}
      defaultOptions
      getOptionLabel={(option) => option.name || option.label}
      isMulti={isMulti}
      getOptionValue={(option) => option.id}
      isDisabled={disabled}
      isClearable={true}
      isSearchable={true}
    />
  );
});

export default MultiSelectField;
