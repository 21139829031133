import React, { useState, useEffect } from "react";
import DynamicScriptLoader from "../DynamicScriptLoader";

const CustomAdSequencePage = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  useEffect(() => {
    if (isScriptLoaded) {
    // Define the script to be executed
    var id=(window.location.origin === "https://ads.xoptin.com")
      ? "01JGYBM4HCZA3EG2C4FBDKR535"
      : "01HDEW998CYMANK0PFJVGJZMBK";
    const script = document.createElement("script");
    script.innerHTML = `
    var xoptinSettingsAd = {
      aid: "`+id+`",
      redirectURL: "",
      sub1: "",
      sub2: "",
      sub3: "",
      "": "[]",
      auto_adjust_height: 'true',
    };
    initialize_custom_ad_sequence(xoptinSettingsAd);
    `;
    // Append the script to the document body
    const footer = document.querySelector("footer");
    footer.appendChild(script);
    
    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }
  }, [isScriptLoaded]);

  return (
    <>
      <>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=no"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:400,700"
          rel="stylesheet"
        />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.0.0/crypto-js.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/lz-string/1.4.4/lz-string.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/device-uuid@1.0.4/lib/device-uuid.min.js"></script>
        <script src="https://cdn.rawgit.com/kimmobrunfeldt/progressbar.js/0.5.6/dist/progressbar.js"></script>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://www.surveypremium.com/3.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="css/custom_ad_sequence.css"
        />
        <DynamicScriptLoader onScriptLoad={() => setIsScriptLoaded(true)} />
      </>

      <div className="">
        <div
          className="wrapper d-flex flex-column"
          style={{ minHeight: "auto !important" }}
        >
          <div
            style={{ minHeight: "auto !important" }}
            className="wrapper d-flex"
          >
            {/* <!--Header Start--> */}
            <header className="hdr" style={{ width: "100%" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <img
                      title=""
                      alt=""
                      src="https://s3.amazonaws.com/leadgen-prod/3/images/logo01.png"
                    />
                  </div>
                </div>
              </div>
            </header>
            {/* <!--Header End--> */}
          </div>
          <div
            class="row body_content"
            style={{ marginRight: "0px", marginLeft: "0px" }}
          >
            <section order="1">
              <div
                class="col-md-12 qa1_bx01 active"
                id={window.location.origin === "https://ads.xoptin.com"
                  ? "01JGYBM4HCZA3EG2C4FBDKR535"
                  : "01HDEW998CYMANK0PFJVGJZMBK"}
                style={{ height: "650px" }}
              >
                <iframe
                  id="laodCustomAdSequence"
                  width="99%"
                  style={{ border: "0px", minHeight: "650px", height: "650px" }}
                ></iframe>
              </div>
            </section>
          </div>
          <footer className="footer01">
            <div className="col-md-12">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 footer01_bx01">
                    <p>
                      {`PROGRAM REQUIREMENTS – Updated August 21, 2019. To earn
                      your reward, you must: 1) be a U.S. resident and be
                      eighteen (18) years of age or over; 2) provide all
                      complete and accurate registration information; 3)
                      complete the entire qualifying survey; 4) review our
                      offers; 5) For Restaurant.com gift card, complete any
                      three (3) offers on gift card page. For Walmart / Vape
                      gift card - $25 value complete any 5 (five) offers and for
                      - $100 value complete any 8 (eight) offers. For Walmart /
                      Vape offers which are split into three (3) tiers, Silver,
                      Gold and Platinum, based on the incentive’s value. Offers
                      are completed when you fulfill the individual requirements
                      of each offer (may require purchase or joining a paid
                      subscription program. Completion of offers must be done
                      within thirty (30) days from when you complete your first
                      offer. Offers and Gifts are limited to one (1) offer per
                      household (persons living at the same address) within any
                      twelve (12) calendar month period. The SurveyPremium`}
                      <a
                        style={{ color: "#3f99e6" }}
                        href="offer_chart.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Offer Chart
                      </a>
                      contains the terms of several of our offers, including
                      descriptions, initial commitments, ongoing obligations and
                      how to cancel. We reserve the right to substitute a gift
                      card of greater or equivalent value for any offer. Failure
                      to follow guidelines will result in disqualification.
                    </p>
                    <p>
                      By participating in our program, you agree to the
                      <a
                        style={{ color: "#3f99e6" }}
                        href="terms.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms &amp; Conditions
                      </a>
                      and
                      <a
                        style={{ color: "#3f99e6" }}
                        href="privacy.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                      . This includes your consent to our sharing your
                      personally identifiable information with our Marketing
                      Partners for which we may be compensated.
                    </p>
                    <p>
                      SurveyPremium administers this website and does not claim
                      to represent or own any of the trademarks, trade names or
                      rights associated with the displayed brands or any of the
                      incentives which are the property of their respective
                      owners who do not own, endorse or promote SurveyPremium or
                      this promotion.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 footer01_bx02 text-center">
                    <p className="alt01">
                      <a
                        href="privacy.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                      &nbsp;|&nbsp;
                      <a
                        href="terms.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms and Conditions
                      </a>
                      &nbsp;|&nbsp;
                      <a
                        href="unsubscribe.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Unsubscribe
                      </a>
                      &nbsp;|&nbsp;
                      <a
                        href="contact_us.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Contact Us
                      </a>
                      &nbsp;|&nbsp;
                      <a
                        href="faq.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        FAQ
                      </a>
                      &nbsp;|&nbsp;
                      <a
                        href="giftcard_lookup.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Redeem Your Gift Card
                      </a>
                    </p>
                    <p className="alt02">
                      ©2024 Survey Premium™ All rights reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
        <div id="lpFormSettingsContainer">
          <input
            type="hidden"
            id="landing_page_id"
            name="landing_page_id"
            value="144"
          />
          <input
            type="hidden"
            id="is_prepop_allowed"
            name="is_prepop_allowed"
            value="0"
          />
          <input
            type="hidden"
            id="is_captcha_disabled"
            name="is_captcha_disabled"
            value="1"
          />
          <input type="hidden" id="tmg_flag" name="tmg_flag" value="0" />
          <input type="hidden" id="pd_flag" name="pd_flag" value="0" />
          <input
            type="hidden"
            id="tcpa_rendering_method"
            name="tcpa_rendering_method"
            value="popup"
          />
          <input type="hidden" id="is_cpc" name="is_cpc" value="0" />
          <input type="hidden" id="is_cpa" name="is_cpa" value="0" />
          <input type="hidden" id="is_organic" name="is_organic" value="1" />
          <input type="hidden" id="cpc_trns_id" name="cpc_trns_id" value="0" />
          <input type="hidden" id="cs_click_id" name="cs_click_id" value="0" />
          <input type="hidden" id="referral_id" name="referral_id" value="0" />
          <input
            type="hidden"
            id="internal_short_coreg_flag"
            name="internal_short_coreg_flag"
            value="0"
          />
          <input
            type="hidden"
            id="cpc_reg_linkout_flag"
            name="cpc_reg_linkout_flag"
            value="0"
          />
          <input
            type="hidden"
            id="cpc_reg_linkout_url"
            name="cpc_reg_linkout_url"
            value=""
          />
          <input
            type="hidden"
            id="is_ifficient_short_coreg"
            name="is_ifficient_short_coreg"
            value="0"
          />
        </div>
        <footer>
          <script></script>
        </footer>
      </div>
    </>
  );
};

export default CustomAdSequencePage;
