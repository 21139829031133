import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";

import AppStore from "../../config/AppStore";
import { isUserTokenValid, readUserToken } from "../../utils/common_functions";

import { sendRequestGetAdvertiserOffers } from "../../view_logics/AdvertiserOffers/AdvertiserOffer";
import { editAdvertiserOfferLinkClick } from "../../view_logics/AdvertiserOffers/EditAdvertiserOffer";
import { convertToTitleCase } from "../../../utils/common_functions";

const AdvertiserOffersList = ({ filteredData }) => {
  const [data, setData] = useState([]);

  const store = AppStore.getAppStoreInstance();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const inputObj = {
    accessToken: store.getState().login.accessToken,
    url: "editAdvertiser",
    history: navigate,
    data: [],
    dataHander: setData,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await sendRequestGetAdvertiserOffers(inputObj);
        let jsonData = inputObj.data;
        console.log("returned data from sendRequestGetAdvertiserOffers is:");
        console.log(inputObj);
        // setData(jsonData);  //this is bad request is recursion
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    setData(filteredData.length > 0 ? filteredData : []);
  }, [filteredData]);
  return (
    <div className="col-12 col-xl-12">
      <div className="card">
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Payout</th>
              <th>CAP</th>
              <th>Type</th>
              <th>URL</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((advertiserOffer, index) => (
                <tr
                  key={advertiserOffer.id}
                  className={
                    // advertiserOffer?.type === "cpa_offer"
                    //   ? "table-primary" // Bootstrap class for blue background
                    //   : advertiserOffer?.is_disabled == 1
                    //   ? "table-danger"
                    //   : index % 2 === 0
                    //   ? "table-success"
                    //   : ""
                    
                      advertiserOffer?.is_disabled == 1
                      ? "table-danger"
                      : index % 2 === 0
                      ? "table-success"
                      : ""
                  }
                >
                  <td>{advertiserOffer.id}</td>
                  <td>{advertiserOffer.name}</td>
                  <td>{advertiserOffer.advertiser_payout}</td>
                  <td>
                    {advertiserOffer.cap +
                      ` (${convertToTitleCase(advertiserOffer?.cap_unit)})`}
                  </td>
                  <td
                  className={
                    advertiserOffer?.type === "cpa_offer"
                      ? "table-warning" : ""
                  }
                  >{advertiserOffer.type.replace("_offer", "").toUpperCase()}</td>
                  <td className="text-truncate" style={{ maxWidth: "130px" }}>
                  <a 
                      href={
                        advertiserOffer.offer_url.startsWith("http://") || advertiserOffer.offer_url.startsWith("https://")
                          ? advertiserOffer.offer_url
                          : `https://${advertiserOffer.offer_url}`
                      }
                      target="_blank" 
                      rel="noopener noreferrer" 
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {advertiserOffer.offer_url}
                    </a>
                  </td>
                  {/* <td className="d-none d-md-table-cell">{advertiser.BillingInf?.CompanyName}</td> */}
                  <td className="table-action">
                    <a
                      href="#"
                      onClick={() => {
                        inputObj.id = advertiserOffer.id;
                        editAdvertiserOfferLinkClick(inputObj);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-edit-2 align-middle"
                      >
                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                      </svg>
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10">No records found....</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default AdvertiserOffersList;
