import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Router,
  useNavigate,
} from "react-router-dom";
import { omitBy } from "lodash";
import AppStore from "../../config/AppStore";

import { createAdvertiserOfferBtnClick } from "../../view_logics/AdvertiserOffers/CreateAdvertiserOffer";

import CreateAdvertiserOffer from "./CreateAdvertiserOffer";
import AdvertiserOffersList from "./AdvertiserOffersList";
import AdvertiserOffersFilter from "./AdvertiserOffersFilter";
import {
  sendRequestGetAdvertisersList,
  sendRequestGetTagsList,
  sendRequestGetAdvertiserOffers,
} from "../../view_logics/AdvertiserOffers/AdvertiserOffer";

function AdvertiserOffers() {
  const [showCreateAdvertiserContent, setShowCreateAdvertiserContent] =
    useState(false);
  const [advertisersList, setAdvertisersList] = useState([]);
  const [advertiserOffers, setAdvertiserOffers] = useState([]);

  const handleCreateAdvertiserClick = (menuItem) => {
    setShowCreateAdvertiserContent(menuItem);
  };

  let store = AppStore.getAppStoreInstance();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const inputObj = {
    accessToken: store.getState().login.accessToken,
    url: "create",
    history: navigate,
  };

  const fetchInitialData = async () => {
    try {
      const accessToken = store.getState().login.accessToken;
      const advertisersResult = await sendRequestGetAdvertisersList({
        accessToken,
      });
      if (advertisersResult) {
        setAdvertisersList(advertisersResult);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const handleSearch = async (searchPayload) => {
    try {
      const accessToken = store.getState().login.accessToken;
      const params = omitBy(
        searchPayload,
        (value) => value == null || value === "" || value === undefined
      );

      const inputObj = {
        accessToken,
        dataHander: setAdvertiserOffers,
        ...params,
      };
      const offersResult = await sendRequestGetAdvertiserOffers(inputObj);
      if (offersResult) {
        setAdvertiserOffers(offersResult);
      } else {
        setAdvertiserOffers([]);
      }
    } catch (error) {
      console.error("Error searching offers:", error);
    }
  };

  return (
    <div className="">
      <main className="content">
        <div className="container-fluid p-0">
          <h1>Advertiser Offers</h1>
          <AdvertiserOffersFilter
            advertisersList={advertisersList}
            onSearch={handleSearch}
          />
          {!showCreateAdvertiserContent ? (
            <div className="col-md-3">
              <a
                // onClick={() => handleCreateAdvertiserClick('createadvertiser')}
                onClick={() => createAdvertiserOfferBtnClick(inputObj)}
                className="btn btn-primary float-right mb-3"
              >
                Create New Offer
              </a>
            </div>
          ) : (
            <div className="col-md-3">
              <a
                onClick={() => handleCreateAdvertiserClick(false)}
                className="btn btn-primary float-right mb-3"
              >
                Back
              </a>
            </div>
          )}
          <hr />
          {showCreateAdvertiserContent ? (
            <CreateAdvertiserOffer />
          ) : (
            <AdvertiserOffersList filteredData={advertiserOffers} />
          )}
        </div>
      </main>
    </div>
  );
}

export default AdvertiserOffers;
