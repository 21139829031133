import React from "react";
import "./styles.css";

const ApiImplementation = () => {
  const jsonData = [
    {
      date: "2024-12-23",
      sub1: "99",
      sub2: "100",
      sub3: "101",
      gross_income: 0.0,
      net_income: 0.0,
      clicks: "2",
    },
  ];
  const apiUrl =
    "https://adsapi.xoptin.com/reporting/v1/publisher/sourceStatsReport/?from={start_date}&to={end_date}";
  return (
    <div
      style={{
        paddingLeft: "20px",
      }}
      className="api-integration-documentation"
    >
      <div className="logo">
        <img
          src="https://www.xoptin.com/images/logo01.png"
          alt="xOptin Logo"
          width="200"
        />
      </div>

      <h1>API Integration Documentation</h1>

      <p>
        To run the API calls detailed below, you need access to your API keys,
        which are available in your publisher account on xOptin Ads.
      </p>

      <h2>Reporting API</h2>
      <p>
        The Reporting API is used to retrieve stats at both the day level and
        the sub-ID level.
      </p>

      <h3>Request Details</h3>
      <p>
        <strong>Request URL:</strong>
      </p>
      <pre className="code">
        <code>{apiUrl}</code>
      </pre>

      <p>
        <strong>Request Type:</strong> GET
      </p>

      <p>
        <strong>Authentication:</strong> An API key is required for
        authentication in the request header. Use the following format:
      </p>
      <pre className="code">Xoptin-Api-Key: [Your API Key]</pre>

      <p>
        <strong>Example cURL Request:</strong>
      </p>
      <pre className="code">
        curl --location
        'https://adsapi.xoptin.com/reporting/v1/publisher/sourceStatsReport/?from=2024-12-21&to=2024-12-30&groupby=sub1'
        -H 'xoptin-api-key: XXXXXXXXXXXXX'
      </pre>

      <h3>Parameters</h3>
      <table>
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Type</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <code className="text">from</code>
            </td>
            <td>Required</td>
            <td>Start date in YYYY-MM-DD format.</td>
          </tr>
          <tr>
            <td>
              <code className="text">to</code>
            </td>
            <td>Required</td>
            <td>End date in YYYY-MM-DD format.</td>
          </tr>
          <tr>
            <td>
              <code className="text">groupby</code>
            </td>
            <td>Optional</td>
            <td>
              Specifies the sub-ID grouping level:
              <ul>
                <li>
                  <code className="text">groupby=sub1</code>: Group by sub1.
                </li>
                <li>
                  <code className="text">groupby=sub1,sub2</code>: Group by sub1
                  and sub2.
                </li>
                <li>
                  <code className="text">groupby=sub1,sub2,sub3</code>: Group by
                  sub1, sub2, and sub3.
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>

      <h3>Response</h3>
      <p>The response is an array of objects. Below is an example:</p>
      <pre className="code">
        <code>{JSON.stringify(jsonData, null, 2)}</code>
      </pre>

      <h3>Response Field Descriptions</h3>
      <table>
        <thead>
          <tr>
            <th>Field</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <code className="text">date</code>
            </td>
            <td>Stats date.</td>
          </tr>
          <tr>
            <td>
              <code className="text">gross_income</code>
            </td>
            <td>Total revenue.</td>
          </tr>
          <tr>
            <td>
              <code className="text">net_income</code>
            </td>
            <td>Net revenue.</td>
          </tr>
          <tr>
            <td>
              <code className="text">clicks</code>
            </td>
            <td>Total clicks on the selected date filter.</td>
          </tr>
          <tr>
            <td>
              <code className="text">sub1</code>
            </td>
            <td>Sub1 ID (if passed as a parameter).</td>
          </tr>
          <tr>
            <td>
              <code className="text">sub2</code>
            </td>
            <td>Sub2 ID (if passed as a parameter).</td>
          </tr>
          <tr>
            <td>
              <code className="text">sub3</code>
            </td>
            <td>Sub3 ID (if passed as a parameter).</td>
          </tr>
        </tbody>
      </table>
      <p>
        For further details, please refer to your publisher account or contact
        support.
      </p>
    </div>
  );
};
export default ApiImplementation;
