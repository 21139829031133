import { React, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";

import AppStore from "../../config/AppStore";
import {
  sendRequesttoFetchAdvertiseDetails,
  sendUpdateAdvertiserFormSubmitRequest,
} from "../../view_logics/EditAdvertiser";
import Spinner from "../../components/common/Spinner";
import LoadingButton from "../../components/common/LoadingButton";

const EditAdvertiser = () => {
  const [advertiserFormLoading, setAdvertiserFormLoading] = useState(false);

  const { id } = useParams();
  const [advertiserInfo, setAdvertiserInfo] = useState(null);
  const [errorOnSave, setErrorOnSave] = useState(false);
  let store = AppStore.getAppStoreInstance();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const inputObj = {
    accessToken: store.getState().login.accessToken,
    url: "editAdvertiser",
    history: navigate,
  };
  inputObj.dataHandler = setAdvertiserInfo; // you setting data in this state you don't need to update setData again
  inputObj.AdvertiserId = id;
  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = await sendRequesttoFetchAdvertiseDetails(inputObj);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const [isHttpPingAuthFlagChecked, setIsHttpPingAuthFlagChecked] =
    useState(false);

  const handleHttpPingAuthFlagCheckboxChange = (event) => {
    setIsHttpPingAuthFlagChecked(event.target.checked);
  };

  const formik = useFormik({
    initialValues: {
      // Initial values for form fields
      // ...
      advertiserName: "",
      email: "",
      sidStr: "",
      globalPixelSid: "",
      identifierFlag: false,
      identifier: "",
      delayInMins: "",
      billingEmail: "",
      billingCompany: "",
      billingAddress: "",
      billingCity: "",
      billingState: "",
      billingZipCode: "",
      individualInvoiceForOfferFlag: false,
      cpcOfferWindowHeight: "",
      cpcOfferWindowWidth: "",
      httpPingAuthFlag: false,
      pingHttpBasicAuthUsername: "",
      pingHttpBasicAuthPassword: "",
      pingPostingMethod: "http_get",
      pingEvaluationCode: "",
      pingPostingUrl: "",
      pingResponseType: "text",
      pingResponsePropertyToMatch: "",
      pingResponseSuccessStringToMatch: "",
      pingResponseFailureStringToMatch: "",
      leadHttpBasicAuthFlag: false,
      leadHttpBasicAuthUsername: "",
      leadHttpBasicAuthPassword: "",
      leadPostingMethod: "http_get",
      leadEvaluationCode: "",
      leadPostingUrl: "",
      leadResponseType: "text",
      leadResponsePropertyToMatch: "",
      leadResponseSuccessStringToMatch: "",
      leadResponseFailureStringToMatch: "",
    },
  });

  const updateAdvertiser = (event) => {
    event.preventDefault();
    console.log("here");
    const formData = new FormData(event.target);
    const serializedData = {};

    serializedData["httpPingAuthFlag"] = "false";
    serializedData["identifierFlag"] = "false";
    serializedData["identifier"] = "";
    serializedData["httpLeadAuthFlag"] = serializedData[
      "leadHttpBasicAuthFlag"
    ] = "false";
    serializedData["individualInvoiceForOfferFlag"] = "false";
    for (let [name, value] of formData.entries()) {
      if (name.includes("httpPingAuthFlag")) {
        serializedData["httpPingAuthFlag"] = "true";
      }
      if (name.includes("httpLeadAuthFlag")) {
        serializedData["httpLeadAuthFlag"] = "true";
      }
      if (name === "individualInvoiceForOfferFlag") {
        serializedData["individualInvoiceForOfferFlag"] = "true";
      }
      if (name === "advertiserName") {
        serializedData["name"] = value;
      } else {
        serializedData[name] = value;
      }
    }
    if (id != undefined) {
      serializedData["id"] = id;
      serializedData["advertiserId"] = id;
    }
    serializedData["httpLeadAuthFlag"] =
      serializedData["leadHttpBasicAuthFlag"];
    const inputObj = {
      accessToken: store.getState().login.accessToken,
      url: "/advertiser",
      history: navigate,
      dataToPost: serializedData,
      errorStateChangeHandler: setErrorOnSave,
      loadingHandler: setAdvertiserFormLoading,
    };
    setAdvertiserFormLoading(true);

    sendUpdateAdvertiserFormSubmitRequest(inputObj);
  };

  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  };

  const { errors } = formik;
  useEffect(() => {
    // Scroll to top when `myState` changes
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [errorOnSave]);

  return (
    <div className="">
      <main className="content">
        <div className="container-fluid p-0">
          <h1>Edit Advertiser</h1>
          {/* {errorOnSave ? 
                    <div className="alert alert-danger alert-dismissible" role="alert">
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        <div className="alert-message">
                            <strong>Unsuccessful!</strong> an occured please try again later
                        </div>
                    </div> : '' } */}
          <Formik
            initialValues={advertiserInfo || formik.initialValues}
            validationSchema={Yup.object().shape({
              advertiserName: Yup.string().required("Name is required"),
              // SidStr:Yup.string()
              // .required('SidStr is required'),
              DelayInMins: Yup.number()
                // .required('Delay in minutes is required')
                .min("1")
                .max("60"),
              // billingCompany: Yup.string().required('Company name is required'),
              // billingAddress: Yup.string().required('Address is required'),
              // billingCity: Yup.string().required('City is required'),
              // billingState: Yup.string().required('State is required'),
              billingZipCode: Yup.string()
                .length(5)
                .matches(/^[0-9]{5}/),
              // .required('Zip code is required'),
              // cpcOfferWindowHeight: Yup.number()
              // .required('Delay in minutes is required'),
              // cpcOfferWindowWidth: Yup.number()
              // .required('Delay in minutes is required'),
              pingHttpBasicAuthUsername: Yup.string().required(
                "Username is required"
              ),
              pingHttpBasicAuthPassword: Yup.string().required(
                "Password is required"
              ),
              // pingEvaluationCode: Yup.string().required('Evaluation code is required'),
              // pingPostingUrl: Yup.string().required(),
              // pingResponsePropertyToMatch: Yup.string().required(),
              // pingResponseSuccessStringToMatch: Yup.string().required(),
              // pingResponseFailureStringToMatch: Yup.string().required(),
              // leadHttpBasicAuthUsername: Yup.string().required(),
              // leadHttpBasicAuthPassword: Yup.string().required(),
              // leadEvaluationCode: Yup.string().required(),
              leadPostingUrl: Yup.string().required(),
              // leadResponsePropertyToMatch: Yup.string().required(),
              leadResponseSuccessStringToMatch: Yup.string().required(),
              leadResponseFailureStringToMatch: Yup.string().required(),
            })}
            enableReinitialize
          >
            {({ isValid, dirty, values }) => (
              <form onSubmit={updateAdvertiser}>
                <Field
                  type="hidden"
                  id="advertiserId"
                  name="advertiserId"
                  className="form-control"
                  required
                  value={advertiserInfo ? advertiserInfo.id : ""}
                />
                <Field
                  type="hidden"
                  id="previousEmail"
                  name="previousEmail"
                  className="form-control"
                  required
                  value={advertiserInfo ? advertiserInfo.email : ""}
                />
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Basic Info</h5>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="advertiserName">
                          Name
                          <span className="text-danger">
                            &nbsp;<strong>*</strong>
                          </span>
                        </label>
                        <Field
                          type="text"
                          id="advertiserName"
                          name="advertiserName"
                          className="form-control"
                          required
                        />
                        <ErrorMessage
                          name="advertiserName"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="email">
                          Email
                          <span className="text-danger">
                            &nbsp;<strong>*</strong>
                          </span>
                        </label>
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          validate={validateEmail}
                          className={
                            errors.name ? "form-control" : "form-control"
                          }
                          required
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="sidStr">
                          SID
                        </label>
                        <Field
                          type="text"
                          id="sidStr"
                          name="sidStr"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="sidStr"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="globalPixelSid">
                          Global Pixel SID
                        </label>
                        <Field
                          type="text"
                          id="globalPixelSid"
                          name="globalPixelSid"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="globalPixelSid"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="delayInMins">
                          Delay In Minutes
                        </label>
                        <Field
                          type="number"
                          id="delayInMins"
                          name="delayInMins"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="delayInMins"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>

                      <div className="mb-3 col-md-6">
                        <label className="form-label">Identifier</label>
                        <div className="input-group">
                          <div className="input-group-text">
                            <Field
                              type="checkbox"
                              id="identifierFlag"
                              name="identifierFlag"
                              className="form-check-input"
                              // onClick={(event) => {
                              //   handleCheckboxChange("identifierFlag", event);
                              // }}
                            />
                          </div>
                          <Field
                            type="text"
                            name="identifier"
                            id="identifier"
                            className="form-control"
                            disabled={!values?.identifierFlag}
                            required
                          ></Field>
                          <ErrorMessage
                            name="identifier"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Billing Info</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="billingEmail">
                          Billing Email
                          <span className="text-danger">
                            &nbsp;<strong>*</strong>
                          </span>
                        </label>
                        <Field
                          type="email"
                          id="billingEmail"
                          name="billingEmail"
                          validate={validateEmail}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="billingEmail"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="billingCompany">
                          Billing Company
                        </label>
                        <Field
                          type="text"
                          id="billingCompany"
                          name="billingCompany"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="billingCompany"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group mb-3">
                        <label className="form-label" htmlFor="billingAddress">
                          Billing Address:
                        </label>
                        <Field
                          type="text"
                          id="billingAddress"
                          name="billingAddress"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="billingAddress"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="billingCity">
                          City
                        </label>
                        <Field
                          type="text"
                          id="billingCity"
                          name="billingCity"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="billingCity"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label" htmlFor="billingState">
                          State
                        </label>
                        <Field
                          type="text"
                          id="billingState"
                          name="billingState"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="billingState"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-2">
                        <label className="form-label" htmlFor="billingZipCode">
                          Zip
                        </label>
                        <Field
                          type="text"
                          id="billingZipCode"
                          name="billingZipCode"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="billingZipCode"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="form-check form-check-inline">
                        <Field
                          type="checkbox"
                          id="individualInvoiceForOfferFlag"
                          name="individualInvoiceForOfferFlag"
                          className="form-check-input"
                          // checked={advertiserInfo ? advertiserInfo.individual_invoice_for_offer_flag ? advertiserInfo.individual_invoice_for_offer_flag : '' : 'checked'}
                        />
                        <span className="form-check-label ml-10">
                          Indivisual Invoice for Offer
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <hr />
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">CPC Settings</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="cpcOfferWindowHeight"
                        >
                          CPC Offer Window Height
                        </label>
                        <Field
                          type="number"
                          id="cpcOfferWindowHeight"
                          name="cpcOfferWindowHeight"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="cpcOfferWindowHeight"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="cpcOfferWindowWidth"
                        >
                          CPC Offer Window Width
                        </label>
                        <Field
                          type="number"
                          id="cpcOfferWindowWidth"
                          name="cpcOfferWindowWidth"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="cpcOfferWindowWidth"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Ping Settings</h5>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <label className="form-check form-check-inline">
                        <Field
                          type="checkbox"
                          id="httpPingAuthFlag"
                          name="httpPingAuthFlag"
                          className="form-check-input"
                          onChange={handleHttpPingAuthFlagCheckboxChange}
                        />
                        <span className="form-check-label ml-10">
                          HTTP Auth
                        </span>
                      </label>
                    </div>
                    {/* {isHttpPingAuthFlagChecked && ( */}
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="pingHttpBasicAuthUsername"
                        >
                          Username
                          <span className="text-danger">
                            &nbsp;<strong>*</strong>
                          </span>
                        </label>
                        <Field
                          type="text"
                          id="pingHttpBasicAuthUsername"
                          name="pingHttpBasicAuthUsername"
                          className="form-control"
                          required
                        />
                        <ErrorMessage
                          name="pingHttpBasicAuthUsername"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="pingHttpBasicAuthPassword"
                        >
                          Password
                          <span className="text-danger">
                            &nbsp;<strong>*</strong>
                          </span>
                        </label>
                        <Field
                          type="text"
                          id="pingHttpBasicAuthPassword"
                          name="pingHttpBasicAuthPassword"
                          className="form-control"
                          required
                        />
                        <ErrorMessage
                          name="pingHttpBasicAuthPassword"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>
                    {/* )} */}
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="pingPostingMethod"
                        >
                          Posting Method
                        </label>
                        <Field
                          as="select"
                          name="pingPostingMethod"
                          id="pingPostingMethod"
                          className="form-control"
                        >
                          <option value="http_get">HTTP GET</option>
                          <option value="http_post">HTTP POST</option>
                          <option value="http_json_post">HTTP JSON POST</option>
                        </Field>
                        <ErrorMessage
                          name="pingPostingMethod"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="pingEvaluationCode"
                        >
                          Ping Evaluation Code
                        </label>
                        <Field
                          type="text"
                          id="pingEvaluationCode"
                          name="pingEvaluationCode"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="pingEvaluationCode"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-label" htmlFor="pingPostingUrl">
                        Posting URL
                      </label>
                      <Field
                        type="text"
                        id="pingPostingUrl"
                        name="pingPostingUrl"
                        className="form-control  mb-3"
                      />
                      <ErrorMessage
                        name="pingPostingUrl"
                        component="div"
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      />
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="pingResponseType"
                        >
                          Response Type
                        </label>
                        <Field
                          as="select"
                          name="pingResponseType"
                          id="pingResponseType"
                          className="form-control"
                        >
                          <option value="text">Text</option>
                          <option value="json">Json</option>
                          <option value="html">HTML</option>
                        </Field>
                        <ErrorMessage
                          name="pingResponseType"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="pingResponsePropertyToMatch"
                        >
                          Ping Response Property
                        </label>
                        <Field
                          type="text"
                          id="pingResponsePropertyToMatch"
                          name="pingResponsePropertyToMatch"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="pingResponsePropertyToMatch"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="pingResponseSuccessStringToMatch"
                        >
                          Response Success String
                        </label>
                        <Field
                          type="text"
                          id="pingResponseSuccessStringToMatch"
                          name="pingResponseSuccessStringToMatch"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="pingResponseSuccessStringToMatch"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="pingResponseFailureStringToMatch"
                        >
                          Response Failure String
                        </label>
                        <Field
                          type="text"
                          id="pingResponseFailureStringToMatch"
                          name="pingResponseFailureStringToMatch"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="pingResponseFailureStringToMatch"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Lead Settings</h5>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <label className="form-check form-check-inline">
                        <Field
                          type="checkbox"
                          id="leadHttpBasicAuthFlag"
                          name="leadHttpBasicAuthFlag"
                          className="form-check-input"
                          // checked={advertiserInfo ? advertiserInfo.lead_http_basic_auth_flag ? advertiserInfo.lead_http_basic_auth_flag : '' : 'checked'}
                        />
                        <span className="form-check-label ml-10">
                          HTTP Auth
                        </span>
                      </label>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="leadHttpBasicAuthUsername"
                        >
                          Username
                        </label>
                        <Field
                          type="text"
                          id="leadHttpBasicAuthUsername"
                          name="leadHttpBasicAuthUsername"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="leadHttpBasicAuthUsername"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="leadHttpBasicAuthPassword"
                        >
                          Password
                        </label>
                        <Field
                          type="text"
                          id="leadHttpBasicAuthPassword"
                          name="leadHttpBasicAuthPassword"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="leadHttpBasicAuthPassword"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="leadPostingMethod"
                        >
                          Lead Posting Method
                        </label>
                        <Field
                          as="select"
                          name="leadPostingMethod"
                          id="leadPostingMethod"
                          className="form-control"
                        >
                          <option value="http_get">HTTP GET</option>
                          <option value="http_post">HTTP POST</option>
                          <option value="http_json_post">HTTP JSON POST</option>
                        </Field>
                        <ErrorMessage
                          name="leadPostingMethod"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="leadEvaluationCode"
                        >
                          Lead Evaluation Code
                        </label>
                        <Field
                          type="text"
                          id="leadEvaluationCode"
                          name="leadEvaluationCode"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="leadEvaluationCode"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-label" htmlFor="leadPostingUrl">
                        Lead Posting URL
                        <span className="text-danger">
                          &nbsp;<strong>*</strong>
                        </span>
                      </label>
                      <Field
                        type="text"
                        id="leadPostingUrl"
                        name="leadPostingUrl"
                        className="form-control mb-3"
                      />
                      <ErrorMessage
                        name="leadPostingUrl"
                        component="div"
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      />
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="leadResponseType"
                        >
                          Lead Response Type
                        </label>
                        <Field
                          as="select"
                          name="leadResponseType"
                          id="leadResponseType"
                          className="form-control"
                        >
                          <option value="text">Text</option>
                          <option value="json">Json</option>
                          <option value="html">HTML</option>
                        </Field>
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="leadResponsePropertyToMatch"
                        >
                          Lead Response Property
                        </label>
                        <Field
                          type="text"
                          id="leadResponsePropertyToMatch"
                          name="leadResponsePropertyToMatch"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="leadResponsePropertyToMatch"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="leadResponseSuccessStringToMatch"
                        >
                          Lead Response Success String
                          <span className="text-danger">
                            &nbsp;<strong>*</strong>
                          </span>
                        </label>
                        <Field
                          type="text"
                          id="leadResponseSuccessStringToMatch"
                          name="leadResponseSuccessStringToMatch"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="leadResponseSuccessStringToMatch"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="leadResponseFailureStringToMatch"
                        >
                          Lead Response Failure String
                          <span className="text-danger">
                            &nbsp;<strong>*</strong>
                          </span>
                        </label>
                        <Field
                          type="text"
                          id="leadResponseFailureStringToMatch"
                          name="leadResponseFailureStringToMatch"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="leadResponseFailureStringToMatch"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-md-10 text-danger">
                    Please fill all the fields with <strong>*</strong>
                  </div>
                  <div
                    className="mb-3 col-md-2"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {advertiserFormLoading ? (
                      <LoadingButton name="Save" />
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!isValid}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </main>
      {id && !advertiserInfo && <Spinner />}
    </div>
  );
};

export default EditAdvertiser;
